import React, { Component } from 'react';
import { connect } from 'react-redux'

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import { IconContext } from "react-icons";
import { FaCircle } from 'react-icons/fa';

import ModalRemoveUser from './Modals/ModalRemoveUser';
import ModalEmailUser from './Modals/ModalEmailUser';
import { markUnseenAsSeen } from '../../../../../store/actions/projectActions';

import './ConfirmedUserList.css';

class ConfirmedUserList extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    };

    UIDtoName = (userID) => {
        if (this.props.users[userID]) {
            return this.props.users[userID].firstName + ' ' + this.props.users[userID].lastName
        } else {
            return "Loading user..."
        }
    };

    addPhoneNumber = (UID) => {
        if (this.props.users[UID]) {
          return this.formatPhoneNumber(this.props.users[UID].phone);
        } else {
          return "";
        }
      };
    
      formatPhoneNumber = (phoneNumberString) => {
        var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
        var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
          return '(' + match[1] + ') ' + match[2] + '-' + match[3];
        }
        return null;
      }

      addEmail = (UID) => {
        if (this.props.users[UID]) {
          return this.props.users[UID].email;
        } else {
          return "";
        }
      };

    confirmedColor = (status) => {
        switch(status) {
            case "no response":
                return "goldenrod";
            case "confirmed":
                return "green";
            case "denied - unseen":
                return "red";
            case "denied":
                return "green"
            case "dropped off - unseen":
                return "red";
            case "dropped off":
                return "green";

            default:
                return "white";
        }
    };

    markAsSeen = (userID, userRole, status ) => {
        if (status === "denied - unseen") {
            this.props.markUnseenAsSeen(this.props.project.refLocation, userID, userRole, "denied");
        } else if (status === "dropped off - unseen") {
            this.props.markUnseenAsSeen(this.props.project.refLocation, userID, userRole, "dropped off");
        }
    }

    render() {
        const { auth, project, confirmedUserListSorted, userRole } = this.props;

        return(
            <div>
                <h4>Confirmed</h4>
                
                {confirmedUserListSorted.length === 0 && (
                    <p><i>There are no confirmed {userRole.lower}s for this project yet...</i></p>
                )}

                <ol style={{paddingInlineStart: "1.25rem"}}>
                    {confirmedUserListSorted.length > 0 && (
                        confirmedUserListSorted.map((entry) => {
                            const userID = entry[0]
                            const status = entry[1]
                            if (userID) {
                                return (
                                    <Row key={userID}>
                                        <Col xs={8}>
                                            <li> {this.UIDtoName(userID) + ', '} 
                                                <b>{status}</b>
                                                <a href={"tel:" + this.addPhoneNumber(userID)} className="no-padding"> {this.addPhoneNumber(userID)} </a> 
                                            </li>
                                            {(status === "dropped off - unseen" || status === "denied - unseen") && (
                                                <Button variant="success" onClick={() => this.markAsSeen(userID, userRole, status )}>Mark as "Seen"</Button>
                                            )}
                                        </Col>

                                        <Col xs={1}>
                                            <IconContext.Provider style={{textAlign: 'right'}} value={{ color: this.confirmedColor(status), className: "global-class-name" }}>
                                                <FaCircle />
                                            </IconContext.Provider>
                                        </Col>

                                        {status !== "removed" && (
                                            <Col xs={1}>
                                                <ModalRemoveUser
                                                    refLocation={project.refLocation}
                                                    userID={userID}
                                                    userName={this.UIDtoName(userID)}
                                                    adminID={auth.uid}
                                                    userRole = {userRole}
                                                />
                                            </Col>
                                        )}

                                        {status == "no response" && (
                                            <Col xs={1}>
                                                <ModalEmailUser
                                                    refLocation={project.refLocation}
                                                    userID={userID}
                                                    userName={this.UIDtoName(userID)}
                                                    adminID={auth.uid}
                                                    userRole = {userRole}
                                                    userEmail={this.addEmail(userID)}
                                                    firstName={this.props.users[userID].firstName}
                                                    lastName={this.props.users[userID].lastName}
                                                    client={project.client}
                                                    startTime={project.startTime}
                                                />
                                            </Col>
                                        )}

                                    </Row>
                                )
                            } else {
                                return null;
                            }
                        })
                    )}  
                </ol>
            </div>
        );
    };
};

const mapDispatchToProps = dispatch => {
    return {
        markUnseenAsSeen: (refLocation, userToMark, userRole, seenAction) => dispatch(markUnseenAsSeen(refLocation, userToMark, userRole, seenAction)),
    }
  }
   
export default connect(null, mapDispatchToProps)(ConfirmedUserList);