import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase';
import { Redirect } from 'react-router-dom'

import Container from 'react-bootstrap/Container';

import BartReport from './BartReport';
import ParkingReport from './ParkingReport';
import ViewBartReport from './ViewBartReport';
import ViewParkingReport from './ViewParkingReport';

import './Expenses.css'

class Expenses extends Component {
    constructor(props) {
        super(props);

        this.state = {
        }
    }

    render() {
        const { auth, project, reports, users, location } = this.props
        if (!auth.uid) return <Redirect to='/signin' />

        var usersReports = []
        if (reports) {
            Object.entries(reports).forEach(entry => {
                const report = entry[1];
    
                if (report.userID === auth.uid && report.status !== "deleted") {
                    usersReports.push(entry)
                }
            })
        }

        if (project && users) {
            return(
                <Container>
                    <a style={{marginTop: 10}} href={location.pathname.replace("/expenses", "")} className="previous">&laquo; Return</a>
                    
                    {!this.state.showBartForm && (
                        <div 
                            style={{textAlign: 'center'}}
                            className="card z-depth-3 project-summary cardMargins"
                            onClick={() => this.setState({showBartForm: true})}
                        >
                            <h5 style={{marginBottom: 0}}>Submit a BART expense</h5>
                        </div>
                    )}

                    {!!this.state.showBartForm && (
                        <div>
                            <BartReport auth={auth} project={project} />
                        </div>
                    )}

                    {!this.state.showParkingForm && (
                        <div
                            style={{textAlign: 'center'}}
                            className="card z-depth-3 project-summary cardMargins"
                            onClick={() => this.setState({showParkingForm: true})}
                        >
                            <h5 style={{marginBottom: 0}}>Submit a Parking expense</h5>
                        </div>
                    )}

                    {!!this.state.showParkingForm && (
                        <div>
                            <ParkingReport auth={auth} project={project} />
                        </div>
                    )}

                    {!!usersReports.length && (
                        Object.values(usersReports).map(report => {
                            if (report[1].type === "BART") {
                                return <ViewBartReport key={report[0]} report={report[1]}/>
                            } else {
                                return <ViewParkingReport key={report[0]} report={report[1]}/>
                            }
                        })
                    )}
                </Container>
            )
        } else {
            return (
                <div className="container center">
                    Loading...
                </div>
            )
        }
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
        project: state.firestore.data.project,
        users: state.firestore.data.users,
        reports: state.firestore.data.reports,
    }
}

export default compose(
    connect(mapStateToProps, null),
    firestoreConnect((props) => [
        {
            collection : 'SchedulerProjects', doc : props.match.params.year,
            subcollections: [{ collection : props.match.params.month, doc : props.match.params.id }],
            storeAs: 'project'
        },
        {
            collection: 'users'
        },
        {
            collection : 'SchedulerProjects', doc : props.match.params.year,
            subcollections: [{ collection : props.match.params.month, doc : props.match.params.id,
                                subcollections: [{collection: 'Expenses'}]                        
                            }],
            storeAs: 'reports'
        }
    ])
)(Expenses);


