import React, { Component } from 'react';


class ScheduledTechs extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    };

    UIDtoName = (userID) => {
        if (this.props.users[userID]) {
            return this.props.users[userID].firstName + ' ' + this.props.users[userID].lastName
        } else {
            return "Loading user..."
        }
    };

    render() {
        const { project } = this.props;

        return (
            <div className="card-panel white z-depth-3">
                <h2> Scheduled </h2>

                <h5>
                    {Object.entries(project.confirmedTechList).map(entry => {
                        const id = entry[0]
                        const value = entry[1]
                        if (value === "confirmed" || value === "no response") {
                            return <ul> {this.UIDtoName(id)} </ul>
                        } else {
                            return <></>
                        }
                    })}
                </h5>
            </div>
        );
    };
};

export default ScheduledTechs;

