import React, { useState } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import Button from 'react-bootstrap/Button';
import { FaPlus } from 'react-icons/fa';
import { IconContext } from "react-icons";
import { addClientToScope, addClientsToScope } from '../../../../store/actions/userActions';
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';

const customStyles = {
    content : {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '400px', 
        height: 'auto', 
        padding: '20px',
        zIndex: 1001,
    },
    overlay: {
        zIndex: 1000,
        backgroundColor: 'rgba(0, 0, 0, 0.75)',
    }
};

function ModalAddClient(props){
    var subtitle;
    const [modalIsOpen, setIsOpen] = useState(false);
    const [clientID, setClientID] = useState(""); // To store selected client ID or 'all'
    const [selectedClientIDs, setSelectedClientIDs] = useState([]); // Store selected clients (array)

    function openModal() {
        setIsOpen(true);
        setClientID(""); // Reset selection on modal open
        setSelectedClientIDs([]); // Reset selected clients
    }

    function afterOpenModal() {
        subtitle.style.color = '#f00';
    }

    function closeModal(){
        setIsOpen(false);
        setClientID('');
    }

    const handleSelectChange = (e) => {
        if (e.target.value === "all") {
            const allClientIDs = clients.map(client => client.id); // Get all client IDs
            setSelectedClientIDs(allClientIDs); // Store all clients in state
            setClientID("all"); // Set clientID to "all"
        } else {
            setSelectedClientIDs([e.target.value]); // Store the single selected client ID
            setClientID(e.target.value); // Set the single selected client ID
        }
    };

    const handleSubmit = () => {
        if (clientID === "all") {
            props.addClientsToScope(userID, selectedClientIDs); // Add all clients
        } else {
            props.addClientToScope(userID, selectedClientIDs[0]); // Add single client
        }
        closeModal();
    }

    const { userID, clients } = props;

    return (
        <>
            {/* Plus Icon is visible at all times, clicking it will open the modal */}
            <IconContext.Provider value={{ className: "global-class-name", style: { cursor: 'pointer' } }}>
                <FaPlus onClick={openModal}/>
            </IconContext.Provider>
    
            {/* Modal JSX */}
            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Add Client Modal"
            >
                <h2 ref={_subtitle => (subtitle = _subtitle)}>
                    {`Add New Client`}
                </h2>
                
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
                    <FormControl fullWidth>
                        <InputLabel id="select-client-label">Select Client</InputLabel>
                        <Select
                            labelId="select-client-label"
                            value={clientID}
                            onChange={handleSelectChange} // Call the handler to set client IDs
                        >
                            <MenuItem value="all">All Clients</MenuItem> {/* All Clients Option */}
                            {clients.map((client) => (
                                <MenuItem key={client.id} value={client.id}>
                                    {client.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
    
                <Button variant="secondary" onClick={closeModal} style={{ marginRight: 10 }}>Cancel</Button>
                <Button
                    onClick={handleSubmit}
                    variant="primary"
                >
                    Add
                </Button>
            </Modal>
        </>
    );
}

const mapDispatchToProps = dispatch => {
    return {
        addClientToScope: (userID, clientID) => dispatch(addClientToScope(userID, clientID)),
        addClientsToScope: (userID, clientIDs) => dispatch(addClientsToScope(userID, clientIDs)),
    };
};

export default connect(null, mapDispatchToProps)(ModalAddClient);
