import React from 'react';
import { connect } from 'react-redux';

import Row from 'react-bootstrap/Row';
import Modal from 'react-modal';
import Button from 'react-bootstrap/Button';

import { FaEllipsisV } from "react-icons/fa";

import { setUserNoShow, revertUserNoShow } from '../../../../../store/actions/projectActions';

const customStyles = {
    content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        transform             : 'translate(-50%, -50%)'
    },
    overlay                 : {zIndex: 1000}
};

const customStylesMobile = {
    content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-49%',
        transform             : 'translate(-50%, -50%)'
    },
    overlay                 : {zIndex: 1000}
};
 
// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
//Modal.setAppElement('#yourAppElement')
 
function TechOptions (props) {
    var subtitle;
    const [modalIsOpen,setIsOpen] = React.useState(false);

    function openModal() {
        setIsOpen(true);
    }
 
    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        subtitle.style.color = '#f00';
    }
 
    function handleAccept(id) {
        closeModal()
    }

    function closeModal(){
        setIsOpen(false);
    }

    function setNoShow() {
        props.setUserNoShow(project.refLocation, id, props.userRole)
        setIsOpen(false);
    }

    function revertNoShow() {
        props.revertUserNoShow(project.refLocation, id, props.userRole)
        setIsOpen(false);
    }

    const {id, project} = props;

    return (
        <div>
            <FaEllipsisV className="ellipses" onClick={openModal}/>

            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={window.innerWidth < 1200 ? customStylesMobile : customStyles}
                contentLabel="Home Message"
            >
                <h2 ref={_subtitle => (subtitle = _subtitle)}>Actions for {props.name}</h2>
            
                <Row>
                    <h5 className="noShowText"> Mark as "No Show" </h5>
                    {!!(project.confirmedTechList[id] !== "no show") && (
                        <Button className="noShowButton" variant="danger" size="sm" onClick={() => setNoShow()}> No Show </Button>
                    )}

                    {!(project.confirmedTechList[id] !== "no show") && (
                        <Button className="noShowButton" variant="success" size="sm" onClick={() => revertNoShow()}> Revert </Button>
                    )}
                </Row>

                <Button style={{marginTop: 10}} variant="primary" size="sm" onClick={() => handleAccept(id)}>Return</Button>
            </Modal>
        </div>
    );
};

const mapDispatchToProps = dispatch => {
    return {
        setUserNoShow: (refLocation, id, userRole) => dispatch(setUserNoShow(refLocation, id, userRole)),
        revertUserNoShow: (refLocation, id, userRole) => dispatch(revertUserNoShow(refLocation, id, userRole)),
    }
};
 
export default connect(null, mapDispatchToProps)(TechOptions);