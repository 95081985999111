import React, { Component } from 'react';
import { connect } from 'react-redux'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'

import emailjs from 'emailjs-com';
import SelectSearch from 'react-select-search';

import { confirmUserWithNoResponse, removeProposedUser, addToActivityLog } from '../../../../../store/actions/projectActions' 
import './SearchStyle.css'

class ManualAddUser extends Component {
    constructor(props) {
        super(props);
    
        this.state = {
            newUser: ''
        };
    };

    handleChange = (id) => {
        this.setState({ newUser: id || '' }, () => {
            if (id) {
                setTimeout(() => {
                    this.setState({ newUser: '' });
                }, 5000); // 5000 milliseconds = 5 seconds
            }
        });
    }

    handleDropdownClick = () => {
        this.setState({ newUser: '' });
    }


    addUser = (proposedUserList, refLocation, userToAdd, userRole) => {

        // Check if user is in proposed user list and remove them if so
        var tempProposedList = {...proposedUserList};
        if (Object.keys(tempProposedList).includes(userToAdd)) {
            delete tempProposedList[userToAdd]
            this.props.removeProposedUser(refLocation, tempProposedList, userRole);
        }

        this.props.confirmUserWithNoResponse(refLocation, userToAdd, userRole);
        this.props.addToActivityLog(refLocation, `admin manual add ${userRole.lower}`, this.props.auth.uid, userToAdd, userRole);

        if (this.props.users[userToAdd].notifications.email.confirmedForProject) {
            var templateParams = { Bcc: this.props.users[userToAdd].email };
            this.sendConfirmEmail(templateParams);
        }

            // Clear the selected user from the state
            this.setState({ newUser: '' });
    }

    sendConfirmEmail = (templateParams) => {
        emailjs.send('scheduler@murphymckay', 'template_ked4438', templateParams, "user_UgmdOeeH6OCjwrAqPoGpi")
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
    }

    render() {
        const { proposedUserList, project, users, userRole } = this.props;

        // Convert the "users" object into the "options" array so that it can be read by "Select Search" component
        const options = []
        Object.entries(users).forEach(entry => {
            const id = entry[0]
            const user = entry[1]

            if (user.role === userRole.lower) {
                options.push({name: user.firstName + ' ' + user.lastName, value: id})
            }
        })
        // Sort the options list alphabetically
        options.sort(function (a,b) {
            if (a.name > b.name) return 1
            if (a.name < b.name) return -1
            return 0;
        })

        return(
            <>
                <Row style={{marginLeft: 0}}>
                    <div onClick={this.handleDropdownClick}>
                        <SelectSearch
                            options={options}
                            search={true}
                            onChange={this.handleChange}
                            placeholder={`Add a ${userRole.lower}...`}
                            emptyMessage={`No ${userRole.lower}s loaded...`}
                            value={this.state.newUser}
                        />
                    </div>
                </Row>

                {this.state.newUser && (
                    <Row style={{marginLeft: 0}}>
                        <Col xs={3}>
                            <Button onClick={() => this.addUser(proposedUserList, project.refLocation, this.state.newUser, userRole)}> Add </Button>
                        </Col>
        
                        <Col xs={9}>
                            <i>{users[this.state.newUser].email}</i>
                        </Col>
                    </Row>
                )}
            </>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        confirmUserWithNoResponse: (refLocation, userToAdd, userRole) => dispatch(confirmUserWithNoResponse(refLocation, userToAdd, userRole)),
        removeProposedUser: (refLocation, proposedUserList, userRole) => dispatch(removeProposedUser(refLocation, proposedUserList, userRole)),
        addToActivityLog: (refLocation, action, authorID, subjectID, userRole) => dispatch(addToActivityLog(refLocation, action, authorID, subjectID, userRole)),
    };
  };
  
  export default connect(null, mapDispatchToProps)(ManualAddUser);