import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import moment from 'moment';
import './ProjectSummary.css';
import bartLogo from '../../../assets/bart-720p.png';

class ProjectSummary extends Component {
  UIDtoName = (userID) => {
    const user = this.props.users[userID];
    return user ? `${user.firstName} ${user.lastName}` : "Loading user...";
  };

  addPhoneNumber = (userID) => {
    const user = this.props.users[userID];
    return user ? this.formatPhoneNumber(user.phone) : "";
  };

  formatPhoneNumber = (phoneNumberString) => {
    const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    return match ? `(${match[1]}) ${match[2]}-${match[3]}` : null;
  };

  convertToMap = (obj) => {
    return new Map(Object.entries(obj));
  };

  renderSupervisorList = () => {
    const { project } = this.props;
    if (!project.confirmedSupervisorList && !project.supervisorList) return null;

    const supervisorMap = project.confirmedSupervisorList ? this.convertToMap(project.confirmedSupervisorList) : 
      (Array.isArray(project.supervisorList) ? new Map(project.supervisorList.map(userID => [userID, 'confirmed'])) : new Map());

    const supervisors = Array.from(supervisorMap.entries()).filter(([_, status]) => status !== 'removed');
    if (supervisors.length === 1) {
      const [supervisor] = supervisors[0];
      return (
        <>
          <h3 className="title" style={{ marginTop: 20 }}>Supervisor</h3>
          <p className="details">
            {this.UIDtoName(supervisor)} - <a href={`tel:${this.addPhoneNumber(supervisor)}`}>{this.addPhoneNumber(supervisor)}</a>
          </p>
        </>
      );
    } else if (supervisors.length > 1) {
      return (
        <>
          <h3 className="title" style={{ marginTop: 20 }}>Supervisors</h3>
          {supervisors.map(([supervisor]) => (
            <p key={supervisor} className="details">
              {this.UIDtoName(supervisor)} - {this.addPhoneNumber(supervisor)}
            </p>
          ))}
        </>
      );
    }
    return null;
  };

  renderLeadList = () => {
    const { project } = this.props;
    if (!project.confirmedLeadList && !project.leadList) return null;

    const leadMap = project.confirmedLeadList ? this.convertToMap(project.confirmedLeadList) : 
      (Array.isArray(project.leadList) ? new Map(project.leadList.map(userID => [userID, 'confirmed'])) : new Map());

    const leads = Array.from(leadMap.entries()).filter(([_, status]) => status !== 'removed');
    if (leads.length === 1) {
      const [lead] = leads[0];
      return (
        <>
          <h3 className="title" style={{ marginTop: 20 }}>Lead</h3>
          <p className="details">
            {this.UIDtoName(lead)} - <a href={`tel:${this.addPhoneNumber(lead)}`}>{this.addPhoneNumber(lead)}</a>
          </p>
        </>
      );
    } else if (leads.length > 1) {
      return (
        <>
          <h3 className="title" style={{ marginTop: 20 }}>Leads</h3>
          {leads.map(([lead]) => (
            <p key={lead} className="details">
              {this.UIDtoName(lead)} - {this.addPhoneNumber(lead)}
            </p>
          ))}
        </>
      );
    }
    return null;
  };

  render() {
    const { profile, project, URLs, location } = this.props;

    // Temporary Firebase Storage Inventory Link Control
    const projectID = project.refLocation.id;
    const showLinks = (projectID === "c6491bf7-c438-4338-a7c3-c4b63775f50b" || projectID === "2240d24d-4619-4b32-8cd8-25cd06e05bd5") &&
                      (profile.role === "supervisor" || profile.role === "lead" || profile.role === "admin");

    const expenseLink = `${location.pathname}/expenses`;
    const startTime = moment(project.startTime, "YYYY-MM-DD hh:mm").format('dddd MM/DD - h:mm a');
    const workstationDetail = `${project.type} - ${project.workstations} Workstations`;
    const bartDetails = `${project.bartStation} - ${project.bartDistance} mi`;

    return (
      <div className="card-panel white z-depth-3" style={{ width: "100%" }}>
        <Row style={{ marginBottom: 0 }}>
          <Col xs={12} lg={9}>
            <h3 className="title">{project.client}</h3>
            <p className="details">{startTime}</p>
            <p className="details">{project.address}</p>
            <p className="details">{project.city}</p>
            <a className="link" style={{ marginLeft: 0, padding: 0 }} href={project.addressLink}>Google Maps Link</a>
            <p className="details">{workstationDetail}</p>
            <div className="details">
              <img src={bartLogo} className="bartImage" alt="BART logo" />
              <p className="bartText">{bartDetails}</p>
            </div>
            <p className="details" style={{ marginTop: 10 }}><i>{project.notes}</i></p>
            {showLinks && URLs.length > 0 && URLs.map(url => (
              <p key={url} style={{ marginBottom: 0 }}><a href={url} style={{ margin: 0 }}>CSV link</a></p>
            ))}
            {this.renderSupervisorList()}
            {this.renderLeadList()}
          </Col>
          <Col xs={12} lg={3}>
            <NavLink to={expenseLink} className="btn blue lighten-1 white-text" style={{ margin: 0 }}>
              Expenses
            </NavLink>
          </Col>
        </Row>
      </div>
    );
  }
}

export default ProjectSummary;
