import React, { Component } from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import ContactInfo from './ContactInfo';
import Writeups from './Writeups/Writeups';
import Loading from '../assets/Loading.gif';
import './UserInfoProfile.css';

class UserInfoProfileMobile extends Component {
  state = {};

  render() {
    const { auth, profile, users } = this.props

    if (auth && profile.firstName) {
      return(
        <Container>
          <Row>
            <ContactInfo profile={profile} />
          </Row>

          <Row>
            <Writeups auth={auth} profile={profile} users={users}/>
          </Row>

          <Row>
            <div style={{ height: '750px', width: '100%' }}>
              <iframe
                src="https://drive.google.com/file/d/191VBBPlaN1Y8oJxcBBREt3qIarQJCsRW/preview"
                style={{ width: '100%', height: '100%' }}
                frameBorder="0"
              ></iframe>
            </div>
          </Row>
        </Container>
      )
    } else {
      return <img style={{marginTop: 20}} className="LoadingWheel" src={Loading} alt="loading..." />
    }
  }
}

export default UserInfoProfileMobile;