import React, { Component } from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

import './Expenses.css';

class ViewBartReport extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    displayStatus = (status) => {
        switch(status) {
            case 'pending':
                return <p style={{color: '#abb012'}}><b>Pending </b></p>

            case 'approved':
                return <p style={{color: 'green'}}><b>Approved</b></p>

            case 'denied':
                return <p style={{color: 'red'}}><b>Denied</b></p>
                
            default:
                console.log("ERROR: DEFAULT CASE REACHED");
                break;
        }
    }

    render() {
        const { report } = this.props;

        return(
            <Card className="reviewCard">
                <Card.Header as="h5">BART Report</Card.Header>

                <Card.Body>
                    <Row style={{marginBottom: 0}}>
                        <Col xs={12} md={6}>
                            <div className="card z-depth-0 project-summary">
                                <div className="card-content grey-text text-darken-3">
                                    <span className="card-title">{this.displayStatus(report.status)}</span>
                                    <span className="card-title">{"To Origin: " + report.toOrigin}</span>
                                    <span className="card-title">{"To Destination: " + report.toDestination}</span>
                                    <span className="card-title">{"To Subtotal: $" + report.toCost}</span>
                                    <span className="card-title">{"Leave Origin: " + report.toOrigin}</span>
                                    <span className="card-title">{"Leave Destination: " + report.toDestination}</span>
                                    <span className="card-title">{"Leave Subtotal: $" + report.leaveCost}</span>
                                    <span className="card-title">{"Total Cost: $" + report.totalCost}</span>   
                                </div>
                            </div>

                        </Col>

                        <Col style={{textAlign: 'center'}} xs={12} md={6}>
                            <img style={{width: '100%'}} src={report.pictureURL} alt="proof of expense"></img>
                            <a style={{fontSize: 10, margin: 0}} href={report.pictureURL} className="previous">Link to image</a>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        )
    }
}

export default ViewBartReport;