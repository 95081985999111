import React from 'react';
import { connect } from 'react-redux';

import { signOut } from '../../../store/actions/authActions';

const SignedInLinks = (props) => {
  return (
    <div>
      <ul className="left">
        <li><a href="/bugReport"> Report a Bug </a></li>
      </ul>



      <ul className="right">
        <li><a onClick={props.signOut} href={'/'}> Log Out </a></li>
      </ul>
    </div>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    signOut: () => dispatch(signOut()),
  }
}

export default connect(null, mapDispatchToProps)(SignedInLinks);