import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from '../../../node_modules/react-redux';
import { signUp } from '../../store/actions/authActions';

import Container from 'react-bootstrap/Container';

import PrivacyPolicy from './modals/PrivacyPolicy';

class SignUp extends Component {
  state = {
    form: {
      email: '',
      password: '',
      repeatPassword: '',
      firstName: '',
      lastName: '',
      phone: '',
    },
    showPasswordError: false
  }

  handleChange = (e) => {
    const { form } = { ...this.state };
    const currentState = form;
    const { id, value } = e.target;
    currentState[id] = value;

    this.setState({ event: currentState })
  }

  handleSubmit = (e) => {
    e.preventDefault();

    if (this.state.form.password !== this.state.form.repeatPassword) {
      this.setState({showPasswordError: true });
    } else {
      this.setState({showPasswordError: false });
    }

    this.props.signUp(this.state.form);
  }

  
  render() {
    const { auth, authError } = this.props;
    if (auth.uid) return <Redirect to='/' />

    return (
      <Container style={{marginTop: 20}}>
        <form className="white" onSubmit={this.handleSubmit}>
          <h5 className="grey-text text-darken-3">Sign Up</h5>
          <div className="input-field">
            <label htmlFor="email">Email</label>
            <input type="email" id='email' required onChange={this.handleChange} />
          </div>
          <div className="input-field">
            <label htmlFor="password">Password</label>
            <input type="password" id='password' required onChange={this.handleChange} />
          </div>
          <div className="input-field">
            <label htmlFor="repeatPassword">Repeat Password</label>
            <input type="password" id='repeatPassword' required onChange={this.handleChange} />
          </div>
          <div className="input-field">
            <label htmlFor="firstName">First Name</label>
            <input type="text" id='firstName' required onChange={this.handleChange} />
          </div>
          <div className="input-field">
            <label htmlFor="lastName">Last Name</label>
            <input type="text" id='lastName' required onChange={this.handleChange} />
          </div>
          <div className="input-field">
            <label htmlFor="phone">Phone Number - ex: 925-808-1234</label>
            <input type="tel" id="phone" name="phone"
              required
              onChange={this.handleChange}>
            </input>
          </div>
          <div className="input-field">
            <PrivacyPolicy />
            <button className="btn pink lighten-1 z-depth-0">Sign Up</button>
            <div className="red-text center">
              { authError ? <p>{ authError }</p> : null }
            </div>
            {this.state.showPasswordError && (
              <div className="red-text center">
                Error: Passwords do not match
              </div>
            )}
          </div>
        </form>
      </Container>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    authError: state.auth.authError
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    signUp: (newUser) => dispatch(signUp(newUser))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);