import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';

import { storage } from '../../../../config/fbConfig';
import { useHistory } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import ProjectSummary from './ProjectSummary'
import UserCommentInput from './UserCommentInput'
import Comments from './Comments'
import ScheduledTechs from './ScheduledTechs'
import TechLists from './TechLists/TechLists'

function ProjectOverviewUser(props) {
  const [URLs, setURLs] = useState([]);
  const history = useHistory();

  // Pull inventory URLs from storage, temporary code
  useEffect(() => {
    const listRef = storage.ref('exports/dynamicInventoryProjects/2023/April/5189ba5a-61b1-4c23-b3be-37257504d548');
    listRef.listAll()
    .then((res) => {
        res.items.forEach((itemRef) => {
          itemRef.getDownloadURL().then((url) => {
            setURLs((prevURLs) => ([...prevURLs, url]));
          })
        });
    }).catch((error) => {
      console.log("Error: ", error);
    })
  }, []);

  const { profile, project, users, location, userRole } = props;

  if (project && users) {
    return (
      <Container>
        <a onClick={() => history.goBack()} className="previous" style={{ cursor: 'pointer', marginTop: 10, marginBottom: 5 }}> &laquo; Return </a>
        <Row>
          <Col xs={12} lg={7}>
            <Row>
              <ProjectSummary profile={profile} project={project} users={users} URLs={URLs} location={location} />
            </Row>

            <Row>
              {(userRole.lower ==='supervisor' || userRole.lower ==='lead') && 
                <UserCommentInput project={project} users={users} refLocation={project.refLocation} userID={props.auth.uid} userRole={userRole} />}
            </Row>

            <Row style={{ display: "flex", justifyContent: "center" }}>
              <Comments project={project} users={users} />
            </Row>
          </Col>

          <Col xs={12} lg={5}>
            {userRole.lower ==='tech' && 
              <ScheduledTechs project={project} users={users} />}

            {(userRole.lower ==='supervisor' || userRole.lower ==='lead') &&
              <TechLists project={project} users={users} />}
          </Col>
        </Row>

        <a onClick={() => history.goBack()} className="previous" style={{ cursor: 'pointer', marginTop: 10, marginBottom: 5 }}> &laquo; Return </a>
      </Container>
    );
  } else {
    return (
      <> </>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    project: state.firestore.data.project,
    users: state.firestore.data.users,
  };
};

export default compose(
  connect(mapStateToProps, null),
  firestoreConnect((props) => [
    {
      collection: 'SchedulerProjects', doc: props.match.params.year,
      subcollections: [{ collection: props.match.params.month, doc: props.match.params.id }],
      storeAs: 'project'
    },
    {
      collection: 'users'
    },
  ])
)(ProjectOverviewUser);
