import React from 'react';
import { connect } from 'react-redux';

import Modal from 'react-modal';
import Button from 'react-bootstrap/Button';

import { FaTrash } from 'react-icons/fa';
import { IconContext } from "react-icons";

import { removeClientFromScope } from '../../../../store/actions/userActions';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
    overlay: { zIndex: 1000 }
};

function ModalRemoveClient(props) {
    var subtitle;
    const [modalIsOpen, setIsOpen] = React.useState(false);

    function openModal() {
        setIsOpen(true);
    };

    function afterOpenModal() {
        subtitle.style.color = '#f00';
    };

    function closeModal() {
        setIsOpen(false);
    };

    function removeClient(userID, clientID) {
        props.removeClientFromScope(userID, clientID);
        closeModal();
    };

    const { userID, clientID } = props;
    return (
        <>
            {/* Trashcan Icon is visible at all times, clicking it will open the modal */}
            <IconContext.Provider style={{ textAlign: 'right' }} value={{ className: "global-class-name" }}>
                <FaTrash onClick={openModal} />
            </IconContext.Provider>

            {/* Modal JSX */}
            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Warning Modal"
            >
                <h2 ref={_subtitle => (subtitle = _subtitle)}>
                    {`Remove Client`}
                </h2>

                <p>{`Are you sure you want to remove ${clientID} from the client scope?`}</p>

                <Button variant="primary" size="sm" onClick={closeModal} style={{ marginRight: 10 }}> Cancel </Button>
                <Button variant="danger" size="sm" onClick={() => removeClient(userID, clientID)}> {"Remove"} </Button>
            </Modal>
        </>
    );
};

const mapDispatchToProps = dispatch => {
    return {
        removeClientFromScope: (userID, clientID) => dispatch(removeClientFromScope(userID, clientID)),
    };
};

export default connect(null, mapDispatchToProps)(ModalRemoveClient);
