const initState = {
    userError: null,
    userSuccess: null,
    emailError: null,
    phoneError: null,
}

const userReducer = (state = initState, action) => {
    switch(action.type){
        case 'UPDATE_ROLE':
            console.log('update role success');
            return {
                ...state,
                userError: null
            }

        case 'UPDATE_ROLE_ERROR':
            console.log('login failed');
            return {
                ...state,
                userError: 'Login failed'
            }

        case "BUG_REPORT_SUCCESS":
            console.log('report success!');
            return {
                ...state,
                userError: null,
                userSuccess: action.success.message
            }

        case "BUG_REPORT_ERR":
            console.log('report error');
            return {
                ...state,
                userError: action.err.message,
                userSuccess: null
            }

        case "UPDATE_VALUE_ERR":
            console.log(action.err)
            if (action.err === "email") {
                console.log("Here email")
                return {
                    ...state,
                    emailError: "Not a valid email address"
                }
            }

            if (action.err === "phone") {
                console.log("Here phone")
                return {
                    ...state,
                    phoneError: "Not a valid phone number"
                }
            }
            break;

        default:
            return state;
    }
}

export default userReducer;