import React, { Component } from 'react';
import { connect } from 'react-redux';

import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import emailjs from 'emailjs-com';

import { addWriteup } from '../../../../store/actions/userActions';

class NewWriteup extends Component {
  state = {
    formClient: "",
    formDate: "",
    formReason: "",
  };

  handleChange = (e) => {
    this.setState({[e.target.id]: e.target.value})
  }

  handleSubmit = (e) => {
    e.preventDefault();

    var writeups = {...this.props.userData.writeups};
    const newWriteupID = uuidv4();
    writeups = this.addNewWriteupToExistingWriteups(writeups, newWriteupID);

    this.props.addWriteup(this.props.userID, writeups)

    this.emailWriteUptoHR();

    this.clearFormFields();
  }

  render() {
    return(
      <Col xs={4}>
        <Form>
          <Card>
            <Card.Header>
              <Row style={{marginBottom: 0}}>
                <Col xs={6}>
                  <Form.Group style={{marginBottom: 0}} controlId="formClient">
                    <Form.Control
                      type="Text"
                      value={this.state.formClient} 
                      onChange={this.handleChange}
                      placeholder="Client"
                    />
                  </Form.Group>
                </Col>
                
                <Col xs={6}>
                  <Form.Group style={{marginBottom: 0}} controlId="formDate">
                    <Form.Control
                      type="Date"
                      value={this.state.formDate} 
                      onChange={this.handleChange}
                      placeholder="Date"
                    />
                    <Form.Text className="text-muted">Date of Incident</Form.Text>
                  </Form.Group>
                </Col>                                   
              </Row>
            </Card.Header>

            <Card.Body>
              <Form.Group style={{marginBottom: 0}} controlId="formReason">
                <Form.Control
                  value={this.state.formReason}
                  type="Text"
                  onChange={this.handleChange}
                  placeholder="Enter a reason for the writeup..."
                  as="textarea" rows={3}
                />
              </Form.Group>

              <Button style={{marginTop: 10}} variant="primary" onClick={this.handleSubmit}>
                Submit
              </Button>
            </Card.Body>
          </Card>
        </Form>
      </Col> 
    )
  }



  /***** HELPER FUNCTIONS BEGIN *****/
  addNewWriteupToExistingWriteups = (writeups, newWriteupID) => {
    writeups[newWriteupID] = {
      client: this.state.formClient,
      incidentDate: this.state.formDate,
      reason: this.state.formReason,
      reportDate: moment().format("YYYY-MM-DD"),
      reportAuthor: this.props.auth.uid,
      status: "Not seen",
    };

    return writeups;
  }

  clearFormFields = () => {
    this.setState({formClient: ""});
    this.setState({formDate: ""});
    this.setState({formReason: ""});
  }

  emailWriteUptoHR = () => {
    const templateParams = {
      issuerName:  `${this.props.users[this.props.auth.uid].firstName} ${this.props.users[this.props.auth.uid].lastName}`,
      techName: `${this.props.userData.firstName} ${this.props.userData.lastName}`,
      client: this.state.formClient,
      date: moment().format("MM/DD/YYYY"),
      reason: this.state.formReason,
    }

    emailjs.send('scheduler@murphymckay', 'template_pavvbhs', templateParams, "user_UgmdOeeH6OCjwrAqPoGpi")
      .then((result) => {
        console.log(result.text);
      }, (error) => {
        console.log(error.text);
      });
  }
  /***** HELPER FUNCTIONS END *****/
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    addWriteup: (userID, writeups) => dispatch(addWriteup(userID, writeups))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewWriteup);