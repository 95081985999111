import React, { Component } from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';

import ProjectView from './projectView/ProjectView'

const localizer = momentLocalizer(moment);

class UserCalendar extends Component {
    constructor(props) {
        super(props);
    
        this.state = {
            activeProject: {},
        };
    };

    eventStyleGetter = (event) => {
        let backgroundColor = 'lightgrey';
        let color = 'black';
    
        switch (event.userConfirmStatus) {
            case 'finished':
                backgroundColor = 'lavender';
                color = 'black';
                break;
            case 'requested':
                backgroundColor = 'blue';
                color = 'white';
                break;
            case 'needToConfirm':
                backgroundColor = 'red';
                color = 'white';
                break;
            case 'confirmed':
                backgroundColor = 'green';
                color = 'white';
                break;
            case 'denied':
            case 'droppedOff':
            case 'removed':
                backgroundColor = 'grey';
                color = 'white';
                break;
            default:
                backgroundColor = 'grey';
                color = 'white';
                break;
        }
    
        return {
            style: {
                backgroundColor: backgroundColor,
                borderRadius: '0px',
                opacity: 0.8,
                color: color ? color : 'white',
                border: '0px',
                display: 'block'
            }
        };
    };

    render() {
        const { auth, projects, dateProjectsArray, profile, adminEmail, userRole } = this.props;

        // calendar and projectview are connected
        return (
            <Container fluid>
                <Row>
                    <Col xs={9}>
                        <Calendar
                            localizer={localizer}
                            events={dateProjectsArray}
                            titleAccessor='client'
                            startAccessor="startTime"
                            endAccessor="endTime"
                            onSelectEvent={(event) => this.setState({activeProject: event.refLocation})} 
                            eventPropGetter={(this.eventStyleGetter)}
                            style={{ height: 1000 }}
                            views={['month', 'week', 'day']}
                        />
                    </Col>

                    <Col xs={3}>
                        <ProjectView
                            auth={auth}
                            id={this.state.activeProject.id}
                            profile={profile}
                            project={projects[this.state.activeProject.id]}
                            userRole={userRole}
                            adminEmail={adminEmail}
                        />
                    </Col>
                </Row>
            </Container>
        );
    };
};

export default UserCalendar;
