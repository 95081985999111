import React, { Component } from 'react';

import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';

import moment from 'moment';

import Writeup from './Writeup';

class Writeups extends Component {
  state = {};

  render() {
    const { auth, profile, users } = this.props;

    var writeupEntries = Object.entries(profile.writeups);
    writeupEntries = this.sortWriteupsByDate(writeupEntries);


    if (writeupEntries.length !== 0) {
      return(
        <Card style={{marginTop: 20}} className="ProfileCard">
          <Card.Header as="h5"> Writeups </Card.Header>
  
          <Card.Body>
            <Row>
            {writeupEntries.map(entry => {
              const writeupID = entry[0];
              const writeupData = entry[1];

              return <Writeup key={writeupID} writeupID={writeupID} writeupData={writeupData} userID={auth.uid} users={users}/>
            })}
            </Row>
          </Card.Body>
        </Card>
      )
    } else {
      return(
        <> </>
      )
    }
  }



  /***** HELPER FUNCTIONS BEGIN *****/
  sortWriteupsByDate = (writeupEntries) => {
    writeupEntries.sort(function (a,b) {        
      if (moment(a[1].incidentDate).isAfter(b[1].incidentDate)) return 1;
      if (moment(a[1].incidentDate).isSameOrBefore(b[1].incidentDate)) return -1;
      return 0;
    })

    return writeupEntries;
  }
  /***** HELPER FUNCTIONS END *****/
}

export default Writeups;