import React, { Component } from 'react'
import { connect } from 'react-redux'

import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import moment from 'moment';

import BasicInfo from './BasicInfo'
import ModalDenyProject from './Modals/ModalDenyProject'
import ModalDropOff from './Modals/ModalDropOff'

import { confirmUserWithResponse, userDeny, addToActivityLog } from '../../../../../store/actions/projectActions'

// ProjectView mainly reads from Props (passed in) to be able to render text and complete functions.
// This is done by destructuring props into eventDetails, and then referencing eventDetails (ex:  <p>{eventDetails.client}</p>)
// However, because we cannot manipulate props, isVisible is created here and handled in state because it is manipulated by a function (handleDelete)

class ProjectView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            mainIsVisible: false,
            connectIsVisible: false,
        }
    }

    // Because ProjectView receives new events, props will be different on each render
    
    handleConfirm = (refLocation, userID, userRole) => {
        this.props.confirmUserWithResponse(refLocation, userID, userRole);
        this.props.addToActivityLog(refLocation, `${userRole.lower} confirm`, userID, userID, userRole);
    }

    render() {
        const { auth, id, profile, project, adminEmail, userRole } = this.props;
        const subtitles = {
            signup: "You have requested to be signed up for this project",
            userConfirmed: `You are a ${userRole.lower} for this project`,
            denied: "You have denied to be further involved in this project",
            dropOff: "You have dropped off of this project",
            removed: "You have been removed from this project",
            waitlist: "You are on the waitlist for this project, keep an eye out for updates",
            finished: "This project has been completed, expense reports may still be submitted"
        };

        const getHref = (role) => {
            switch (role) {
                case 'supervisor':
                    return "/s/";
                case 'lead':
                    return "/l/";
                case 'tech':
                    return "/t/";
                case 'admin':
                    return "/a/";
                default:
                    return "/t"; // Default case if userRole is not one of the expected values
            }
        };
    
        if (!project || !project.phase) {
            return (
                <div></div>
            );
        }

        /* User has requested project and is awaiting scheduler */
        if ((project.phase === 'scheduled' || project.phase === 'test' ) && 
            project[`proposed${userRole.capitalize}List`] && 
            Object.keys(project[`proposed${userRole.capitalize}List`]).includes(auth.uid)) {
                return (
                    <div>
                        <Card body style={{ marginTop: 45 }}>
                            <BasicInfo project={project} subtitle={subtitles.signup} />
                        </Card>
                    </div>
                );
        }

        if ((project.phase === 'scheduled' || project.phase === 'confirmed') && 
            project[`confirmed${userRole.capitalize}List`] && 
            Object.keys(project[`confirmed${userRole.capitalize}List`]).includes(auth.uid)) {
            
            const userStatus = project[`confirmed${userRole.capitalize}List`][auth.uid];

            /* User has not responded yet */
            if (userStatus === 'no response') {
                return (
                    <div>
                        <Card body style={{ marginTop: 45 }}>
                            <BasicInfo project={project} />
                            <Row style={{ marginLeft: "0px" }}>
                                <Button style={{ marginRight: 10 }} variant="success" onClick={() => this.handleConfirm(project.refLocation, auth.uid, userRole)}>
                                    Confirm
                                </Button>
                                <ModalDenyProject
                                    refLocation={project.refLocation}
                                    userID={auth.uid}
                                    client={project.client}
                                    startTime={project.startTime}
                                    profile={profile}
                                    adminEmail={adminEmail}
                                    userRole={userRole}
                                />
                            </Row>
                        </Card>
                    </div>
                );
            } 

            /* User has confirmed */
            if (userStatus === 'confirmed') {
                return (
                    <div>
                        <Card body style={{ marginTop: 45 }}>
                            <BasicInfo project={project} subtitle={subtitles.userConfirmed} />
                            <Row>
                                <Col>
                                    <Button style={{ marginLeft: 0 }}
                                        href={getHref(userRole.lower) + 
                                        moment(project.startTime, "YYYY-MM-DD hh:mm").format('YYYY') + '/' +
                                        moment(project.startTime, "YYYY-MM-DD hh:mm").format('MMMM') + '/' +
                                        id}>
                                        View Project
                                    </Button>
                                </Col>
                                <Col className="text-right">
                                    <ModalDropOff
                                        refLocation={project.refLocation}
                                        userID={auth.uid}
                                        client={project.client}
                                        startTime={project.startTime}
                                        profile={profile}
                                        adminEmail={adminEmail}
                                        userRole={userRole}
                                    />
                                </Col>
                            </Row>
                        </Card>
                    </div>
                );
            }

            /* Other user responses */
            if (userStatus === 'denied - unseen' || userStatus === 'denied') {
                return (
                    <div>
                        <Card body style={{ marginTop: 45 }}>
                            <BasicInfo project={project} subtitle={subtitles.denied} />
                        </Card>
                    </div>
                );
            }

            if (userStatus === 'dropped off - unseen' || userStatus === 'dropped off') {
                return (
                    <div>
                        <Card body style={{ marginTop: 45 }}>
                            <BasicInfo project={project} subtitle={subtitles.dropOff} />
                        </Card>
                    </div>
                );
            }

            // if (userStatus === 'removed') {
            //     return (
            //         <div>
            //             <Card body style={{ marginTop: 45 }}>
            //                 <BasicInfo project={project} subtitle={subtitles.removed} />
            //             </Card>
            //         </div>
            //     );
            // }

            if (Object.keys(project[`proposed${userRole.capitalize}List`]).includes(auth.uid)) {
                return (
                    <div>
                        <Card body style={{ marginTop: 45 }}>
                            <BasicInfo project={project} subtitle={subtitles.waitlist} />
                        </Card>
                    </div>
                );
            }


        }

        /* Project has finished */
        if (project.phase === 'finished') {
            return (
                <div>
                    <Card body style={{ marginTop: 45 }}>
                        <BasicInfo project={project} subtitle={subtitles.finished} />
                        <Button style={{ marginLeft: 0 }}
                            href={getHref(userRole.lower) + 
                            moment(project.startTime, "YYYY-MM-DD hh:mm").format('YYYY') + '/' +
                            moment(project.startTime, "YYYY-MM-DD hh:mm").format('MMMM') + '/' +
                            id}>
                            View Project
                        </Button>
                    </Card>
                </div>
            );
        }
       
        return (
            <div></div>
        );

    }

}


const mapDispatchToProps = dispatch => {
    return {
        confirmUserWithResponse: (refLocation, userID, userRole) => dispatch(confirmUserWithResponse(refLocation, userID, userRole)),
        userDeny: (refLocation, userID, userRole) => dispatch(userDeny(refLocation, userID, userRole)),
        addToActivityLog: (refLocation, action, authorID, subjectID, userRole) => dispatch(addToActivityLog(refLocation, action, authorID, subjectID, userRole)),
    }
};

export default connect(null, mapDispatchToProps)(ProjectView);