import React, { Component } from 'react'
import { connect } from '../../../node_modules/react-redux'
import { signIn, resetPasswordEmail } from '../../store/actions/authActions'
import { addUserLastTimeIn } from '../../store/actions/userActions'
import { Redirect } from 'react-router-dom'
import firebase from 'firebase/app'

class SignIn extends Component {
  state = {
    email: '',
    password: '',
    passwordEmail: '',
    showPasswordReset: false
  }

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value
    })
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.signIn(this.state);
    this.afterSignIn();
  }

  handlePasswordSubmit = (e) => {
    e.preventDefault();
    this.props.resetPasswordEmail(this.state.passwordEmail)
  }

  showPasswordReset = () => {
    this.setState({showPasswordReset: true})
  }

  afterSignIn = () => {
    firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)
      .then(() => {
        // Existing and future Auth states are now persisted in the current
        // session only. Closing the window would clear any existing state even
        // if a user forgets to sign out.
        // ...
        // New sign-in will be persisted with session persistence.
        return firebase.auth().signInWithEmailAndPassword(this.state.email, this.state.password);
      })
      .catch((error) => {
        console.error('Error after signin:', error);
      });
  }

  

  render() {
    const { authError, auth, passResetError, passResetSuccess } = this.props;
    if (auth.uid) {
      this.props.addUserLastTimeIn(auth.uid)
      return <Redirect to='/' />
    }

    return (
      <div className="container">
        <form className="white" onSubmit={this.handleSubmit}>
          <h5 style={{marginTop: 5}} className="grey-text text-darken-3">Sign In</h5>
          <div className="input-field">
            <label htmlFor="email">Email</label>
            <input type="email" id='email' onChange={this.handleChange} />
          </div>
          <div className="input-field">
            <label htmlFor="password">Password</label>
            <input type="password" id='password' onChange={this.handleChange} />
          </div>
          <div className="input-field">
            <button className="btn pink lighten-1 z-depth-0 white-text">Login</button>
            <div className="red-text center">
              { authError ? <p>{authError}</p> : null }
            </div>
          </div>
        </form>

        <button className="btn blue lighten-1 z-depth-0 white-text" onClick={this.showPasswordReset}>Forgot Password?</button>
        {this.state.showPasswordReset && (
          <form className="white" onSubmit={this.handlePasswordSubmit}>
            <div className="input-field">
              <label htmlFor="passwordEmail">Email Associated With Account</label>
              <input type="email" id='passwordEmail' onChange={this.handleChange} />
            </div>

            <div className="input-field">
              <button className="btn blue lighten-1 z-depth-0 white-text">Submit</button>
              <div className="red-text center">
                { passResetError ? <p>{passResetError}</p> : null }
              </div>
              <div className="blue-text center">
                { passResetSuccess ? <p>{passResetSuccess}</p> : null }
              </div>
            </div>
          </form>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.authError,
    passResetError: state.auth.passResetError,
    passResetSuccess: state.auth.passResetSuccess,
    auth: state.firebase.auth
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    signIn: (creds) => dispatch(signIn(creds)),
    resetPasswordEmail: (email) => dispatch(resetPasswordEmail(email)),
    addUserLastTimeIn: (userID) => dispatch(addUserLastTimeIn(userID)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignIn)