import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { Redirect } from 'react-router-dom';
import moment from 'moment';

import ProjectGroup from './ProjectGroup';
import ProjectSignUpMobile from './mobile/ProjectSignUpMobile';
import './ProjectSignUp.css';

import { checkin } from '../../../../store/actions/userActions';
import LoadingWheel from '../../../assets/Loading.gif';

import { formatUserRole } from '../../../../utils/userRoleUtils';

class ProjectSignUp extends Component {
  componentDidMount() {
    const { auth, checkin } = this.props;
    // Automatically check in user when they click project signup
    checkin(auth.uid, moment().subtract(moment().day() - 1, 'days').format('M-D-YY'));
  }
  
  filterAndSortProjects = (projects, profile, auth, userRole) => {
    const availableProjects = Object.values(projects)
      .filter(project => {
        if (!project) return false;
  
        const isScheduledOrTest = project.phase === 'scheduled' || (project.phase === 'test' && profile.role === 'test');
        if (!isScheduledOrTest) return false;

        // Once a user is in the confirmedList, they can't re-signup for the same project. They will have to be manually added again if they dropoff or are removed.
        const confirmedList = project[`confirmed${userRole.capitalize}List`];
        if (confirmedList && confirmedList.hasOwnProperty(auth.uid)) {
          return false;
        }

        return true;
      })
      .sort((a, b) => a.startTime.localeCompare(b.startTime));
  

    // meant for duplicates but actually for connected projects that are restated in ProjectGroup
    const removedEntries = [];
    availableProjects.map((project) => {
      if (project.connectedProjects.length) {
        project.connectedProjects.map(connectedProject => {
          availableProjects.map((project, index) => {
            if (project.refLocation.id === connectedProject.id  ) { 
              removedEntries.push(availableProjects.splice(index, 1))
            }
          })
        })
      }
    })

    return { availableProjects, removedEntries };
  };

  
  render() {
    const { auth, profile, projects } = this.props;

    if (!auth.uid) return <Redirect to='/signin' />
    if (profile.role === 'admin') return <Redirect to='/' />
    
    if (!profile.isLoaded) {
      return (
        <div className="customDashboardContainer1">
          <a href="/" className="returnButton">&laquo; Return</a>
          <div className="container center">
            <img className="LoadingWheel" src={LoadingWheel} alt="loading..." />
          </div>
        </div>
      );
    }

    if (!projects) {
      return (
        <div className="customDashboardContainer1">
          <a href="/" className="returnButton">&laquo; Return</a>
          <div className="container center">
            There are no projects posted at this time!
          </div>
        </div>
      );
    }

    const userRole = formatUserRole(profile.role)
    const { availableProjects, removedEntries } = this.filterAndSortProjects(projects, profile, auth, userRole);

    if (window.innerWidth < 1200) {
      return <ProjectSignUpMobile auth={auth} availableProjects={availableProjects} projects={projects}  profile={profile} removedEntries={removedEntries} userRole={userRole} />;
    }

    return (
      <div className="customDashboardContainer1">
        <a href="/" className="returnButton">&laquo; Return</a>

        {availableProjects.length ? availableProjects.map(project => (
          <ProjectGroup
            profile={profile}
            key={project.refLocation.id}
            auth={auth}
            projects={projects}
            project={project}
            removedEntries={removedEntries}
            userRole={userRole}
          />
        )) : (
          <div className="container center">
            There are no projects posted at this time!
          </div>
        )}

      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.firebase.auth,
  profile: state.firebase.profile,
  projects: {
    ...state.firestore.data.LastMonthProjects,
    ...state.firestore.data.MonthProjects,
    ...state.firestore.data.NextMonthProjects,
  }
});

const mapDispatchToProps = (dispatch) => ({
  checkin: (userID, weekOf) => dispatch(checkin(userID, weekOf))
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect(() => [
    {
      collection: 'SchedulerProjects', doc: moment().format('YYYY'),
      subcollections: [{ collection: moment().format('MMMM') }],
      storeAs: 'MonthProjects'
    },
    {
      collection: 'SchedulerProjects', doc: moment().add(1, 'M').format('MMMM') === "January" ? moment().add(1, 'Y').format('YYYY') : moment().format('YYYY'),
      subcollections: [{ collection: moment().add(1, 'M').format('MMMM') }],
      storeAs: 'NextMonthProjects'
    },
    {
      collection: 'SchedulerProjects', doc: moment().subtract(1, 'M').format('MMMM') === "December" ? moment().subtract(1, 'Y').format('YYYY') : moment().format('YYYY'),
      subcollections: [{ collection: moment().subtract(1, 'M').format('MMMM') }],
      storeAs: 'LastMonthProjects'
    },
  ])
)(ProjectSignUp);