import React, { Component } from 'react';
import { connect } from 'react-redux';

import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

import moment from 'moment'

import { v4 as uuidv4 } from 'uuid';
import { storage } from '../../config/fbConfig'

import { updateCertifications, updateExperience } from '../../store/actions/userActions';
import './UserInfoProfile.css'


class Certifications extends Component {
  state = {
    file: null,
    certification: "",
    experience: "",
  };

  handleFileChange = (event) => {
    this.setState({file: event.target.files[0]});
  }

  handleFileSubmit = (refLocation, startTime, client) => {

    if (this.state.file === null) {
        //this.setState({missingPictureError: true});
        console.log("error, no file")
        return;
    }

    // Upload the image to Firebase Storage
    const year = moment().format('YYYY');
    const month = moment().format('MMMM');
    const file = this.state.file;
    const fileName = uuidv4()

    //this.setState({noSubmission: false})
    //this.setState({submitFailure: false})
    //this.setState({submitSuccess: false})
    //this.setState({submitLoading: true})

    const uploadTask = storage.ref(`resumes/${fileName}`).put(file);
    uploadTask.on('state_changed',
    (snapshot) => {
        // progress function...
        //const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        //this.setState({progress});
    },
    (error) => {
        // error function...
        this.setState({submitLoading: false})
        this.setState({submitFailure: true})
        console.log(error);
    }, 
    () => {
        // complete function...
        storage.ref("resumes").child(fileName).getDownloadURL().then(url => {
            // Once the URL is given back, proceed with creating the expense report in Firestore
            console.log(url);
            this.setState({submitLoading: false})
            this.setState({submitSuccess: true})
            // this.props.addBartExpense(
            //     refLocation, this.props.auth.uid,
            //     this.state.toOrigin, this.state.toDestination,
            //     this.state.leaveOrigin, this.state.leaveDestination,
            //     Number(this.state.toCost), Number(this.state.leaveCost),
            //     url, startTime, client
            // )
        })
    });
  }

  handleCertExpChange = (e) => {
    this.setState({[e.target.id]: e.target.value})
  }

  handleCertSubmit = () => {
    const profile = this.props.profile;
    var certifications = [...profile.certifications]
    if (certifications.includes(this.state.certification)) {
      return
    } else {
      certifications.push(this.state.certification);
      this.props.updateCertifications(this.props.auth.uid, certifications);
    }
  }

  handleExpSubmit = () => {
    const profile = this.props.profile;
    var experience = [...profile.experience]
    if (experience.includes(this.state.experience)) {
      return
    } else {
      experience.push(this.state.experience);
      this.props.updateExperience(this.props.auth.uid, experience);
    }
  }

  render() {
    const { auth, profile } = this.props

    const ComptiaCerts = ["CompTIA ITF+", "CompTIA A+", "CompTIA Network+", "CompTIA Security+", "CompTIA Cloud+", "CompTIA Linux+", "CompTIA Server+", "CompTIA CySA+", "CompTIA CASP+", "CompTIA PenTest+", "CompTIA Data+", "CompTIA Project+", "CompTIA CTT+", "CompTIA Cloud Essentials+"];
    const GoogleCerts = ["Google - Digital Marketing & E-commerce", "Google - IT Support", "Google - Data Analytics", "Google - Project Management", "Google - UX Design"];
    const MicrosoftCerts = ["Microsoft - Placement Cert 1", "Microsoft - Placement Cert 2", "Microsoft - Placement Cert 3"];
    const CiscoCerts = ["Cisco - Technology (Entry)", "Cisco - Technology (Associate)", "Cisco - Technology (Professional)", "Cisco - Technology (Expert)"];

    const ServerExperience = ["Server Racks - <1 year", "Server Racks - 1 to 2 years", "Server Racks - 2 to 3 years", "Server Racks - 3+ years"];
    const WindowsExperience = ["Windows Imaging - <1 year", "Windows Imaging - 1 to 2 years", "Windows Imaging - 2 to 3 years", "Windows Imaging - 3+ years"];
    const MacExperience = ["Mac Imaging - <1 year", "Mac Imaging - 1 to 2 years", "Mac Imaging - 2 to 3 years", "Mac Imaging - 3+ years"];

    var allCerts = [...ComptiaCerts, ...GoogleCerts, ...MicrosoftCerts, ...CiscoCerts];
    allCerts.sort();

    var allExperience = [...ServerExperience, ...WindowsExperience, ...MacExperience];
    allExperience.sort();
    
    if (profile.isLoaded) {
      return (
        <Card className="ProfileCard">
          <Card.Header as="h5"> Certifications & Experience </Card.Header>
          <Card.Body>
            <Container>
              <Row style={{marginLeft: 15}}>
                <p style={{marginRight: 15}}> Add a resume: </p>
                <input type="file" accept=".pdf" onChange={this.handleFileChange}/>
                {this.state.file && (
                  <Button onClick={this.handleFileSubmit}> Upload Resume </Button>
                )}
              </Row>

              <Row className="ButtonLine">
                <Col>
                  <h5> Certifications </h5>
                  <ol>
                    {profile.certifications.map(cert => {
                      return(<li> {cert} </li>)
                    })}
                  </ol>

                  <p> Add a new certification: </p>

                  <Form.Group controlId="certification">
                    <Row>
                      <Col xs={8}>
                        <Form.Control as="select" onChange={this.handleCertExpChange}>
                          <option> Choose certification... </option>
                          {allCerts.map(cert => {
                            return (<option value={cert}> {cert} </option>)
                          })}
                        </Form.Control>
                      </Col>

                      <Col xs={4}>
                        {this.state.certification && (
                          <Button onClick={this.handleCertSubmit}> Submit </Button>
                        )}
                      </Col>
                    </Row>
                  </Form.Group>
                </Col>

                <Col>
                  <h5> Experience </h5>
                  <ol>
                    {profile.experience.map(exp => {
                      return(<li> {exp} </li>)
                    })}
                  </ol>

                  <p> Add experience: </p>

                  <Form.Group controlId="experience">
                    <Row>
                      <Col xs={8}>
                        <Form.Control as="select" onChange={this.handleCertExpChange}>
                          <option> Choose experience... </option>
                          {allExperience.map(experience => {
                            return (<option value={experience}> {experience} </option>)
                          })}
                        </Form.Control>
                      </Col>
                      
                      <Col xs={4}>
                        {this.state.experience && (
                          <Button onClick={this.handleExpSubmit}> Submit </Button>
                        )}
                        </Col>
                    </Row>

                  </Form.Group>
                </Col>
              </Row>
            </Container>
          </Card.Body>
        </Card>
      )
    } else {
      return(
        <div>Loading...</div>
      )
    }
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateCertifications: (userID, certifications) => dispatch(updateCertifications(userID, certifications)),
    updateExperience: (userID, experience) => dispatch(updateExperience(userID, experience)),
  }
}

export default connect(null, mapDispatchToProps)(Certifications);