import React from 'react';
import { Link } from 'react-router-dom';
import SignedInLinks from './SignedInLinks';
import SignedOutLinks from './SignedOutLinks';
import { connect } from 'react-redux'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import image1 from '../assets/1.png'

import './Navbar.css'

const Navbar = (props) => {
    const { auth, profile } = props;
    const links = auth.uid ? <SignedInLinks profile={profile}/> : <SignedOutLinks />;
    
    return (
        <nav className="nav-wrapper grey darken-3">
            <div className="container">
                {window.innerWidth > 1200 && (
                    <Row>
                        <Col>
                            <img src={image1} className="mmaImage2" alt="MMA logo"/>
                        </Col>
                        

                        <Col>
                            <Link to='/' className="SchedulerLogo noHover">Scheduler</Link>
                        </Col>

                        <Col>
                            { auth.isLoaded && links }
                        </Col>
                    </Row>
                )}

                {window.innerWidth < 1200 && (
                    <Row className="HeaderRow">
                        <Col className="HeaderCol" xs={10}>
                            <Link to='/' className="SchedulerLogoMobile noHover">Scheduler</Link>
                        </Col>

                        <Col className="HeaderCol" xs={2}>
                            { auth.isLoaded && links }
                        </Col>
                    </Row>
                    
                )}
            </div>
        </nav>  
    )
}

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
        profile: state.firebase.profile
    }
}

export default connect(mapStateToProps)(Navbar);