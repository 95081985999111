// BATCHED WAY: WORKS BEST - NEEDS DISPATCH ADDED
export const createComment = (commentID, refLocation, userID, comment) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        
        const firestore = getFirestore();
        const batch = firestore.batch();

        batch.update(refLocation, ({
            [`comments.${commentID}.comment`] : comment
        }))
        batch.update(refLocation, ({
            [`comments.${commentID}.userID`] : userID
        }))
        batch.update(refLocation, ({
            [`comments.${commentID}.timestamp`] : new Date()
        }))

        batch.commit();
    }
};


// OLD WAY, SINGLE TRANSACTIONS - TECHNICALLY WORKS, BUT NULL ERRORS ARE THROWN BECAUSE THE OBJECT DOESNT CREATE FAST ENOUGH
/*
export const createComment = (commentID, projectID, userID, comment) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore();
        firestore.collection('testProjects').doc(projectID).update({
            [`comments.${commentID}.comment`] : comment
        }).then(() => {})
        .then(() => {
            dispatch({ type: 'CREATE_COMMENT', comment });
        }).catch((err) => {
            dispatch({ type: 'CREATE_COMMENT_ERROR', err });
        })
        firestore.collection('testProjects').doc(projectID).update({
            [`comments.${commentID}.userID`] : userID
        }).then(() => {})
        .then(() => {
            dispatch({ type: 'CREATE_COMMENT', comment });
        }).catch((err) => {
            dispatch({ type: 'CREATE_COMMENT_ERROR', err });
        })
        firestore.collection('testProjects').doc(projectID).update({
            [`comments.${commentID}.timestamp`] : new Date()
        }).then(() => {})
        .then(() => {
            dispatch({ type: 'CREATE_COMMENT', comment });
        }).catch((err) => {
            dispatch({ type: 'CREATE_COMMENT_ERROR', err });
        })
    }
};
*/




