import React, { Component } from 'react';

import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';

import './UserInfoProfile.css'

class ContactInfo extends Component {
  state = {};

  formatPhoneNumber = (phoneNumberString) => {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return null;
  }

  render() {
    const { profile } = this.props

    return(
      <Card style={{marginTop: 20}} className="ProfileCard">
        <Card.Header as="h5">Contact Info</Card.Header>

        <Card.Body>
          <Row style={{marginBottom: 0}}>
            <h5 style={{marginLeft: 15}}>{`Name:`}</h5>
            <p style={{marginLeft: 10}}> {`${profile.firstName} ${profile.lastName}`}</p>
          </Row>

          <Row style={{marginBottom: 0}}>
            <h5 style={{marginLeft: 15}}>{`Email:`}</h5>
            <p style={{marginLeft: 10}}> {`${profile.email}`}</p>
          </Row>

          <Row style={{marginBottom: 0}}>
            <h5 style={{marginLeft: 15}}>{`Phone:`}</h5>
            <p style={{marginLeft: 10}}> {`${this.formatPhoneNumber(profile.phone)}`}</p>
          </Row>
        </Card.Body>
      </Card>
    )
  }
}

export default ContactInfo;