import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyAIdnrSt5djc69QUz2Kjovvpa5MeIUAQaQ",
    authDomain: "mmatablet-11c34.firebaseapp.com",
    databaseURL: "https://mmatablet-11c34.firebaseio.com",
    projectId: "mmatablet-11c34",
    storageBucket: "mmatablet-11c34.appspot.com",
    messagingSenderId: "870744547567",
    appId: "1:870744547567:web:b12d87e94bf47865167b1a"
  };

  firebase.initializeApp(firebaseConfig);

  if (window.location.hostname === 'localhost') {
    firebase.firestore().useEmulator('localhost', 8000);
    firebase.auth().useEmulator('http://localhost:9099/');
  }

  const storage = firebase.storage();
  //firebase.firestore().settings({ timestampsInSnapshots: true });
  const db = firebase.firestore();

  export  {
      storage, firebase, db as default
  }