import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import firebase from 'firebase/app';
import 'firebase/firestore';

export const createProject = (projectArray) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {

        const firestore = getFirestore();
        const batch = firestore.batch();

        var refArray = []

        projectArray.forEach((project) => {
            var fileRef = firestore.collection('SchedulerProjects')
                                   .doc(moment(project.startTime, "YYYY-MM-DD hh:mm").format('YYYY'))
                                   .collection(moment(project.startTime, "YYYY-MM-DD hh:mm").format('MMMM'))
                                   .doc(uuidv4())

            refArray.push(fileRef)
        })

        projectArray.forEach((project, index) => {
            batch.set(refArray[index], ({
                ...project, 
                proposedTechList: {},
                proposedLeadList: {},
                proposedSupervisorList: {},
                confirmedTechList: {},
                confirmedLeadList: {},
                confirmedSupervisorList: {},
                connectedProjects: refArray.filter(function(x) { return x !== refArray[index]; }),
                comments: {},
                createdAt: new Date(),
                phase: 'proposed',
                refLocation: refArray[index]
                })
            )
        })

        batch.commit();
    }
}

//////////////////////////////     CONFIRMED USER LIST     //////////////////////////////

export const confirmUserWithNoResponse = (refLocation, userID, userRole) => { 
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        refLocation.update({
            [`confirmed${userRole.capitalize}List.${userID}`] : "no response"
        }).then(() => {
            dispatch({ type: `CONFIRM_${userRole.upper}_NO_RESPONSE`, userID });
        }).catch((err) => {
            dispatch({ type: `CONFIRM_${userRole.upper}_NO_RESPONSE_ERR`, err });
        })
    }
}

export const confirmUserWithResponse = (refLocation, userID, userRole) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        refLocation.update({
            [`confirmed${userRole.capitalize}List.${userID}`]: 'confirmed'
        }).then(() => {
            dispatch({ type: `CONFIRM_${userRole.upper}_RESPONSE`, refLocation });
        }).catch((err) => {
            dispatch({ type: `CONFIRM_${userRole.upper}_RESPONSE_ERR`, err });
        })
    }
}

export const removeConfirmedUser = (refLocation, userID, userRole) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        refLocation.update({
            [`confirmed${userRole.capitalize}List.${userID}`] : "removed"
        }).then(() => {
            dispatch({ type: `REMOVE_${userRole.upper}`, userID });
        }).catch((err) => {
            dispatch({ type: `REMOVE_${userRole.upper}_ERR`, err });
        })
    }
}

export const markUnseenAsSeen = (refLocation, userID, userRole, seenAction) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        refLocation.update({
            [`confirmed${userRole.capitalize}List.${userID}`]: seenAction
        }).then(() => {
            dispatch({ type: `MARK_${userRole.upper}_ACTION_AS_SEEN`, userID });
        }).catch((err) => {
            dispatch({ type: `MARK_${userRole.upper}_ACTION_ERR`, err });
        })
    }
}

export const setUserNoShow = (refLocation, userID, userRole) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        refLocation.update({
            [`confirmed${userRole.capitalize}List.${userID}`]: "no show"
        }).then(() => {
            dispatch({ type: `${userRole.upper}_SET_NO_SHOW`, userID });
        }).catch((err) => {
            dispatch({ type: `${userRole.upper}_SET_NO_SHOW_ERR`, err });
        })
    }
}

export const revertUserNoShow = (refLocation, userID, userRole) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        refLocation.update({
            [`confirmed${userRole.capitalize}List.${userID}`]: "confirmed"
        }).then(() => {
            dispatch({ type: `${userRole.upper}_REVERT_NO_SHOW`, userID });
        }).catch((err) => {
            dispatch({ type: `${userRole.upper}_REVERT_NO_SHOW_ERR`, err });
        })
    }
}


export const userDeny = (refLocation, userID, userRole) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        refLocation.update({
            [`confirmed${userRole.capitalize}List.${userID}`]: 'denied - unseen'
        }).then(() => {
            dispatch({ type: `${userRole.upper}_DENIED`, refLocation });
        }).catch((err) => {
            dispatch({ type: `${userRole.upper}_DENIED_ERR`, err });
        })
    }
}

export const userDropOff = (refLocation, userID, userRole) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        refLocation.update({
            [`confirmed${userRole.capitalize}List.${userID}`]: 'dropped off - unseen'
        }).then(() => {
            dispatch({ type: `${userRole.upper}_DROPPED`, refLocation });
        }).catch((err) => {
            dispatch({ type: `${userRole.upper}_DROPPED_ERR`, err });
        })
    }
}

//////////////////////////////     PROPOSED USER LIST     //////////////////////////////

export const addUserProposed = (refLocation, userID, userRole) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore();
        refLocation.update({
            [`proposed${userRole.capitalize}List.${userID}`]: firestore.FieldValue.serverTimestamp()
        }).then(() => {
            dispatch({ type: `ADD_${userRole.upper}_PROPOSED`, refLocation });
        }).catch((err) => {
            dispatch({ type: `ADD_${userRole.upper}_PROPOSED_ERR`, err });
            console.log(err)
        })
    }
}

export const undoUserProposed = (refLocation, userID, proposedUserList, userRole) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        var currentUsers = {...proposedUserList}
        delete currentUsers[userID]

        refLocation.update({
            [`proposed${userRole.capitalize}List`]: currentUsers
        }).then(() => {
            dispatch({ type: `REMOVED_${userRole.upper}_PROPOSED`, refLocation });
        }).catch((err) => {
            dispatch({ type: `REMOVED_${userRole.upper}_PROPOSED_ERROR`, err });
        })
    }
}


export const removeProposedUser = (refLocation, newProposedUserList, userRole) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        refLocation.update({
            [`proposed${userRole.capitalize}List`]: newProposedUserList
        }).then(() => {
            dispatch({ type: `${userRole.upper}_REMOVED_FROM_PROPOSED`, refLocation });
        }).catch((err) => {
            dispatch({ type: `${userRole.upper}_REMOVED_FROM_PROPOSED_ERROR`, err });
        })
    }
}


//////////////////////////////     DROP OFF     //////////////////////////////
export const logUserDropOff = (refLocation, userID, reason, client, startTime, userRole) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore();
        refLocation.update({
            [`dropOffs.${uuidv4()}`] : {
                userID,
                reason,
                dropTime: firestore.FieldValue.serverTimestamp(),
                client,
                startTime,
                seen: false,
                projectRef: refLocation,
        }}).then(() => {
            dispatch({ type: `${userRole.upper}_DROPPED`, refLocation });
        }).catch((err) => {
            dispatch({ type: `${userRole.upper}_DROPPED_ERR`, err });
        })
    }
}

export const markDropOffAsSeen = (refLocation, dropOffID, dropOffContent) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        refLocation.update({
            [`dropOffs.${dropOffID}`] : { dropOffContent }
        }).then(() => {
            dispatch({ type: 'USER_DROPPED', refLocation });
        }).catch((err) => {
            dispatch({ type: 'USER_DROPPED_ERR', err });
        })
    }
}


//////////////////////////////     OTHER     //////////////////////////////
export const addUserLastTimeIn = (userID) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore();
        firestore.collection('users').doc(userID).update({
            lastSignIn: firestore.FieldValue.serverTimestamp() 
        }).then(() => {
            dispatch({ type: 'ADD_LAST_SIGNIN', userID });
        }).catch((err) => {
            dispatch({ type: 'ADD_LAST_SIGNIN_ERR', err });
        })
    }
}

export const addToActivityLog = (refLocation, action, authorID, subjectID, userRole) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore();
        refLocation.update({
            [`activityLog.${uuidv4()}`] : {
                time: firestore.FieldValue.serverTimestamp(),
                action,
                authorID,
                subjectID,
                userRole: userRole.lower,
        }}).then(() => {
            dispatch({ type: action, refLocation });
        }).catch((err) => {
            dispatch({ type: `${action}_ERR`, err });
        })
    }
}

export const AddToModerationLog = (refLocation, uid, action) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        refLocation.update({
            moderationLog: firebase.firestore.FieldValue.arrayUnion('yes')
        }).then(() => {
            dispatch({ type: 'ADD', refLocation });
        }).catch((err) => {
            dispatch({ type: 'PROPOSED_TO_CONFIRMED_ERR', err });
        })
    }
}

export const addWriteUp = (userID, writeUps) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore();
        firestore.collection('users').doc(userID).update({
            writeUps: writeUps
        }).then(() => {
            dispatch({ type: 'ADD_WRITE_UP', userID });
        }).catch((err) => {
            dispatch({ type: 'ADD_WRITE_UP_ERR', err });
        })
    }
}

// investigate (2024-07-06)
export const addComment = (refLocation, userID, comment) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        refLocation.update({
            [`commentsTech.${userID}`] : comment
        }).then(() => {
            dispatch({ type: 'ADD_TECH_COMMENT', refLocation });
        }).catch((err) => {
            dispatch({ type: 'ADD_TECH_COMMENT_ERR', err });
        })
    }
}

export const updateProjectDetail = (refLocation, detailToUpdate, valueToUse) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        refLocation.update({
            [`${detailToUpdate}`]: valueToUse
        }).then(() => {
            dispatch({ type: 'UPDATE_PROJECT', refLocation });
        }).catch((err) => {
            dispatch({ type: 'UPDATE_PROJECT_ERROR', err });
        })
    }
}

export const updatePhase = (refLocation, phase) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        refLocation.update({
            phase: phase
        }).then(() => {
            dispatch({ type: 'UPDATE_PROJECT', refLocation });
        }).catch((err) => {
            dispatch({ type: 'UPDATE_PROJECT_ERROR', err });
        })
    }
}

// export const sendEmail = () => {
//     return (dispatch, getState, { getFirebase, getFirestore }) => {
//         firestore.collection('SchedulerProjects').doc('newEmail').set({
            
//         }).then(() => {
//             dispatch({ type: 'MARK_TECH_ACTION_AS_SEEN', techToMark });
//         }).catch((err) => {
//             dispatch({ type: 'MARK_TECH_ACTION_ERR', err });
//         })
//     }
// }


//////////////////////////////     CONNECTED PROJECTS     //////////////////////////////
// TESTING NEEDED HERE, STILL ON PROJECTID FORM //

export const addConnectedProject = (projectID, projectIDtoAdd) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore();
        firestore.collection('testProjects').doc(projectID).update({
            connectedProjects: firestore.FieldValue.arrayUnion(projectIDtoAdd)})
        .then(() => {
            dispatch({ type: 'ADD_CONNECTED_PROJECT', projectID });
        }).catch((err) => {
            dispatch({ type: 'ADD_CONNECTED_PROJECT_ERR', err });
        })
    }
}

export const deleteFromConnectedProjects = (refToDelete, refToDeleteFrom) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        refToDeleteFrom.update({
            connectedProjects: firebase.firestore.FieldValue.arrayRemove(refToDelete)})
            .then(() => {
                dispatch({ type: 'REMOVE_CONNECTED_PROJECT', refToDeleteFrom });
            }).catch((err) => {
                dispatch({ type: 'REMOVE_CONNECTED_PROJECT_ERR', err });
            })
    }
}
