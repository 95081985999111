import React, { Component } from 'react';
import { connect } from 'react-redux';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import moment from 'moment';

import './ProjectPost.css';
import bartLogo from '../../../assets/bart-720p.png';
import vaccineIcon from '../../../assets/vaccine.png';

import { addUserProposed, undoUserProposed, addToActivityLog } from '../../../../store/actions/projectActions';

class ProjectPost extends Component {
  constructor(props) {
    super(props);

    const userId = props.auth.uid;
    let isInProposedList = false;
    let buttonMessage = '';
    let isButtonDisabled = false;

    isInProposedList = props.project[`proposed${props.userRole.capitalize}List`] && Object.keys(props.project[`proposed${props.userRole.capitalize}List`]).includes(userId);
    buttonMessage = isInProposedList ? "Shift Requested" : "Signup";
    isButtonDisabled = isInProposedList;

    this.state = {
      signupButton: {
        message: buttonMessage,
        variant: '',
        type: '',
        isDisabled: isButtonDisabled
      }
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.disableAll !== this.props.disableAll) {
      var buttonState = {...this.state.signupButton}
      buttonState.isDisabled = true
      buttonState.message = "Shift Requested"
      this.setState({signupButton: buttonState})
    }
  }

  handleSignup = (refLocation, userID, userRole) => {
    this.props.addUserProposed(refLocation, userID, userRole);

    var tempProp = { ...this.state.signupButton };
    tempProp.message = 'Shift Requested';
    tempProp.isDisabled = true;
    this.setState(() => ({ signupButton: tempProp }));

    this.props.addToActivityLog(refLocation, `${userRole.lower} signup`, userID, userID, userRole);
    this.props.parentCallback(true)
  }

  handleUndo = (refLocation, userID, userRole) => {
    this.props.undoUserProposed(refLocation, userID, this.props.project[`proposed${userRole.capitalize}List`], userRole);

    var tempProp = { ...this.state.signupButton };
    tempProp.message = 'Signup';
    tempProp.isDisabled = false;
    this.setState(() => ({ signupButton: tempProp }));

    this.props.addToActivityLog(refLocation, `${userRole.lower} undo signup`, userID, userID, userRole);

    this.props.parentCallback(false)
  }

  render() {
    const { auth, project, profile, userRole } = this.props;

    return (
      <div className="card z-depth-2 project-summary">
        <div className="card-content grey-text text-darken-3">
          <Container fluid>
            <Row>
              <Col xs={9}>
                <span className="card-title"> <b>{project.client}</b> </span>
                <span className="card-title"> {project.city} </span>
                <span className="card-title"> {project.address} </span>
                <span className="card-title">
                  {moment(project.startTime, "YYYY-MM-DD hh:mm").format('dddd MM/DD - h:mm a')}
                </span>
                {!!project.workstations && project.type !== "Server" && (<span className="card-title"> {project.type + ' - ' + project.workstations + ' Workstations'} </span>)}
                {!!project.workstations && project.type === "Server" && (<span className="card-title"> {project.type + ' - ' + project.workstations + ' Servers'} </span>)}
                {!project.workstations && (<span className="card-title"> {project.type} </span>)}
                {!!project.bartStation && (
                  <span className="cardMediumInfo">
                    <img src={bartLogo} className="bartImage" alt="BART logo"/>
                    <div className="bartText">
                      {' ' + project.bartStation + ' - ' + project.bartDistance + ' mi'}
                    </div>
                  </span>
                )}
                
                {!!project.notes && (<span className="cardSmallInfo"><i>{project.notes}</i></span>)}
              </Col>

              <Col xs={3} className="PostButtonCol">
                {(project.phase === 'scheduled' || project.phase === 'test') && (
                  <Button className="bigButton" variant='primary' size = 'sm' type='submit' disabled={this.state.signupButton.isDisabled}
                      onClick={() => this.handleSignup(project.refLocation, auth.uid, userRole)}
                  >
                      {this.state.signupButton.message}
                  </Button>
                )}

                {((project.phase === 'scheduled' || project.phase === 'test') && this.state.signupButton.isDisabled) && (
                    <Button
                      style={{marginTop: 5}}
                      variant='danger'
                      size = 'sm'
                      type='submit'
                      onClick={() => this.handleUndo(project.refLocation, auth.uid, userRole)}
                    >
                      Undo
                    </Button>
                )}

                {/* <img src={vaccineIcon} className="vaccineImage" alt="Vaccine Icon" /> */}
                
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addUserProposed: (refLocation, userID, userRole) => dispatch(addUserProposed(refLocation, userID, userRole)),
    undoUserProposed: (refLocation, userID, proposedUserList, userRole) => dispatch(undoUserProposed(refLocation, userID, proposedUserList, userRole)),
    addToActivityLog: (refLocation, action, authorID, subjectID, userRole) => dispatch(addToActivityLog(refLocation, action, authorID, subjectID, userRole)),
  };
};

export default connect(null, mapDispatchToProps)(ProjectPost);