import React from 'react';
import moment from 'moment';

const ConnectedProjects = (props) => {
  const PIDtoLine = (projectID) => {
    if (!props.projects) return '';

    let line = '';
    Object.entries(props.projects).forEach(entry => {
      const id = entry[0];
      const project = entry[1];
      if (project) {
        if (id === projectID) {
          if (project.phase === "deleted") {
            line = `${project.client} (${moment(project.startTime, "YYYY-MM-DD hh:mm").format('M/D')}) - DELETED`;
          } else {
            line = `${project.client} (${moment(project.startTime, "YYYY-MM-DD hh:mm").format('M/D')}) - ${project.type}`;
          }
        }
      }
    });

    return line;
  };

  const { project } = props;

  return (
    <div>
      <h2>Connected Projects</h2>

      {project.connectedProjects.length === 0 ? (
        <p><i>No connected projects...</i></p>
      ) : (
        <>
          {project.connectedProjects.filter(Boolean).map(connectedProject => (
            <p key={connectedProject.id}> {PIDtoLine(connectedProject.id)} </p>
          ))}
        </>
      )}
    </div>
  );
};

export default ConnectedProjects;
