import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { Redirect } from 'react-router-dom'

import Container from 'react-bootstrap/Container';

import ApproveReports from './ApproveReports'

import moment from 'moment'

class Header extends Component {
    constructor(props) {
        super(props);

        this.state = {
        }
    }

    render() {
        const { auth, projects, users } = this.props;
        if (!auth.uid) return <Redirect to='/signin' />

        if (projects) {

            // SORT PROJECTS BY DATE //
            var sortedProjects = []
            for (var project in projects) {
                sortedProjects.push([project, projects[project]]);
            }
            sortedProjects.sort(function (a, b) {
                if (a[1].startTime > b[1].startTime) return 1
                if (a[1].startTime < b[1].startTime) return -1
                return 0;
            });

            return(
                <div style={{marginTop: "10px"}}>
                    <Container>
                        {Object.values(sortedProjects).map(entry => {
                            const id = entry[0];
                            const project = entry[1];

                            return <ApproveReports key={id} id={id} project={project} users={users}/>
                        })}
                    </Container>
                </div>
            )
        } else {
            return <div>Loading projects...</div>
        }
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
        users: state.firestore.data.users,
        projects: {
            ...state.firestore.data.MonthProjects,
            ...state.firestore.data.NextMonthProjects,
            ...state.firestore.data.LastMonthProjects,
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect(() => [
        {
            collection : 'SchedulerProjects', doc: moment().format('YYYY'),
            subcollections: [{ collection : moment().format('MMMM') }],
            storeAs: 'MonthProjects'
        },
        {
            collection : 'SchedulerProjects', doc: moment().add(1, 'M').format('MMMM') === "January" ? moment().add(1, 'Y').format('YYYY') : moment().format('YYYY'),
            subcollections: [{ collection : moment().add(1, 'M').format('MMMM') }],
            storeAs: 'NextMonthProjects'
        },
        {
            collection : 'SchedulerProjects', doc: moment().subtract(1, 'M').format('MMMM') === "December" ? moment().subtract(1, 'Y').format('YYYY') : moment().format('YYYY'),
            subcollections: [{ collection : moment().subtract(1, 'M').format('MMMM') }],
            storeAs: 'LastMonthProjects'
        },
        {
            collection: 'users',
        },
    ])
)(Header);
