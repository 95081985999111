import React, {useState, useEffect} from 'react';

import moment from 'moment';

import db from '../../../config/fbConfig';
import BartReport from './children/BartReport';
import ParkingReport from './children/ParkingReport';

const ApproveReports = (props) => {
    const [reports, setReports] = useState([]);

    useEffect(() => {
        const id = props.id;
        const project = props.project;
        const year = String(moment(project.startTime).year());
        const month = moment(project.startTime).format('MMMM');

        const getReports = [];
        const subscriber = db
            .collection('SchedulerProjects').doc(year).collection(month).doc(id).collection('Expenses')
            .onSnapshot((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    getReports.push({
                        ...doc.data(),
                        key: doc.id
                    });
                });
                setReports(getReports);
            });

            return () => subscriber();
    }, [props.project]);

    const { users } = props;

    if (reports.length && Object.keys(users).length) {

        // weird bug, reports get duplicated after any separate project gets approved or denied
        var reportKeys = [];
        reports.forEach((report, index) => {
            if (!reportKeys.includes(report.key)) {
                reportKeys.push(report.key)
            } else {
                reports.splice(index);
            }
        })

        // SORT REPORTS BY NAME //
        var sortedReports = []
        for (var report in reports) {
            sortedReports.push([report, reports[report]]);
        }
        sortedReports.sort(function (a, b) {
            if (users[a[1].userID] !== undefined && users[b[1].userID] !== undefined) {
                if (users[a[1].userID].lastName > users[b[1].userID].lastName) return 1
                if (users[a[1].userID].lastName < users[b[1].userID].lastName) return -1
                return 0;
            } else {
                return 0;
            }
        });

        return (
            Object.values(sortedReports).map(entry => {
                const report = entry[1];

                if (report.status === "pending") {
                    if (report.type === 'BART') {
                        return(
                            <BartReport key={report.key} report={report} users={users}/>
                        )
                    } else if (report.type === "Parking") {
                        return(
                            <ParkingReport key={report.key} report={report} users={users}/>
                        )
                    } else {
                        return null
                    }
                } else {
                    return null
                }
            })
        )
    } else {
        return null
    }
}

export default ApproveReports;