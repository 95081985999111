import React from 'react'
import { NavLink } from 'react-router-dom'
import { connect } from '../../../node_modules/react-redux'
import { signOut } from '../../store/actions/authActions'

const SignedInLinks = (props) => {


  if (window.innerWidth < 1200) {
    return (
      <div>
        <ul className="right">
          <li>
            <NavLink to='/UserProfile' className="btn-floating btn-small waves-effect waves-light pink lighten-1 buttonTop">
              { props.profile.initials }      
            </NavLink>
          </li>
        </ul>
      </div>
    )
  } else {
    return (
      <div>
        <ul className="right">
          <li><a onClick={props.signOut} href={"/"}> Log Out </a></li>
          <li>
            <NavLink to='/userprofile' className="btn-floating btn-small waves-effect waves-light pink lighten-1">
              { props.profile.initials }        
            </NavLink>
          </li>
        </ul>
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    signOut: () => dispatch(signOut())
  }
}

export default connect(null, mapDispatchToProps)(SignedInLinks)

//<li><NavLink to='/create'>New Project</NavLink></li>