import React, { Component } from 'react';
import { connect } from 'react-redux';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';


import { updateRole } from '../../../store/actions/userActions';
import '../Manage.css';

class AssignRoles extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    handleButton (id, role) {
        this.props.updateRole(id, role)
    }

    render() {
        const { users } = this.props;

        // Check the "Users" collection and look for any user with "none" as their role
        // (none is the default role assigned to a user upon account creation)
        var usersWithNoRoles = [];
        Object.entries(users).filter(Boolean).forEach(entry => {
            const user = entry[1];
            if (user.role === "none") {
                usersWithNoRoles.push(entry)
            }
        })

        if (usersWithNoRoles.length) {
            return (
                <Card className="ManageCard">
                    <Card.Header as="h5"> Assign Roles </Card.Header>
                    <Card.Body>
                        {usersWithNoRoles.map(entry => {
                            const id = entry[0];
                            const user = entry[1];

                            return (
                                <div key={id} className="card z-depth-0 project-summary"> 
                                    <div className="card-content grey-text text-darken-3">
                                        <Row style={{marginBottom: 0}}>
                                            <Col xs={12} md={6}>
                                                <span className="card-title"> {user.firstName + ' ' + user.lastName} </span>
                                                <span className="card-title"> {user.email}</span>    
                                            </Col>
                                            
                                            <Col xs={12} md={6}>
                                                <div className="ButtonCol">
                                                    <Button className="RoleButtons" variant="primary" onClick={() => this.handleButton(id, "tech")}>Tech</Button>
                                                    <Button className="RoleButtons" variant="success" onClick={() => this.handleButton(id, "lead")}>Lead</Button>
                                                    <Button className="RoleButtons" variant="warning" onClick={() => this.handleButton(id, "supervisor")}>Super</Button>
                                                    <Button className="RoleButtons" variant="dark" onClick={() => this.handleButton(id, "admin")}>Admin</Button>
                                                    <Button className="RoleButtons" variant="danger" onClick={() => this.handleButton(id, "deleted")}>Delete</Button>
                                                    <Button className="RoleButtons" variant="info" onClick={() => this.handleButton(id, "client")}>Client</Button>
                                                </div>
                                            </Col>
                                        </Row>                  
                                    </div>
                                </div>
                            )
                        })}
                    </Card.Body>
                </Card>
            )
        } else {
            return(
                <Card className="ManageCard">
                    <Card.Header as="h5"> Assign Roles </Card.Header>
                    <Card.Body>
                        <Card.Title> No new users... </Card.Title>
                    </Card.Body>
                </Card>
            )
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateRole: (projectID, role) => dispatch(updateRole(projectID, role)),
    }
}

export default connect(null, mapDispatchToProps)(AssignRoles);