import React, { Component } from 'react';
import { connect } from 'react-redux';
import { useFirestore } from 'react-redux-firebase';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

import moment from 'moment';
import SelectSearch from 'react-select-search';

import { promoteUser, demoteUser, deactivateUser } from '../../../store/actions/userActions';
import vaccineIcon from '../../assets/vaccine.png'

import ModalProjectScope from './Modals/ModalProjectScope';
import ModalClientScope from './Modals/ModalClientScope'; 

class SearchUser extends Component {
    constructor(props) {
        super(props);

        this.state = {
            techSearchID: '',
            leadSearchID: '',
            supervisorSearchID: '',
            adminSearchID: '',
            clientSearchID: '',
            deactivatedSearchID: '',
        }
    }

    handleTechChange = (id) => {
        this.setState({techSearchID: id})
    }

    handleLeadChange = (id) => {
        this.setState({leadSearchID: id})
    }

    handleSupervisorChange = (id) => {
        this.setState({supervisorSearchID: id})
    }

    handleAdminChange = (id) => {
        this.setState({adminSearchID: id})
    }

    handleClientChange = (id) => {
        this.setState({clientSearchID: id})
    }

    handledeactivatedChange = (id) => {
        this.setState({deactivatedSearchID: id})
    }

    isVaccinated = (id) => {
        return this.props.users[id].vaccineStatus
    }

    formatPhoneNumber = (phoneNumberString) => {
        var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
        var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
          return '(' + match[1] + ') ' + match[2] + '-' + match[3];
        }
        return null;
    }

    promote = (userID) => {
        if (this.props.users[userID].role === 'supervisor') {
            this.props.promoteUser(userID, 'admin');
            this.setState({supervisorSearchID: ''});
        } else if (this.props.users[userID].role === 'lead') {
            this.props.promoteUser(userID, 'supervisor');
            this.setState({leadSearchID: ''});
        } else if (this.props.users[userID].role === 'tech') {
            this.props.promoteUser(userID, 'lead');
            this.setState({techSearchID: ''});
        } else if (this.props.users[userID].role === 'deactivated') {
            this.props.promoteUser(userID, 'none');
            this.setState({deactivatedSearchID: ''});
        }
    }

    demote = (userID) => {
        if (this.props.users[userID].role === 'admin') {
            this.props.demoteUser(userID, 'supervisor');
            this.setState({adminSearchID: ''});
        } else if (this.props.users[userID].role === 'supervisor') {
            this.props.demoteUser(userID, 'lead');
            this.setState({supervisorSearchID: ''});
        } else if (this.props.users[userID].role === 'lead') {
            this.props.demoteUser(userID, 'tech');
            this.setState({leadSearchID: ''});
        }
    }

    deactivate = (userID, role) => {
        this.props.deactivateUser(userID);
        if (role === "tech") {
            this.setState({techSearchID: ''});
        } else if (role === "lead") {
            this.setState({leadSearchID: ''});
        } else if (role === "supervisor") {
            this.setState({supervisorSearchID: ''});
        } else if (role === "admin") {
            this.setState({adminSearchID: ''});
        } else if (role === "client") {
            this.setState({clientSearchID: ''});
        }
    }
    
    firestoreTimestampToMoment = (timestamp) => {
        if (!timestamp) {
            return null;
        }

        const jsDate = timestamp.toDate();
        return moment(jsDate).format('MMMM Do YYYY, h:mm:ss a');
    };

    findLatestCheckIn = (user) => {
        if (user.checkins === undefined) {
            return "No Check-In history"
        } else {
            const checkIns = Object.keys(user.checkins);

            let maxDate = checkIns[0];
            for (let date of checkIns) {
                if (moment(date).isAfter(maxDate)) {
                    maxDate = date;
                }
            }
            
            return moment(maxDate).format('MMMM Do YYYY');
        }
    }

    allTechDataToCSV = () => {
        const projects = Object.values(this.props.projects);

        var techsThatConfirmedInFinishedProjects = [];
        var techsInConfirmedListsWithExceptions = [];

        projects.forEach(project => {
            if (project.phase === "finished") {
                Object.entries(project.confirmedTechList).forEach(entry => {
                    const id = entry[0];
                    const status = entry[1];
    
                    if (status === "confirmed") {
                        techsThatConfirmedInFinishedProjects.push([id, project.startTime])
                    } else if (status === "removed" || status === "denied" || status === "dropped off") {
                        techsInConfirmedListsWithExceptions.push([id, project.startTime])
                    }
                })
            }

        })
        console.log(techsThatConfirmedInFinishedProjects)

        const excludedIDs = ["L4pFWx2luzL3cOzpTopZASAtxss1", "WiTRpBl1tqdpreN6BcTJAS7n1vp1",
        "y9BH67XAMWXBsSOtzNBhkXrcla12", "koyylz6epbNpz7tCltFvjXR4Cfy1",
        "teu3pHC3K6d7pNf8EHtGHPRquvJ2", "iukz9dBFaiPDMwbWvR3EIjLMIlt1", "epBXxyy1EZVLYNqQwwzxWMYWNM53"];

        var techDataCSV = "";

        Object.entries(this.props.users).filter(Boolean).forEach(entry => {
            const id = entry[0];
            const user = entry[1];
            if (!excludedIDs.includes(id)) {
                if (user.role === "tech" || user.role === "lead" || user.role === "supervisor" || user.role === "admin") {
                    var phone = user.phone.replace(/-/g, "").replace("(", "").replace(")", "").replace(" ", "");
                    phone = phone.replace(/(\d{3})(\d{3})(\d+)/, '$1-$2-$3');

                    var foundTech = false;
                    var mostRecentDate;
                    techsThatConfirmedInFinishedProjects.forEach(array => {
                        const techID = array[0];
                        const date = array[1];
                        if (techID === id) {
                            foundTech = true;
                            if (mostRecentDate) {
                                mostRecentDate = moment(date) > moment(mostRecentDate) ? date : mostRecentDate;
                            } else {
                                mostRecentDate = date
                            }
                        }
                    })
                    if (foundTech) {
                        const name = `${user.firstName} ${user.lastName} - ${mostRecentDate}`;
                        console.log(name)
                    } else {
                        const name = `${user.firstName} ${user.lastName} - No projects in last 2+ months`;
                        console.log(name)
                    }

                    const row = `${user.firstName}, ${user.lastName}, ${user.email}, ${phone}, ${user.role}, ${mostRecentDate}, ${id}\n`
                    techDataCSV += row
                }
            }
        });

        navigator.clipboard.writeText(techDataCSV)
    }

    render() {
        const { users } = this.props;

        // Convert the "users" object into the "options" array so that it can be read by "Select Search" component
        var techOptions = [];
        var leadOptions = [];
        var supervisorOptions = [];
        var adminOptions = [];
        var clientOptions = [];
        var deactivatedOptions = [];
        Object.entries(users).forEach(entry => {
            const id = entry[0]
            const user = entry[1]
            
            if (user.role === 'tech' || user.role === 'test') {
                techOptions.push({name: user.firstName + ' ' + user.lastName, value: id})
            } else if (user.role === 'supervisor') {
                supervisorOptions.push({name: user.firstName + ' ' + user.lastName, value: id})
            } else if (user.role === 'lead') {
                leadOptions.push({name: user.firstName + ' ' + user.lastName, value: id})
            } else if (user.role === 'admin') {
                adminOptions.push({name: user.firstName + ' ' + user.lastName, value: id})
            } else if (user.role === 'client') {
                clientOptions.push({name: user.firstName + ' ' + user.lastName, value: id})
            } else if (user.role === 'deactivated') {
                deactivatedOptions.push({name: user.firstName + ' ' + user.lastName, value: id})
            }
            
        })

        // Sort the options list alphabetically
        techOptions.sort(function (a,b) {
            if (a.name > b.name) return 1
            if (a.name < b.name) return -1
            return 0;
        })

        leadOptions.sort(function (a,b) {
            if (a.name > b.name) return 1
            if (a.name < b.name) return -1
            return 0;
        })

        supervisorOptions.sort(function (a,b) {
            if (a.name > b.name) return 1
            if (a.name < b.name) return -1
            return 0;
        })

        adminOptions.sort(function (a,b) {
            if (a.name > b.name) return 1
            if (a.name < b.name) return -1
            return 0;
        })

        clientOptions.sort(function (a,b) {
            if (a.name > b.name) return 1
            if (a.name < b.name) return -1
            return 0;
        })

        deactivatedOptions.sort(function (a,b) {
            if (a.name > b.name) return 1
            if (a.name < b.name) return -1
            return 0;
        })

        return(
            <Card className="ManageCard">
                <Card.Header as="h5"> Search a User </Card.Header>
                <Card.Body>
                    <Row>
                        <Col md={12} lg={4}>
                            <div className="card-content grey-text text-darken-3">
                                <Row>
                                    <Card.Title style={{marginRight: 15}}> Techs </Card.Title>
                                    <SelectSearch options={techOptions} search={true} onChange={this.handleTechChange} placeholder="Choose a user..." emptyMessage="No users loaded..." />
                                </Row>

                                <Row>
                                    {users[this.state.techSearchID] && (
                                        <div className="card-content grey-text text-darken-3">
                                            <Row>
                                                <p>{"Phone: "} <b>{this.formatPhoneNumber(users[this.state.techSearchID].phone)}</b></p>
                                            </Row>

                                            <Row>
                                                <p>{"Email: "} <b>{users[this.state.techSearchID].email}</b></p>
                                            </Row>

                                            <Row>
                                                <p>{"Vaccinated:"}</p>
                                                {users[this.state.techSearchID].vaccineStatus && (
                                                    <>
                                                        <p style={{marginLeft: 3}}> <b>Yes</b> </p>
                                                        <img style={{marginLeft: 3}} src={vaccineIcon} height="20px" width="20px" alt="vaccine syringe icon"/>
                                                    </>
                                                )}
                                                {!users[this.state.techSearchID].vaccineStatus && (
                                                    <p style={{marginLeft: 3}}> <b>No</b> </p>
                                                )}
                                            </Row>

                                            <Row>
                                                <p>{"Last Sign-In: "} <b>{this.firestoreTimestampToMoment(users[this.state.techSearchID].lastSignIn)}</b></p>
                                            </Row>

                                            <Row>
                                                <p>{"Last Check-In: "} <b>{this.findLatestCheckIn(users[this.state.techSearchID])}</b></p>
                                            </Row>

                                            <Row>
                                                <p>{"ID: "} <b>{this.state.techSearchID}</b></p>
                                            </Row>

                                            <Row>
                                                <p> Certifications: </p>
                                            </Row>

                                            <Row style={{marginBottom: 0}}>
                                                {users[this.state.techSearchID].certifications.map(cert => {
                                                    return(<ol> {cert} </ol>)
                                                })}
                                            </Row>

                                            <Row>
                                                <p> Experience: </p>
                                            </Row>

                                            <Row style={{marginBottom: 0}}>
                                                {users[this.state.techSearchID].experience.map(exp => {
                                                    return (<ol> {exp} </ol>)
                                                })}
                                            </Row>

                                            <Row>
                                                <Button disabled variant="warning">
                                                    Demote
                                                </Button>
                                                
                                                <Button 
                                                    style={{marginLeft: 5}}
                                                    variant="success"
                                                    onClick={() => this.promote(this.state.techSearchID)}
                                                >
                                                    Promote
                                                </Button>

                                                <Button 
                                                    style={{marginLeft: 5}}
                                                    variant="danger"
                                                    onClick={() => this.deactivate(this.state.techSearchID, "tech")}
                                                >
                                                    Deactivate
                                                </Button>
                                            </Row>
                                            
                                            <Row>
                                                <Button
                                                    style={{marginLeft: 0}}
                                                    href={`/manage/history/${this.state.techSearchID}`}
                                                >
                                                    History
                                                </Button>

                                                <Button
                                                    style={{marginLeft: 5}}
                                                    variant="danger"
                                                    href={`/manage/writeups/${this.state.techSearchID}`}
                                                >
                                                    Writeups
                                                </Button>
                                            </Row>
                                        </div>
                                    )}
                                </Row>
                            </div>
                        </Col>

                        <Col xs={12} lg={4}>
                            <div className="card-content grey-text text-darken-3">
                                <Row>
                                    <Card.Title style={{marginRight: 15}}> Leads </Card.Title>
                                    <SelectSearch options={leadOptions} search={true} onChange={this.handleLeadChange} placeholder="Choose a user..." emptyMessage="No users loaded..." />
                                </Row>

                                <Row>
                                    {users[this.state.leadSearchID] && (
                                        <div className="card-content grey-text text-darken-3">
                                            <Row>
                                                <p>{"Phone: "} <b>{this.formatPhoneNumber(users[this.state.leadSearchID].phone)}</b></p>
                                            </Row>

                                            <Row>
                                                <p>{"Email: "} <b>{users[this.state.leadSearchID].email}</b></p>
                                            </Row>

                                            <Row>
                                                <p>{"Vaccinated:"}</p>
                                                {users[this.state.leadSearchID].vaccineStatus && (
                                                    <>
                                                        <p style={{marginLeft: 3}}> <b>Yes</b> </p>
                                                        <img style={{marginLeft: 3}} src={vaccineIcon} height="20px" width="20px" alt="vaccine syringe icon"/>
                                                    </>
                                                )}
                                                {!users[this.state.leadSearchID].vaccineStatus && (
                                                    <p style={{marginLeft: 3}}> <b>No</b></p>
                                                )}
                                            </Row>

                                            <Row>
                                                <p>{"Last Sign-In: "} <b>{this.firestoreTimestampToMoment(users[this.state.leadSearchID].lastSignIn)}</b></p>
                                            </Row>

                                            <Row>
                                                <p>{"ID: "} <b>{this.state.leadSearchID}</b></p>
                                            </Row>

                                            <Row>
                                                <Button onClick={() => this.demote(this.state.leadSearchID)} variant="warning">
                                                    Demote
                                                </Button>
                                                
                                                <Button onClick={() => this.promote(this.state.leadSearchID)} style={{marginLeft: 5}} variant="success">
                                                    Promote
                                                </Button>

                                                <Button onClick={() => this.deactivate(this.state.leadSearchID, "lead")} style={{marginLeft: 5}} variant="danger">
                                                    Deactivate
                                                </Button>
                                            </Row>
                                        </div>
                                    )}
                                </Row>
                            </div>
                        </Col>

                        <Col xs={12} lg={4}>
                            <div className="card-content grey-text text-darken-3">
                                <Row>
                                    <Card.Title style={{marginRight: 15}}> Supervisors </Card.Title>
                                    <SelectSearch options={supervisorOptions} search={true} onChange={this.handleSupervisorChange} placeholder="Choose a user..." emptyMessage="No users loaded..." />
                                </Row>

                                <Row>
                                    {users[this.state.supervisorSearchID] && (
                                        <div className="card-content grey-text text-darken-3">
                                            <Row>
                                                <p>{"Phone: "} <b>{this.formatPhoneNumber(users[this.state.supervisorSearchID].phone)}</b></p>
                                            </Row>

                                            <Row>
                                                <p>{"Email: "} <b>{users[this.state.supervisorSearchID].email}</b></p>
                                            </Row>

                                            <Row>
                                                <p>{"Vaccinated:"}</p>
                                                {users[this.state.supervisorSearchID].vaccineStatus && (
                                                    <>
                                                        <p style={{marginLeft: 3}}> <b>Yes</b> </p>
                                                        <img style={{marginLeft: 3}} src={vaccineIcon} height="20px" width="20px" alt="vaccine syringe icon"/>
                                                    </>
                                                )}
                                                {!users[this.state.supervisorSearchID].vaccineStatus && (
                                                    <p style={{marginLeft: 3}}> <b>No</b></p>
                                                )}
                                            </Row>

                                            <Row>
                                                <p>{"Last Sign-In: "} <b>{this.firestoreTimestampToMoment(users[this.state.supervisorSearchID].lastSignIn)}</b></p>
                                            </Row>

                                            <Row>
                                                <p>{"ID: "} <b>{this.state.supervisorSearchID}</b></p>
                                            </Row>

                                            <Row>
                                                <Button onClick={() => this.demote(this.state.supervisorSearchID)} variant="warning">
                                                    Demote
                                                </Button>
                                                
                                                <Button onClick={() => this.promote(this.state.supervisorSearchID)} style={{marginLeft: 5}} variant="success">
                                                    Promote
                                                </Button>

                                                <Button onClick={() => this.deactivate(this.state.supervisorSearchID, "supervisor")} style={{marginLeft: 5}} variant="danger">
                                                    Deactivate
                                                </Button>
                                            </Row>
                                        </div>
                                    )}
                                </Row>
                            </div>
                        </Col>

                        <Col xs={12} lg={4}>
                            <div className="card-content grey-text text-darken-3">
                                <Row>
                                    <Card.Title style={{marginRight: 15}}> Admins </Card.Title>
                                    <SelectSearch options={adminOptions} search={true} onChange={this.handleAdminChange} placeholder="Choose a user..." emptyMessage="No users loaded..." />
                                </Row>

                                <Row>
                                    {users[this.state.adminSearchID] && (
                                        <div className="card-content grey-text text-darken-3">
                                            <Row>
                                                <p>{"Phone: "} <b>{this.formatPhoneNumber(users[this.state.adminSearchID].phone)}</b></p>
                                            </Row>

                                            <Row>
                                                <p>{"Email: "} <b>{users[this.state.adminSearchID].email}</b></p>
                                            </Row>

                                            <Row>
                                                <p>{"ID: " } <b>{this.state.adminSearchID}</b></p>
                                            </Row>

                                            <Row>
                                                <Button onClick={() => this.demote(this.state.adminSearchID)} variant="warning">
                                                    Demote
                                                </Button>
                                                
                                                <Button disabled style={{marginLeft: 5}} variant="success">
                                                    Promote
                                                </Button>

                                                <Button onClick={() => this.deactivate(this.state.adminSearchID, "admin")} style={{marginLeft: 5}} variant="danger">
                                                    Deactivate
                                                </Button>
                                            </Row>
                                        </div>
                                    )}
                                </Row>
                            </div>
                        </Col>

                        <Col xs={12} lg={4}>
                            <div className="card-content grey-text text-darken-3">
                                <Row>
                                    <Card.Title style={{marginRight: 15}}> Clients </Card.Title>
                                    <SelectSearch options={clientOptions} search={true} onChange={this.handleClientChange} placeholder="Choose a user..." emptyMessage="No users loaded..." />
                                </Row>

                                <Row>
                                    {users[this.state.clientSearchID] && (
                                        <div className="card-content grey-text text-darken-3">
                                            <Row>
                                                <p>{"Phone: "} <b>{this.formatPhoneNumber(users[this.state.clientSearchID].phone)}</b></p>
                                            </Row>

                                            <Row>
                                                <p>{"Email: "} <b>{users[this.state.clientSearchID].email}</b></p>
                                            </Row>

                                            <Row>
                                                <p>{"ID: " } <b>{this.state.clientSearchID}</b></p>
                                            </Row>

                                            <Row>
                                                <ModalProjectScope
                                                    projectScope={this.props.users[this.state.clientSearchID].projectScope}
                                                    userID={this.state.clientSearchID}
                                                />

                                                <ModalClientScope
                                                    clientScope={this.props.users[this.state.clientSearchID].clientScope}
                                                    userID={this.state.clientSearchID}
                                                />
                                            </Row>
                                            <Row>
                                                <Button onClick={() => this.deactivate(this.state.clientSearchID, "client")} style={{marginLeft: 0}} variant="danger">
                                                    Deactivate
                                                </Button>
                                            </Row>
                                        </div>
                                    )}
                                </Row>
                            </div>
                        </Col>

                        <Col md={12} lg={4}>
                            <div className="card-content grey-text text-darken-3">
                                <Row>
                                    <Card.Title style={{marginRight: 15}}> Deactivated </Card.Title>
                                    <SelectSearch options={deactivatedOptions} search={true} onChange={this.handledeactivatedChange} placeholder="Choose a user..." emptyMessage="No users loaded..." />
                                </Row>

                                <Row>
                                    {users[this.state.deactivatedSearchID] && (
                                        <div className="card-content grey-text text-darken-3">
                                            <Row>
                                                <p>{"Phone: " + this.formatPhoneNumber(users[this.state.deactivatedSearchID].phone)}</p>
                                            </Row>

                                            <Row>
                                                <p>{"Email: " + users[this.state.deactivatedSearchID].email}</p>
                                            </Row>

                                            <Row>
                                                <p>{"Vaccinated:"}</p>
                                                {users[this.state.deactivatedSearchID].vaccineStatus && (
                                                    <>
                                                        <p style={{marginLeft: 3}}> Yes </p>
                                                        <img style={{marginLeft: 3}} src={vaccineIcon} height="20px" width="20px" alt="vaccine syringe icon"/>
                                                    </>
                                                )}
                                                {!users[this.state.deactivatedSearchID].vaccineStatus && (
                                                    <p style={{marginLeft: 3}}> No </p>
                                                )}
                                            </Row>

                                            <Row>
                                                <p>{"ID: " + this.state.deactivatedSearchID}</p>
                                            </Row>

                                            <Row>
                                                <Button onClick={() => this.promote(this.state.deactivatedSearchID)} style={{marginLeft: 5}} variant="success">
                                                    Reactivate
                                                </Button>
                                            </Row>
                                        </div>
                                    )}
                                </Row>
                            </div>
                        </Col>
                    </Row>
                    <Button onClick={() => this.allTechDataToCSV()}>Copy All User Info To ClipBoard (.csv)</Button>
                </Card.Body>
            </Card> 
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        promoteUser: (userID, role) => dispatch(promoteUser(userID, role)),
        demoteUser: (userID, role) => dispatch(demoteUser(userID, role)),
        deactivateUser: (userID) => dispatch(deactivateUser(userID)),
    }
}

export default connect(null, mapDispatchToProps)(SearchUser);