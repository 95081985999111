import React, { Component } from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';

class TimePeriod extends Component {
    state = {
        expandList: false,
        sortByFirstName: true,
    }

    handleExpand = () => {
        this.setState({
            expandList: !this.state.expandList
        })
    }

    handleSort = (sortBy) => {
        this.setState({
            sortByFirstName: sortBy
        });
    }

    render() {
        const { title, date, userList, users } = this.props;

        if (userList) {
            // Sort the array alphabetically
            function lastNameSort(a, b) {
                var lastA = users[a].lastName;
                var lastB = users[b].lastName;
            
                if (lastA < lastB) return -1;
                if (lastA > lastB) return 1;
                return 0;
            }

            function firstNameSort(a, b) {
                var firstA = users[a].firstName;
                var firstB = users[b].firstName;

                if (firstA < firstB) return -1;
                if (firstA > firstB) return 1;
                return 0;
            }

            var sorted;
            if (this.state.sortByFirstName) {
                sorted = userList.sort(firstNameSort)
            } else {
                sorted = userList.sort(lastNameSort);
            }

            return(
                <Container>
                    <div className="card z-depth-2 project-summary">
                        <div className="card-content grey-text text-darken-3">
                            <span className="card-title">
                                {title ? title :
                                    (date ? date.format('M-D-YY') + ": " : "Users With No Checkins: ")
                                }
                                <b>{sorted.length}</b>
                            {" techs"}
                            </span>
    
                            <Row>
                                <Col>
                                    <Button style={{marginRight: 10}} onClick={() => this.handleExpand()}>
                                        Expand
                                    </Button>
                                </Col>
                                {this.state.expandList && (
                                    <Col>
                                        <h5>
                                            Sort By: 
                                        </h5>
                                        <ButtonGroup aria-label="Sort By">
                                            <Button onClick={() => this.handleSort(false)} variant="secondary">Last</Button>
                                            <Button onClick={() => this.handleSort(true)} variant="secondary">First</Button>
                                        </ButtonGroup>
                                    </Col>
                                )}
                            </Row>

                            {this.state.expandList && (
                                <div>
                                    <Row>
                                        <Col xs={3}>
                                            {sorted.slice(0, sorted.length / 4).map(id => {
                                                return <p>{`${users[id].firstName} ${users[id].lastName} (${users[id].sixMonthProjectCount})`}</p>
                                            })}
                                        </Col>

                                        <Col xs={3}>
                                            {sorted.slice(sorted.length / 4, (sorted.length / 4) * 2).map(id => {
                                                return <p>{`${users[id].firstName} ${users[id].lastName} (${users[id].sixMonthProjectCount})`}</p>
                                            })}
                                        </Col>

                                        <Col xs={3}>
                                            {sorted.slice((sorted.length / 4) * 2, (sorted.length / 4) * 3).map(id => {
                                                return <p>{`${users[id].firstName} ${users[id].lastName} (${users[id].sixMonthProjectCount})`}</p>
                                            })}
                                        </Col>

                                        <Col xs={3}>
                                            {sorted.slice((sorted.length / 4) * 3).map(id => {
                                                return <p>{`${users[id].firstName} ${users[id].lastName} (${users[id].sixMonthProjectCount})`}</p>
                                            })}
                                        </Col>
                                    </Row>
                                </div>
                            )}
                        </div>
                    </div>
                </Container>
            )
        } else {
            return(
                <div>
                    Loading users...
                </div>
            )
        }
    }
}

export default TimePeriod;