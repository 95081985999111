import React, { Component } from 'react';
import moment from 'moment';

class BasicInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { project, subtitle, phase } = this.props;

        return(
            <div style={{marginBottom: "2rem"}}>
                <h2>{project.client}</h2>
                <p>{project.city}</p>
                <p>{project.address}</p>
                <p>
                    {moment(project.startTime, "YYYY-MM-DD hh:mm").format('dddd MM/DD')}<br/>
                    {moment(project.startTime, "YYYY-MM-DD hh:mm").format('h:mm a')}
                    {' to ' + moment(project.endTime, "YYYY-MM-DD hh:mm").format('h:mm a')}
                </p>
                {project.type !== "Server" ? (
                    <p>{project.type + " - " + project.workstations + " Workstations"}</p>
                ) : (
                    <p>{project.type + " - " + project.workstations + " Servers"}</p>
                )}
                {phase && (
                    <p>{"Phase: " + phase}</p>
                )}
                {subtitle && (
                    <h5><i>{subtitle}</i></h5>
                )}
                {/* <p>Vaccines Required: Yes</p> */}
            </div>
        );
    }
}

export default BasicInfo;
