import React, { Component } from 'react';
import { connect } from 'react-redux';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import emailjs from 'emailjs-com';

import { confirmUserWithNoResponse, addToActivityLog, removeProposedUser } from '../../../../../store/actions/projectActions';
import './ProposedUserList.css';

class ProposedUserList extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  addUserToConfirmedList = (refLocation, userToAdd, proposedUserList, userRole) => {
    // Add the user to the confirmed list
    this.props.confirmUserWithNoResponse(refLocation, userToAdd, userRole);
    this.props.addToActivityLog(refLocation, "admin move from proposed to confirmed", this.props.auth.uid, userToAdd, userRole);

    // Remove the user from the proposed list
    console.log("Proposed List before:", proposedUserList);
    var removeList = { ...proposedUserList };
    delete removeList[userToAdd];
    this.props.removeProposedUser(refLocation, removeList, userRole);
    console.log("Proposed List after:", removeList);

    if (this.props.users[userToAdd].notifications.email.confirmedForProject) {
      var templateParams = { Bcc: this.props.users[userToAdd].email };
      this.sendConfirmEmail(templateParams);
    }
  };

  sendConfirmEmail = (templateParams) => {
    emailjs.send('scheduler@murphymckay', 'template_ked4438', templateParams, "user_UgmdOeeH6OCjwrAqPoGpi")
      .then((result) => {
        console.log(result.text);
      }, (error) => {
        console.log(error.text);
      });
  };

  UIDtoName = (userID) => {
    if (this.props.users[userID]) {
      return this.props.users[userID].firstName + ' ' + this.props.users[userID].lastName;
    } else {
      return "Loading user...";
    }
  };


  render() {
    const { project, proposedUserList, proposedUserListSorted, userRole } = this.props;

    return (
      <>
        <h4>Available</h4>

        {Object.keys(proposedUserList).length === 0 && (
          <p><i>There are no available {userRole.lower}s on standby...</i></p>
        )}

        {Object.keys(proposedUserList).length > 0 && (
          <>
            <p><i>(Top of the list signed up first, bottom is last)</i></p>

            <ol style={{ paddingInlineStart: "2.0rem" }}>
              {proposedUserListSorted.map(entry => {
                const id = entry[0]
                return(
                <Row key={id} style={{ marginBottom: "0px" }}>
                  <Col xs={6}>
                    <Row>
                      <li>{this.UIDtoName(id)}</li>
                    </Row>
                  </Col>

                  {project.phase !== "finished" && (
                    <Col xs={6}>
                      <Button
                        className="buttonAlign"
                        variant="success"
                        onClick={() => this.addUserToConfirmedList(project.refLocation, id, proposedUserList, userRole)}
                      >
                        Add
                      </Button>
                    </Col>
                  )}
                </Row>
                )
                })}
            </ol>
          </>
        )}
      </>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    confirmUserWithNoResponse: (refLocation, user, userRole) => dispatch(confirmUserWithNoResponse(refLocation, user, userRole)),
    removeProposedUser: (refLocation, proposedUserList, userRole) => dispatch(removeProposedUser(refLocation, proposedUserList, userRole)),
    addToActivityLog: (refLocation, action, authorID, subjectID, userRole) => dispatch(addToActivityLog(refLocation, action, authorID, subjectID, userRole)),
  };
};

export default connect(null, mapDispatchToProps)(ProposedUserList);
