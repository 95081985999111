import React, { Component } from 'react';
import { connect } from 'react-redux'

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Dropdown from 'react-bootstrap/Dropdown';
import Button from 'react-bootstrap/Button';
import Nav from 'react-bootstrap/Nav';

import moment from 'moment';

import ArchiveProjectList from './ArchiveProjectList';

class Archive extends Component {
  state = {
    month: "January",
    year: moment().format('YYYY'),
    hideDeletedProjects: true,
  };

  handleButton = () => {
    this.setState({hideDeletedProjects: !this.state.hideDeletedProjects});
  }

  handleSetMonth = (userSelectedMonth) => {
    this.setState({month: userSelectedMonth});
  }

  render() {
    var years = [];
    const currentYear = parseInt(moment().format('YYYY'));

    var year = 2020;
    while (year <= currentYear) {
      years.push(year.toString())
      year++;
    }

    return(
      <Container style={{maxWidth: 1160}}>
        <a href="/" className="previous" style={{marginTop: 10}}> &laquo; Return </a>

        <Card>
          <Card.Header>
            <Row style={{marginBottom: 0}}>
              <Col>
                <Row style={{marginBottom: 0}}>
                  <Col md="auto">
                    <h2>{`Projects from: `}</h2>
                  </Col>

                  <Dropdown>
                    <Dropdown.Toggle variant="outline-primary" id="dropdown-basic">
                      {this.state.year}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {years.map(year => {
                        return (<Dropdown.Item onClick={() => this.setState(() => ({year: year}))}> {year} </Dropdown.Item>)
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                </Row>
              </Col>

              <Col>
                  <div style={{marginLeft: "auto", marginRight: 0}}>
                    
                    <Row style={{marginBottom: 0}}>
                      <p style={{marginTop: 5}}> Show Deleted Projects: </p>
                      {this.state.hideDeletedProjects && (
                        <Button style={{marginLeft: 10}} onClick={this.handleButton}> On </Button>
                      )}

                      {!this.state.hideDeletedProjects && (
                        <Button style={{marginLeft: 10}} onClick={this.handleButton}> Off </Button>
                      )}
                    </Row>

                  </div>
              </Col>
            </Row>

            <Row style={{marginBottom: 0}}>
              <Nav>
                <Nav.Item>
                  <Nav.Link onClick={() => this.handleSetMonth("January")}> January </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link onClick={() => this.handleSetMonth("February")}> February </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link onClick={() => this.handleSetMonth("March")}> March </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link onClick={() => this.handleSetMonth("April")}> April </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link onClick={() => this.handleSetMonth("May")}> May </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link onClick={() => this.handleSetMonth("June")}> June </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link onClick={() => this.handleSetMonth("July")}> July </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link onClick={() => this.handleSetMonth("August")}> August </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link onClick={() => this.handleSetMonth("September")}> September </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link onClick={() => this.handleSetMonth("October")}> October </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link onClick={() => this.handleSetMonth("November")}> November </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link onClick={() => this.handleSetMonth("December")}> December </Nav.Link>
                </Nav.Item>
              </Nav>
            </Row>
          </Card.Header>

          <Card.Body>
            <ArchiveProjectList
              year={this.state.year}
              month={this.state.month}
              hideDeletedProjects={this.state.hideDeletedProjects}
            />
          </Card.Body>
        </Card>
      </Container>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
  }
}

export default connect(mapStateToProps, null)(Archive);