const initState = {
    authError: null
}

const authReducer = (state = initState, action) => {
    switch(action.type){
        case 'LOGIN_ERROR':
            console.log('login failed');
            return {
                ...state,
                authError: 'Login failed'
            }
        case 'LOGIN_SUCCESS':
            console.log('login success');
            return {
                ...state,
                authError: null
            }

        case 'SIGNOUT_SUCCESS':
            console.log('signout success');
            return state;

        case 'SIGNUP_SUCCESS':
            console.log('signup success');
            return {
                ...state,
                authError: null
            }
        case 'SIGNUP_ERROR':
            console.log('signup error')
            return {
                ...state,
                authError: action.err.message
            }

        case 'PASSWORD_ERROR':
            console.log('password error')
            return {
                ...state,
                authError: action.err.message
            }

        case 'EMAIL_SUCCESS':
            return {
                ...state,
                passResetSuccess: action.message
            }
        case 'EMAIL_ERROR':
            if (action.err.code === "auth/user-not-found")
                return {
                    ...state,
                    passResetError: "Email address not associated with any users"
                }
            else
                return {
                    ...state,
                    passResetError: action.err.message
                }

        default:
            return state;
    }
}

export default authReducer;