import React from 'react';
import { NavLink } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import CardDeck from 'react-bootstrap/CardDeck';

import NotificationCenter from './NotificationCenter';
import '../../Dashboard.css';

const AdminHomeMobile = () => {
  return (
    <Container>
      <NotificationCenter />
      
      <CardDeck>
        <Card>
          <Card.Body>
            <Card.Title>Project Calendar</Card.Title>

            <Card.Text>
              View all current projects
            </Card.Text>
            
            <NavLink to='/calendaradmin' className="LinkButtons btn pink lighten-1 white-text">
              GO
            </NavLink>
          </Card.Body>
        </Card>

        <Card>
          <Card.Body>
            <Card.Title>Create a Project</Card.Title>

            <Card.Text>
              Create and approve new projects
            </Card.Text>
            
            <NavLink to='/createprojectm' className="LinkButtons btn pink lighten-1 white-text">
              GO
            </NavLink>
          </Card.Body>
        </Card>

        {/* <Card>
          <Card.Body>
            <Card.Title>Posted Projects</Card.Title>

            <Card.Text>
              See the currently posted projects available for signup
            </Card.Text>
            
            <NavLink to='/projectsignup' className="LinkButtons btn pink lighten-1 white-text">
              GO
            </NavLink>
          </Card.Body>
        </Card> */}

          <Card>
            <Card.Body>
              <Card.Title>Active Projects</Card.Title>

              <Card.Text>
                View all ongoing projects
              </Card.Text>

              <NavLink to='/select' className="LinkButtons btn pink lighten-1 white-text">
                GO
              </NavLink>
            </Card.Body>
          </Card>
          
          <Card>
            <Card.Body>
              <Card.Title>Archived Projects</Card.Title>

              <Card.Text>
                View all previous projects
              </Card.Text>

              <NavLink to='/archive' className="LinkButtons btn pink lighten-1 white-text">
                GO
              </NavLink>
            </Card.Body>
          </Card>

        <Card>
          <Card.Body>
            <Card.Title>Management Tools</Card.Title>

            <Card.Text>
              Access all admin tools including expense report approval, tech profile viewing, and check-in statistics
            </Card.Text>

            <NavLink to='/manage' className="LinkButtons btn pink lighten-1 white-text">
              GO
            </NavLink>
          </Card.Body>
        </Card>
      </CardDeck>
    </Container>
  )
}

export default AdminHomeMobile;