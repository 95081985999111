import React, { Component } from 'react';
import { connect } from 'react-redux';

import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import emailjs from 'emailjs-com';

import { approveReport, denyReport, deleteReport } from '../../../../store/actions/userActions';

class BartReport extends Component {
    constructor(props) {
        super(props);

        this.state = {
            approved: false,
            denied: false,
            denyActive: false,
            denyReason: '',
            showDenyError: false,
            approveEmailSent: false,
            approveEmailFailed: false,
            approveEmailError: "",
            denyEmailSent: false,
            denyEmailFailed: false,
            denyEmailError: "",
        }
    }

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    UIDtoName = (userID) => {
        if (this.props.users[userID]) {
            return this.props.users[userID].firstName + ' ' + this.props.users[userID].lastName
        } else {
            return "Loading user..."
        }
    }

    approveReport = (report) => {
        this.props.approveReport(report.refLocation);
        this.setState({approved: true});

        const templateParams = {
            type: "BART",
            client: report.client,
            date: report.projectDate,
            to_email: this.props.users[report.userID].email
        };
      
        emailjs.send('scheduler@murphymckay', 'template_ny8zt9u', templateParams, "user_UgmdOeeH6OCjwrAqPoGpi")
            .then((result) => {
                this.setState({approveEmailSent: true})
                this.setState({approveEmailFailed: false})
            }, (error) => {
            this.setState({approveEmailError: error.text})
            this.setState({approveEmailSent: false})
            this.setState({approveEmailFailed: true})
        });
        console.log("Sent to", templateParams.to_email)
    }

    denyReport = (report) => {
        if (this.state.denyReason) {
            this.props.denyReport(report.refLocation, this.state.denyReason);

            const templateParams = {
                type: "BART",
                client: report.client,
                date: report.projectDate,
                reason: this.state.denyReason,
                to_email: this.props.users[report.userID].email
            };
          
            emailjs.send('scheduler@murphymckay', 'template_bnh0fjk', templateParams, "user_UgmdOeeH6OCjwrAqPoGpi")
                .then((result) => {
                    this.setState({approveEmailSent: true})
                    this.setState({approveEmailFailed: false})
                }, (error) => {
                this.setState({approveEmailError: error.text})
                this.setState({approveEmailSent: false})
                this.setState({approveEmailFailed: true})
            });
            console.log("Sent to", templateParams.to_email)



            this.setState({denyReason: ''});
            this.setState({denied: true});
        } else {
            this.setState({showDenyError: true});
        }
    }

    deleteReport = (refLocation) => {
        this.props.deleteReport(refLocation);
        this.setState({denied: true});
    }

    showDenyForm = () => {
        this.setState({denyActive: true});
    }

    render() {
        const { report } = this.props;

        console.log("report: ", report)
        console.log("props", this.props)

        if (!(this.state.approved || this.state.denied)) {
            return (
                <Card style={{marginBottom: 20}}>
                    <Card.Header as="h5">
                        {"Report for: " + this.UIDtoName(report.userID)}
                        <span style={{fontSize: 8}}> {report.key} </span>
                    </Card.Header>

                    <Card.Body>
                        <Row style={{marginBottom: 0}}>
                            <Col xs={12} md={6}>
                                <div className="card z-depth-0 project-summary">
                                    <div className="card-content grey-text text-darken-3">
                                        <span className="card-title">{"Client: " + report.client}</span>
                                        <span className="card-title" style={{marginBottom: 20}}>{"Date: " + report.projectDate}</span>

                                        <span className="card-title">{"Type: " + report.type}</span>
                                        <span className="card-title">{"To Origin: " + report.toOrigin}</span>
                                        <span className="card-title">{"To Destination: " + report.toDestination}</span>
                                        <span className="card-title">{"To Subtotal: $" + report.toCost}</span>
                                        <span className="card-title">{"Leave Origin: " + report.toOrigin}</span>
                                        <span className="card-title">{"Leave Destination: " + report.toDestination}</span>
                                        <span className="card-title">{"Leave Subtotal: $" + report.leaveCost}</span>
                                        <span className="card-title">{"Total Cost: $" + report.totalCost}</span>   
                                    </div>
                                </div>

                            </Col>

                            <Col style={{textAlign: 'center'}} xs={12} md={6}>
                                <img style={{width: '100%'}} src={report.pictureURL} alt="proof of expense"></img>
                                <a style={{fontSize: 10, margin: 0, marginBottom: 10}} href={report.pictureURL} className="previous">Link to image</a>
                            </Col>
                        </Row>

                        <Row style={{marginLeft: 0, marginBottom: 0}}>
                            <Button onClick={() => this.approveReport(report)} variant="success"> Approve </Button>
                            {!this.state.denyActive && (
                                <Button style={{marginLeft: 10}} onClick={() => this.showDenyForm()} variant="danger"> Deny </Button>
                            )}

                            <Button style={{marginLeft: "auto", marginRight: 15}}onClick={() => this.deleteReport(report.refLocation)} variant="danger">Delete</Button>
                        </Row>

                        {!!this.state.denyActive && (
                            <div style={{marginTop: 25}}>
                                <Form.Group controlId="denyReason">
                                    <Form.Control
                                        type="text"
                                        placeholder={"Enter a reason..."}
                                        onChange={this.handleChange}
                                        required
                                    />
                                </Form.Group>

                                {!!this.state.showDenyError && (
                                    <p style={{color: 'red'}}>Need a reason to submit!</p>
                                )}

                                <Button style={{marginLeft: 10}} onClick={() => this.denyReport(report)} variant="danger"> Deny </Button>
                            </div>
                        )}
                    </Card.Body>
                </Card>
            )
        } else {
            return null
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        approveReport: (refLocation) => dispatch(approveReport(refLocation)),
        denyReport: (refLocation, reason) => dispatch(denyReport(refLocation, reason)),
        deleteReport: (refLocation) => dispatch(deleteReport(refLocation)),
    }
}

export default connect(null, mapDispatchToProps)(BartReport);