import React, { Component } from 'react'
import { connect } from 'react-redux'

import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'

import { v4 as uuidv4 } from 'uuid';
import emailjs from 'emailjs-com';

import { createComment } from '../../../../store/actions/commentActions'

class UserCommentInput extends Component {
  state = {
    comment: '',
  };

  handleChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const commentID = uuidv4();
    this.props.createComment(commentID, this.props.refLocation, this.props.userID, this.state.comment);
    this.emailCommentToTechs(this.state.comment);
  };

  emailCommentToTechs = (comment) => {
    // var IDsToUpdate = Object.keys(`this.props.project[confirmed${this.props.userRole.capitalize}List]`);
    var IDsToUpdate = Object.keys(this.props.project.confirmedTechList);
    console.log(IDsToUpdate)
    var emailsToUpdate = [];

    IDsToUpdate.forEach(id => {
    if (this.props.users[id].notifications.email.supervisorComment) {
        emailsToUpdate.push(this.props.users[id].email)
      };
    });

    var templateParams = { Bcc: emailsToUpdate, Comment: comment }
    emailjs.send('scheduler@murphymckay', 'template_01ip2ae', templateParams, "user_UgmdOeeH6OCjwrAqPoGpi")
    .then((result) => {
        console.log(result.text);
    }, (error) => {
        console.log(error.text);
    });
  };

  render() {
    return (
      <div className="container">
        <Form onSubmit={this.handleSubmit}>
          <h5> {this.props.userRole.capitalize} Comments </h5>
          <Form.Group controlId="comment">
            <Form.Control as="textarea" rows="4" type="comment" placeholder="Enter a new comment..." onChange={this.handleChange}/>
          </Form.Group>

          <Button variant="primary" type="submit">
            Submit
          </Button>
        </Form>
      </div>
    );
  };
};

const mapStateToProps = (state) => {
  return {
    authError: state.auth.authError,
    auth: state.firebase.auth,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    createComment: (commentID, projectID, userID, comment) => dispatch(createComment(commentID, projectID, userID, comment)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserCommentInput);