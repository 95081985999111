import React, { Component } from 'react';
import { connect } from 'react-redux';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

import { resolveBug } from '../../../store/actions/userActions'

class BugReports extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    UIDtoName = (userID) => {
        if (this.props.users[userID]) {
            return this.props.users[userID].firstName + ' ' + this.props.users[userID].lastName
        } else {
            return "Loading user..."
        }
    }

    handleResolve = (reportID) => {
        this.props.resolveBug(reportID);
    }

    render() {
        const { reports } = this.props;

        // Place all reports into an array for sorting
        var bugReportList = [];
        Object.entries(reports).filter(Boolean).map((report) => {
            return bugReportList.push(report)
        })
  
        // Sort reports by time
        bugReportList.sort(function (a, b) {
            if (a[1].time.seconds > b[1].time.seconds) return 1
            if (a[1].time.seconds < b[1].time.seconds) return -1
            return 0
        })

        var unresolvedReports = [];
        Object.values(bugReportList).filter(Boolean).forEach(report => {
            const reportContent = report[1];

            if (!reportContent.resolved) {
                unresolvedReports.push(report)
            }
        })

        if (unresolvedReports.length) {
            return(
                <Card className="ManageCard">
                    <Card.Header as="h5">Bug Reports</Card.Header>
                    <Card.Body>
                        <Row>
                            {unresolvedReports.map(report => {
                                const reportID = report[0];
                                const reportContent = report[1];
                                return (
                                    <Col xs={12} md={6} lg={4} key={reportID}>
                                        <div className="card z-depth-0 project-summary">
                                            <div className="card-content grey-text text-darken-3">
                                                <span className="card-title"> {reportContent.time.toDate().toDateString() + " " + reportContent.time.toDate().toLocaleTimeString('en-US')} </span>
                                                <span className="card-title"> {this.UIDtoName(reportContent.userID)} </span>
                                                
                                                <Row style={{marginLeft: 0, marginRight: 0, marginBottom: 10}}><span className="card-detail"> {reportContent.report} </span></Row>
                                                <Row style={{marginLeft: 0, marginRight: 0, marginBottom: 0}}><span style={{fontSize: 8}}> {reportID} </span></Row>
                                                
                                            </div>
                                            <div className="ResolveDiv">
                                                <Button className="ResolveButton" variant="success" onClick={() => this.handleResolve(reportID)}> Resolve </Button>
                                            </div>
                                        </div>
                                    </Col>
                                )
                            })}
                        </Row>
                        
                        <Button disabled variant="primary"> View All </Button>
                    </Card.Body>
                </Card>
            )
        } else {
            return(
                <Card className="ManageCard">
                    <Card.Header as="h5"> Bug Reports </Card.Header>
                    <Card.Body>
                        <Card.Title> No unresolved bug reports... </Card.Title>
                        <Button disabled variant="primary"> View All </Button>
                    </Card.Body>
                </Card>
            )
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        resolveBug: (reportID) => dispatch(resolveBug(reportID))
    }
}

export default connect(null, mapDispatchToProps)(BugReports);