import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { Link, Redirect } from 'react-router-dom';

import Container from 'react-bootstrap/Container';

import moment from 'moment';

class ProjectSelect extends Component {
  state = {};

  render() {
    const { auth, projects } = this.props;

    if (!auth.uid) return <Redirect to='/signin' />

    var projectEntries = Object.entries(projects);
    projectEntries = this.sortProjectsByDate(projectEntries);

    if (projectEntries.length > 0) {
      return (
        <Container>
          <a href="/" className="previous" style={{marginTop: 10}}> &laquo; Return </a>
          {projectEntries.map(entry => {
            const projectID = entry[0];
            const projectData = entry[1];

            if ((projectData.phase !== 'finished') && (projectData.phase !== 'deleted')) {
              const dateFormattedForLink = moment(projectData.startTime, "YYYY-MM-DD hh:mm").format('YYYY/MMMM');
              const idForLink = projectData.refLocation.id
              return (
                <div key={projectID} className="card z-depth-2 project-summary">
                  <div className="card-content grey-text text-darken-3">
                    <span className="card-title"> {projectData.client} </span>
                    <p className="grey-text"> {projectData.startTime} </p>
                    <Link style={{marginLeft: 0}} to={`/s/${dateFormattedForLink}/${idForLink}`}> Link </Link>
                  </div>
                </div>
              )
            } else {
              return (
                <></>
              )
            }
          })}
        </Container>
      )
    } else {
      return (
        <Container>
          <p> No Projects at this time... </p>
        </Container>
      )
    }
  }



  /***** HELPER FUNCTIONS BEGIN *****/
  sortProjectsByDate = (projectEntries) => {
    projectEntries.sort(function (a,b) { 
      if (moment(a[1].startTime, "YYYY-MM-DD hh:mm") > moment(b[1].startTime, "YYYY-MM-DD hh:mm")) return 1       
      if (moment(a[1].startTime, "YYYY-MM-DD hh:mm") < moment(b[1].startTime, "YYYY-MM-DD hh:mm")) return -1
      return 0;
    })

    return projectEntries;
  }
  /***** HELPER FUNCTIONS END *****/
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    users: state.firebase.users,
    projects: {
      ...state.firestore.data.NextMonthProjects,
      ...state.firestore.data.MonthProjects,
      ...state.firestore.data.LastMonthProjects,
    },
  }
}

 // Conditional code is included for the December -> January transition //
 export default compose(
  connect(mapStateToProps, null),
  firestoreConnect(() => [
    {
      collection : 'SchedulerProjects', doc: moment().subtract(1, 'M').format('MMMM') === "December" ? moment().subtract(1, 'Y').format('YYYY') : moment().format('YYYY'),
      subcollections: [{ collection : moment().subtract(1, 'M').format('MMMM') }],
      storeAs: 'LastMonthProjects'
    },
    {
      collection : 'SchedulerProjects', doc: moment().format('YYYY'),
      subcollections: [{ collection : moment().format('MMMM') }],
      storeAs: 'MonthProjects'
    },
    {
      collection : 'SchedulerProjects', doc: moment().add(1, 'M').format('MMMM') === "January" ? moment().add(1, 'Y').format('YYYY') : moment().format('YYYY'),
      subcollections: [{ collection : moment().add(1, 'M').format('MMMM') }],
      storeAs: 'NextMonthProjects'
    },
  ])
)(ProjectSelect);