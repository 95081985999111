import React from 'react';
import { connect } from 'react-redux';

import Modal from 'react-modal';
import Button from 'react-bootstrap/Button';

import moment from 'moment';
import emailjs from 'emailjs-com';

import { MdEmail } from 'react-icons/md';
import { IconContext } from "react-icons";

import { addToActivityLog } from '../../../../../../store/actions/projectActions';

const customStyles = {
    content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)'
    },
    overlay                 : {zIndex: 1000}
};
 
 
function ModalEmailUser(props){
    var subtitle;
    const [modalIsOpen,setIsOpen] = React.useState(false);

    function openModal() {
        setIsOpen(true);
    };
 
    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        subtitle.style.color = '#f00';
    };
 
    function closeModal(){
        setIsOpen(false);
    };
    
    function emailUser(refLocation, userID, adminID, userRole) {
        props.addToActivityLog(refLocation, `admin email ${userRole.lower} no response`, adminID, userID, userRole);
        sendUserEmail();
        closeModal();
    };

    function sendUserEmail () {
        var projectDate = moment(props.startTime);
        var templateParams = {
            user_name: `${props.firstName} ${props.lastName}`,
            client: props.client,
            date_of_project: projectDate.format("MM-DD"),
            user_email: props.userEmail
        }

        emailjs.send('scheduler@murphymckay', 'template_grs3yem', templateParams, "user_UgmdOeeH6OCjwrAqPoGpi")
        .then((result) => {
                console.log(result.text);
        }, (error) => {
                console.log(error.text);
        });

    }

    const { refLocation, userID, userName, adminID, userRole } = props;
    return (
        <>
            <IconContext.Provider style={{textAlign: 'right'}} value={{ className: "global-class-name" }}>
                <MdEmail onClick={openModal}/>
            </IconContext.Provider>

            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Warning Modal"
            >
                <h2 ref={_subtitle => (subtitle = _subtitle)}>
                    {`Email ${userRole.capitalize}`}
                </h2>
                
                <p>{"Are you sure you want to email " + userName + " to confirm this project?"}</p>
            
                <Button variant="primary" size="sm" onClick={closeModal} style={{marginRight: 10}}> Cancel </Button>
                <Button variant="success"  size="sm" onClick={() => emailUser(refLocation, userID, adminID, userRole)}> {"Email"} </Button>
            </Modal>
        </>
    );
};

const mapDispatchToProps = dispatch => {
    return {
        addToActivityLog: (refLocation, action, authorID, subjectID, userRole) => dispatch(addToActivityLog(refLocation, action, authorID, subjectID, userRole)),
    };
};

export default connect(null, mapDispatchToProps)(ModalEmailUser);