const initState = {
    commentError: null
}

const commentReducer = (state = initState, action) => {
    switch(action.type){
        case 'CREATE_COMMENT':
            console.log('create comment success');
            return {
                ...state,
                commentError: null
            }
        case 'CREATE_COMMENT_ERROR':
            console.log('create comment error');
            return {
                ...state,
                commentError: 'Create comment failed'
            }
        default:
            return state;
    }
}

export default commentReducer;