import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase';

import moment from 'moment';

class ProjectList extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    };

    render() {
        const { projects, uid } = this.props;

        var techWasInTheseProjects = [];

        Object.entries(projects).forEach(entry => {
            const project = entry[1];
            
            if (Object.keys(project.confirmedTechList).includes(uid)) {
                techWasInTheseProjects.push(project)
            }
        })

        techWasInTheseProjects.sort(function (a, b) {
            if (a.startTime > b.startTime) return 1
            if (a.startTime < b.startTime) return -1
            return 0;
          })

        return(
            Object.values(techWasInTheseProjects).map(project => {
                return(<div>{`${project.startTime} - ${project.client} : ${project.confirmedTechList[uid]}`}</div>)
            })
        )
    }
}

const mapStateToProps = (state) => {
    return {
        projects: {
            ...state.firestore.data.January,
            ...state.firestore.data.February,
            ...state.firestore.data.March,
            ...state.firestore.data.April,
            ...state.firestore.data.May,
            ...state.firestore.data.June,
            ...state.firestore.data.July,
            ...state.firestore.data.August,
            ...state.firestore.data.September,
            ...state.firestore.data.October,
            ...state.firestore.data.November,
            ...state.firestore.data.December
        }
    };
};

export default compose(
    connect(mapStateToProps, null),
    firestoreConnect((props) => {
        if (props.year) {
            return [
                {
                    collection: 'SchedulerProjects', doc: props.year,
                    subcollections: [{ collection: "January" }],
                    storeAs: "January"
                },
                {
                    collection: 'SchedulerProjects', doc: props.year,
                    subcollections: [{ collection: "February" }],
                    storeAs: "February"
                },
                {
                    collection: 'SchedulerProjects', doc: props.year,
                    subcollections: [{ collection: "March" }],
                    storeAs: "March"
                },
                {
                    collection: 'SchedulerProjects', doc: props.year,
                    subcollections: [{ collection: "April" }],
                    storeAs: "April"
                },
                {
                    collection: 'SchedulerProjects', doc: props.year,
                    subcollections: [{ collection: "May" }],
                    storeAs: "May"
                },
                {
                    collection: 'SchedulerProjects', doc: props.year,
                    subcollections: [{ collection: "June" }],
                    storeAs: "June"
                },
                {
                    collection: 'SchedulerProjects', doc: props.year,
                    subcollections: [{ collection: "July" }],
                    storeAs: "July"
                },
                {
                    collection: 'SchedulerProjects', doc: props.year,
                    subcollections: [{ collection: "August" }],
                    storeAs: "August"
                },
                {
                    collection: 'SchedulerProjects', doc: props.year,
                    subcollections: [{ collection: "September" }],
                    storeAs: "September"
                },
                {
                    collection: 'SchedulerProjects', doc: props.year,
                    subcollections: [{ collection: "October" }],
                    storeAs: "October"
                },
                {
                    collection: 'SchedulerProjects', doc: props.year,
                    subcollections: [{ collection: "November" }],
                    storeAs: "November"
                },
                {
                    collection: 'SchedulerProjects', doc: props.year,
                    subcollections: [{ collection: "December" }],
                    storeAs: "December"
                },
            ]
        } else {
            return [
                {
                    collection: 'SchedulerProjects',
                    doc: moment().format('YYYY'),
                    subcollections: [{ collection: moment().format('MMMM') }],
                    storeAs: 'currentProjects'
                },
            ]
        }
    })
)(ProjectList);