const initState = {
    projectError: null
  }
  
  const projectReducer = (state = initState, action) => {
    switch (action.type) {
      case 'CREATE_PROJECT':
        break;
        
      case 'CREATE_PROJECT_ERROR':
        break;

      case 'ADD_ID':
        break;

      case 'ADD_ID_ERROR':
        return action;
        
      case 'UPDATE_ID_ARRAY':
        return action.newIDarray;

      case 'UPDATE_ID_ARRAY_ERR':
        break;
        
      default:
        return action;
    }
  };
  
  export default projectReducer;