import React, { Component } from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import TechOptions from './TechOptions';

import './TechLists.css';

import { formatUserRole } from '../../../../../utils/userRoleUtils';

class TechLists extends Component {
  UIDtoName = (userID) => {
    if (this.props.users[userID]) {
      return `${this.props.users[userID].firstName} ${this.props.users[userID].lastName}`;
    } else {
      return "Loading user...";
    }
  };

  addPhoneNumber = (UID) => {
    if (this.props.users[UID]) {
      return this.formatPhoneNumber(this.props.users[UID].phone);
    } else {
      return "";
    }
  };

  formatPhoneNumber = (phoneNumberString) => {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return null;
  }

  addEmail = (UID) => {
    if (this.props.users[UID]) {
      return this.props.users[UID].email;
    } else {
      return "";
    }
  };

  clickMe = () => {
    return (<TechOptions />);
  }

  render() {
    const { project, users } = this.props;

    const userRoleTech = formatUserRole('tech');
    
    // Confirmed Tech List
    var confirmedUsers = [];
    var awaitingUsers = [];
    var deniedUsers = [];
    var droppedOffUsers = [];
    var removedUsers = [];
    var noShowUsers = [];

    // Proposed Tech List
    var proposedUsers = Object.keys(project.proposedTechList).filter(Boolean);

    // Fill confirmed user arrays
    Object.entries(project.confirmedTechList).filter(Boolean).forEach(entry => {
      const id = entry[0];
      const status = entry[1];

      switch (status) {
        case "confirmed":
          confirmedUsers.push(id);
          break;
        
        case "no response":
          awaitingUsers.push(id);
          break;
        
        case "denied":
          deniedUsers.push(id);
          break;

        case "dropped off":
          droppedOffUsers.push(id);
          break;
        
        case "removed":
          removedUsers.push(id);
          break;
        
        case "no show":
          noShowUsers.push(id);
          break;

        default:
          console.log("Error, default case reached");
      }
    })

    return(
      <div>
        {!!confirmedUsers.length && (
          <Row>
            <Col xs={12}>
              <div className="card-panel white lighten-3 z-depth-1">
                <h3>Confirmed</h3>

                <ol>
                  {confirmedUsers.map(id => {
                    return(
                      <div className="user-details" key={id}>
                        <Row className="removeRowMargin">
                          <li className="details" onClick={() => this.clickMe()}> {this.UIDtoName(id)} </li>
                          <TechOptions id={id} users={users} project={project} name={this.UIDtoName(id)} userRole={userRoleTech} />
                        </Row>
                          
                        <div className="details-group">
                          <a className="details" href={"tel:" + this.addPhoneNumber(id)}> {this.addPhoneNumber(id)} </a>
                          <a className="details" href={"mailto:" + this.addEmail(id)}> {this.addEmail(id)} </a>
                        </div>
                      </div>
                    )
                  })}
                </ol>
              </div>
            </Col>
          </Row>
        )}
          
        {!!awaitingUsers.length && (
          <Row>
            <Col xs={12}>
              <div className="card-panel white lighten-3 z-depth-1">
                <h3>Awaiting Responses</h3>

                <ol>
                  {awaitingUsers.map(id => {
                    return (
                      <div>   
                        <li className="details"> {this.UIDtoName(id)} </li>
                        <a className="details" href={"tel:" + this.addPhoneNumber(id)}> {this.addPhoneNumber(id)} </a>
                      </div>
                    )
                  })}
                </ol>
              </div>
            </Col>
          </Row>
        )}

        {!!proposedUsers.length && (
          <Row>
            <Col xs={12}>
              <div className="card-panel white lighten-3 z-depth-1">
                <h3>Available</h3>

                <ol>
                  {proposedUsers.map(id => {
                    return(
                      <div>   
                        <li className="details"> {this.UIDtoName(id)} </li>
                        <a className="details" href={"tel:" + this.addPhoneNumber(id)}> {this.addPhoneNumber(id)} </a>
                      </div>
                    )
                  })}
                </ol>
              </div>
            </Col>
          </Row>
        )}

        {!!(noShowUsers.length || deniedUsers.length || droppedOffUsers.length || removedUsers.length) && (
          <Row>
            <Col xs={12}>
              <div className="card-panel white lighten-3 z-depth-1">
                <h3>Not Available</h3>

                {!!noShowUsers.length && (
                  <div>
                    <h5> No Show </h5>
                    
                    <ol>
                      {noShowUsers.map(id => {
                        return (
                          <div>
                            <Row className="removeRowMargin">
                              <li className="details" onClick={() => this.clickMe()}> {this.UIDtoName(id)} </li>
                              <TechOptions id={id} users={users} project={project} name={this.UIDtoName(id)} userRole={userRoleTech} />
                            </Row> 
                          </div>
                        )
                      })}
                    </ol>
                  </div>
                )}

                {!!droppedOffUsers.length && (
                  <div>
                    <h5> Dropped Off </h5>
                      
                    <ol>
                      {droppedOffUsers.map(id => {
                        return(<li className="details"> {this.UIDtoName(id)} </li>)
                      })}
                    </ol>
                  </div>
                )}

                {!!deniedUsers.length && (
                  <div>
                    <h5> Denied </h5>
                      
                    <ol>
                      {deniedUsers.map(id => {
                        return(<li className="details"> {this.UIDtoName(id)} </li>)
                      })}
                    </ol>
                  </div>
                )}


                {!!removedUsers.length && (
                  <div>
                    <h5> Removed </h5>
                      
                    <ol>
                      {removedUsers.map(id => {
                        return(<li className="details"> {this.UIDtoName(id)} </li>)
                      })}
                    </ol>
                  </div>
                )}
              </div>
            </Col>
          </Row>
        )}
      </div>
    );
  };
};

export default TechLists;