import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';

import moment from 'moment';

class Checkins extends Component {
    constructor(props) {
        super(props);

        this.state = {
            expandList: true
        };
    };

    render() {
        const { users } = this.props;

        var thisMonday = moment().startOf('week').add(1, 'days').format('M-D-YY');
        var lastMonday = moment().startOf('week').subtract(6, 'days').format('M-D-YY');
        var twoMondaysAgo = moment().startOf('week').subtract(13, 'days').format('M-D-YY');
        var threeMondaysAgo = moment().startOf('week').subtract(20, 'days').format('M-D-YY');

        var usersThisWeek = 0;
        var usersLastWeek = 0;
        var usersTwoWeeksAgo = 0;
        var usersThreeWeeksAgo = 0;

        Object.entries(users).filter(Boolean).forEach(entry => {
            const user = entry[1];

            if (user.role === 'tech') {
                if (user.checkins) {
                    if (user.checkins[thisMonday]) {
                        usersThisWeek++;
                    }

                    if (user.checkins[lastMonday]) {
                        usersLastWeek++;
                    }

                    if (user.checkins[twoMondaysAgo]) {
                        usersTwoWeeksAgo++;
                    }

                    if (user.checkins[threeMondaysAgo]) {
                        usersThreeWeeksAgo++;
                    }
                }
            }
        })

        var percentOneToNow = usersThisWeek / usersLastWeek - 1;
        var percentTwoToOne = usersLastWeek / usersTwoWeeksAgo - 1;
        var percentThreeToTwo = usersTwoWeeksAgo / usersThreeWeeksAgo - 1;
        
        
        var displayOne = Math.round(percentOneToNow * 100);
        var displayTwo = Math.round(percentTwoToOne * 100);
        var displayThree = Math.round(percentThreeToTwo * 100);

        return (
            <Card className="ManageCard">
                <Card.Header as="h5">Check-Ins</Card.Header>
                <Card.Body>

                    <div className="card z-depth-0 project-summary">
                        <div className="card-content grey-text text-darken-3">
                            <Card.Title>
                                <Row style={{marginLeft: 0}}>
                                    {thisMonday + ': ' + usersThisWeek + ' Techs'}
                                    {percentOneToNow >= 0 ?
                                        <p style={{color: 'green', marginLeft: 5}}>{"(+" + displayOne + "%)"}</p> 
                                        :
                                        <p style={{color: 'red', marginLeft: 5}}>{"(" + displayOne + "%)"}</p>
                                    }
                                </Row>
                            </Card.Title>
                        </div>
                    </div>

                    <div className="card z-depth-0 project-summary">
                        <div className="card-content grey-text text-darken-3">
                            <Card.Title>
                                <Row style={{marginLeft: 0}}>
                                    {lastMonday + ': ' + usersLastWeek + ' Techs'}
                                    {percentTwoToOne >= 0 ?
                                        <p style={{color: 'green', marginLeft: 5}}>{"(+" + displayTwo + "%)"}</p> 
                                        :
                                        <p style={{color: 'red', marginLeft: 5}}>{"(" + displayTwo + "%)"}</p>
                                    }
                                </Row>
                            </Card.Title>
                        </div>
                    </div>

                    <div className="card z-depth-0 project-summary">
                        <div className="card-content grey-text text-darken-3">
                            <Card.Title>
                                <Row style={{marginLeft: 0}}>
                                    {twoMondaysAgo + ': ' + usersTwoWeeksAgo + ' Techs'}
                                    {percentThreeToTwo >= 0 ?
                                        <p style={{color: 'green', marginLeft: 5}}>{"(+" + displayThree + "%)"}</p> 
                                        :
                                        <p style={{color: 'red', marginLeft: 5}}>{"(" + displayThree + "%)"}</p>
                                    }
                                </Row>
                            </Card.Title>
                        </div>
                    </div>

                    <NavLink to={'/manage/checkins'} style={{marginLeft: 0}} className="btn blue lighten-1 white-text">
                        View More
                    </NavLink>
                </Card.Body>
            </Card>
        );
    };
};

export default (Checkins);