import React, { Component } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import CacheBuster from 'react-cache-buster';
import { version } from '../package.json';
import LoadingImage from './components/assets/Loading.gif';

import ErrorScreen from './components/error/ErrorScreen';
import Navbar from './components/layout/Navbar';
import BottomNavbar from './components/layout/mobile/BottomNavbar';
import RoleRedirect from './components/view/RoleRedirect';
import SignIn from './components/auth/SignIn';
import SignUp from './components/auth/SignUp';
import ProjectSelect from './components/view/ProjectSelect';
import ProjectOverviewAdmin from './components/view/adminView/projectOverviewAdmin/ProjectOverviewAdmin';
import ProjectOverviewUser from './components/view/userView/projectOverviewUser/ProjectOverviewUser';
import Manage from './components/manage/Manage';
import ProjectCalendar from './components/view/adminView/adminCalendar/ProjectCalendar';
import CreateProjectMobile from './components/view/adminView/adminCalendar/CreateProjectMobile';
import ProjectSignUp from './components/view/userView/projectSignUp/ProjectSignUp';
import BugReport from './components/bugReport/BugReport';
import UserInfoProfile from './components/userInfoProfile/UserInfoProfile';
import Expenses from './components/view/userView/projectOverviewUser/Expenses';
import Header from './components/manage/linksTo/Header';
import DetailedCheckin from './components/manage/linksTo/DetailedCheckin';
import EmailCenter from './components/manage/children/EmailCenter';
import TechHistory from './components/manage/children/TechHistory/TechHistory';
import TechWriteups from './components/manage/linksTo/TechWriteups/TechWriteups';
import Archive from './components/archive/Archive';

import mmaLogo from './components/assets/mma-logo.png';
import './App.css';

import { formatUserRole } from './utils/userRoleUtils';

const Loading = () => (
  <div className="loading-container">
    <img src={LoadingImage} alt="Loading..." />
  </div>
);

class App extends Component {
  state = { hasError: false };

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      return <ErrorScreen />;
    }

    const userRoleSupervisor = formatUserRole('supervisor');
    const userRoleLead = formatUserRole('lead');
    const userRoleTech = formatUserRole('tech');

    const isProduction = process.env.NODE_ENV === 'production';

    return (
      <CacheBuster
        currentVersion={version}
        isEnabled={isProduction} // If false, the library is disabled.
        isVerboseMode={false} // If true, the library writes verbose logs to console.
        loadingComponent={<Loading />} // If not passed, nothing appears at the time of new version check.
        metaFileDirectory={'.'} // If public assets are hosted somewhere other than root on your server.
      >
        <BrowserRouter>
          <div className="App">
            <Navbar />

            <Switch>
              <Route exact path='/' component={RoleRedirect} />
              <Route path='/signin' component={SignIn} />
              <Route path='/signup' component={SignUp} />
              <Route path='/select' component={ProjectSelect} />
              <Route exact path='/a/:year/:month/:id' component={ProjectOverviewAdmin} />
              <Route exact path='/s/:year/:month/:id' render={(props) => <ProjectOverviewUser {...props} userRole={userRoleSupervisor} />} />
              <Route exact path='/l/:year/:month/:id' render={(props) => <ProjectOverviewUser {...props} userRole={userRoleLead} />} />
              <Route exact path='/t/:year/:month/:id' render={(props) => <ProjectOverviewUser {...props} userRole={userRoleTech} />} />
              <Route exact path='/manage' component={Manage} />
              <Route path='/calendaradmin' component={ProjectCalendar} />
              <Route path='/createprojectm' component={CreateProjectMobile} />
              <Route path='/projectsignup' component={ProjectSignUp} />
              <Route path='/bugreport' component={BugReport} />
              <Route path='/userprofile' component={UserInfoProfile} />
              <Route exact path='/t/:year/:month/:id/expenses' component={Expenses} />
              <Route exact path='/manage/approve' component={Header} />
              <Route exact path='/manage/checkins' component={DetailedCheckin} />
              <Route exact path='/manage/emailcenter' component={EmailCenter} />
              <Route exact path='/manage/history/:uid' component={TechHistory} />
              <Route exact path='/manage/writeups/:uid' component={TechWriteups} />
              <Route exact path='/archive' component={Archive} />
            </Switch>

            <div style={{ marginTop: 10 }} className="container">
              <img src={mmaLogo} className="mmaImage" alt="MMA logo" />
            </div>

            <BottomNavbar />
          </div>
        </BrowserRouter>
      </CacheBuster>
    );
  }
}

export default App;
