import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';

import Modal from 'react-modal';
import Button from 'react-bootstrap/Button';

import ModalAddClient from './ModalAddClient';
import ModalRemoveClient from './ModalRemoveClient';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        maxHeight: '80vh', // Allow scrolling if content is too long
        overflowY: 'auto',
    },
    overlay: { zIndex: 1000 }
};

function ModalClientScope(props) {
    const [modalIsOpen, setIsOpen] = React.useState(false);

    function openModal() {
        setIsOpen(true);
    };

    function closeModal() {
        setIsOpen(false);
    };

    const { userID, clientScope, clients } = props;

    // Filter out clients that are already in the clientScope, before passing to ModalAddClient
    // Convert filtered clients into an array and sort them by client name
    const filteredClients = Object.keys(clients)
        .filter(clientID => !clientScope.includes(clientID)) // Filter out clients already in clientScope
        .map(clientID => ({ id: clientID, name: clients[clientID].name })) // Create an array of client objects
        .sort((a, b) => a.name.localeCompare(b.name)); // Sort clients by name

    return (
        <>
            <Button onClick={openModal} style={{ marginLeft: 5 }}>
                Companies
            </Button>

            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={() => {}}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Client Scope Modal"
            >
                <h2 style={{ display: 'flex', justifyContent: 'space-between', color: '#f00' }}>
                    {`Client Scope`}
                    <ModalAddClient clients={filteredClients} userID={userID} />
                </h2>

                <div>
                    {clientScope && clientScope.length > 0 ? (
                        clientScope.map(clientID => {
                            const clientData = clients ? clients[clientID] : null;
                            return (
                                clientData && (
                                    <div key={clientID} style={{ marginBottom: '10px' }}>
                                        <h3>{clientData.name}</h3>
                                        <ul>
                                            <li>
                                                <span>{`Client ID: ${clientID}`}</span>
                                                <ModalRemoveClient userID={userID} clientID={clientID} />
                                            </li>
                                        </ul>
                                    </div>
                                )
                            );
                        })
                    ) : (
                        <p>No clients available in the scope.</p>
                    )}
                </div>
            </Modal>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        clients: state.firestore.data.clients, // Fetch clients data from Firestore
    };
};

export default compose(
    connect(mapStateToProps),
    firestoreConnect([
        { collection: 'clients' } // Fetch all clients
    ])
)(ModalClientScope);
