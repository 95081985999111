import React, { Component } from 'react';

import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';

import moment from 'moment';

import ApproveReports from './ApproveReports';

class GatherReports extends Component {

    constructor(props) {
        super(props);

        this.state = {
            expenseStartDate: null,
            expenseEndDate: null
        }
    }

    handleDateChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    render() {
        const { projects, users } = this.props;

        var projectsQuery = {};
        if (this.state.expenseStartDate && this.state.expenseEndDate) {
            // Gather all projects within the date range
            Object.entries(projects).forEach(entry => {
                const id = entry[0]
                const project = entry[1]
    
                const projectStart = moment(project.startTime)
                const startThreshold = moment(this.state.expenseStartDate)
                const endThreshold = moment(this.state.expenseEndDate).add(1, 'day')
    
                if (projectStart >= startThreshold && projectStart < endThreshold ) {
                    projectsQuery[id] = moment(project.startTime).startOf("month").format('YYYY MMMM');
                }
            })
        }
        
        if (projectsQuery) {
            return (
                <div>
                    <Form.Row>
                        <Form.Group as={Col} controlId="expenseStartDate">
                            <Form.Label>Start Date</Form.Label>
                            <Form.Control
                                type="date"
                                placeholder="Enter a day"
                                onChange={this.handleDateChange}
                                required
                            />
                        </Form.Group>
        
                        <Form.Group className="FormEntries" as={Col} controlId="expenseEndDate">
                            <Form.Label>End Date</Form.Label>
                            <Form.Control
                                type="date"
                                placeholder="Enter a day"
                                onChange={this.handleDateChange}
                                required
                            />
                        </Form.Group>
                    </Form.Row>
        
                    {!!Object.keys(projectsQuery).length && (
                        <ApproveReports projects={projectsQuery} startDate={this.state.expenseStartDate} endDate={this.state.expenseEndDate} users={users}/>
                    )}
                </div>
            )
        } else {
            return(
                <div>Loading Projects...</div>
            )
        }
        
    }
}

export default GatherReports;