import React from 'react';
import { NavLink } from 'react-router-dom';

const SignedOutLinks = () => {

    if (window.innerWidth < 1200) {
        return (
          <div>
          </div>
        )
      } else {
        return (
            <ul className="right">
                <li><NavLink to='/signup'>Signup</NavLink></li>
                <li><NavLink to='/signin'>Login</NavLink></li>
            </ul>
        )
    }
}

export default SignedOutLinks;