import React, { Component } from 'react';
import { connect } from 'react-redux';

import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

import { resetHomeMessageSeen } from '../../../store/actions/userActions'


class VaccineStats extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    resetHomeMessages = () => {
        Object.entries(this.props.users).forEach(entry => {
            const id = entry[0];
            this.props.resetHomeMessageSeen(id);
        })
    }

    render() {
        const { users } = this.props;

        var vaccinatedCount = 0;
        var unvaccinatedCount = 0;
        Object.entries(users).forEach(entry => {
            const tech = entry[1];

            if (tech.role === 'tech') {
                if (tech.vaccineStatus === true) {
                    vaccinatedCount++
                } else {
                    unvaccinatedCount++;
                }
            }
        })

        return (
            <Card className="ManageCard">
                <Card.Header as="h5"> Stats </Card.Header>
                <Card.Body>
                <div className="card-content grey-text text-darken-3">
                    <Row>
                        <h5>Vaccinated Techs: {vaccinatedCount}</h5>
                    </Row>

                    <Row>
                        <h5>Unvaccinated/Undisclosed Techs: {unvaccinatedCount}</h5>
                    </Row>

                    <Row>
                        <h5>More project stats coming soon...</h5>
                    </Row>

                    <Row>
                        <Button
                            style={{marginLeft: 0}}
                            href='https://firebasestorage.googleapis.com/v0/b/mmatablet-11c34.appspot.com/o/app%2FMMADoc.apk?alt=media&token=0d8abdfd-8e8a-49cb-877e-9a627ca9f6bc'
                        >
                            Tablet .apk Download
                        </Button>
                    </Row>
                </div>

                </Card.Body>
            </Card>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        resetHomeMessageSeen: (userID) => dispatch(resetHomeMessageSeen(userID)),
    }
}

export default connect(null, mapDispatchToProps)(VaccineStats);


/*
                    <Row>
                        <Button onClick={() => this.resetHomeMessages()}>Reset Home Message</Button>
                    </Row>
                    */