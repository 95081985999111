import React, { Component } from 'react'
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';

import Row from 'react-bootstrap/Row'
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button'

import moment from 'moment';
import emailjs from 'emailjs-com';
import { v4 as uuidv4 } from 'uuid';

import { FaCheckCircle, FaRegTimesCircle } from "react-icons/fa";

import { updatePhase, addWriteUp } from '../../../../store/actions/projectActions'
import { updateAdminTimestamp } from '../../../../store/actions/userActions'

import LoadingWheel from '../../../assets/Loading.gif';

class Actions extends Component {
    constructor(props) {
        super(props);
    
        this.state = {
            emailBlastSent: false,
            emailBlastFailed: false,
            emailBlastFailedError: "",
            markedProjectsFinished: false,
            techAddresses: false,
            leadAddresses: false,
            supervisorAddresses: false,
            anyAddresses: false,
        }
    }

    sendEmailBlast = () => {
        var allEmails = [];
        Object.entries(this.props.users).forEach(entry => {
            const user = entry[1];
            
            if (user.role === 'tech' && user.email) {
                if (user.notifications.email.newProjects) {
                    allEmails.push(user.email);
                }
            }
        })
    
        // allEmails = ["robertmurphymckay@gmail.com"]    // DEBUG
    
        while (allEmails.length) {
            const templateParams = {
                Bcc: allEmails.splice(0, 98)
            };
          
            emailjs.send('scheduler@murphymckay', 'template_myp5vme', templateParams, "user_UgmdOeeH6OCjwrAqPoGpi")
                .then((result) => {
                    this.setState({emailBlastSent: true})
                    this.setState({emailBlastFailed: false})
                    this.props.updateAdminTimestamp("lastEmailBlast", moment().format("YYYY-MM-DD HH:mm"))
                }, (error) => {
                this.setState({emailBlastFailedError: error.text})
                this.setState({emailBlastSent: false})
                this.setState({emailBlastFailed: true})
            });
            console.log("Sent to", templateParams.Bcc)
        }
    }

    markProjectsFinished = (projects) => {
        Object.values(projects).filter(Boolean).forEach(project => {
            if (moment() > moment(project.endTime) && (project.phase !== "finished" && project.phase !== "deleted")) {
                this.props.updatePhase(project.refLocation, "finished");
    
                // Scan for No Shows
                Object.entries(project.confirmedTechList).filter(Boolean).forEach(entry => {
                    const id = entry[0];
                    const status = entry[1];
    
                    if (status === "no show") {
                        var writeUps = this.props.users[id].writeUps !== undefined ? writeUps : {};
                        const writeUpID = uuidv4();
                        writeUps[writeUpID] = project.refLocation;
                        this.props.addWriteUp(id, writeUps);
                    }
                })
            }
        })
    
        this.props.updateAdminTimestamp("lastMarkedFinished", moment().format("YYYY-MM-DD HH:mm"));
        this.setState({markedProjectsFinished: true});
    }

    copyEmailsToClipboard = (targetAddresses) => {
        var emailTest = ""
    
        if (targetAddresses === "Tech") {
            this.setState({techAddresses: true})
            this.setState({leadAddresses: false})
            this.setState({supervisorAddresses: false})
            this.setState({anyAddresses: false})

            Object.values(this.props.users).filter(Boolean).forEach(user => {
                if (user.role === "tech") {
                    if (user.email) {
                        emailTest += user.email + "; "
                    }
                }
            })
        } else if (targetAddresses === "Lead") {
            this.setState({techAddresses: false})
            this.setState({leadAddresses: true})
            this.setState({supervisorAddresses: false})
            this.setState({anyAddresses: false})

            Object.values(this.props.users).filter(Boolean).forEach(user => {
                if (user.role === "lead") {
                    if (user.email) {
                        emailTest += user.email + "; "
                    }
                }
            })
        } else if (targetAddresses === "Supervisor") {
            this.setState({techAddresses: false})
            this.setState({leadAddresses: false})
            this.setState({supervisorAddresses: true})
            this.setState({anyAddresses: false})

            Object.values(this.props.users).filter(Boolean).forEach(user => {
                if (user.role === "supervisor") {
                    if (user.email) {
                        emailTest += user.email + "; "
                    }
                }
            })

        } else if (targetAddresses === "Any") {
            this.setState({techAddresses: false})
            this.setState({leadAddresses: false})
            this.setState({supervisorAddresses: false})
            this.setState({anyAddresses: true})

            Object.values(this.props.users).filter(Boolean).forEach(user => {
                if (user.role === "tech" || user.role === "lead" || user.role === "supervisor") {
                    if (user.email) {
                        emailTest += user.email + "; "
                    }
                }
            })
        }
        navigator.clipboard.writeText(emailTest)
    }

    render() {
        const { administrative, projects } = this.props;

        if (administrative) {
            return (
                <Card body>
                    <Row className="ActionsCard">
                        <h2> Actions </h2>
                    </Row>

                    <Row className="ActionsCard">
                        <Button variant="primary" type="button" onClick={() => this.sendEmailBlast()}> 
                            Send Emails
                        </Button>

                        <p className="ActionsEmailText">Last sent: {moment(administrative.lastEmailBlast).format('MMMM D YYYY, h:mm a')}</p>

                        {this.state.emailBlastSent && (
                            <FaCheckCircle className="ActionsCheckmark"/>
                        )}

                        {this.state.emailBlastFailed && (
                            <FaRegTimesCircle className="ActionsFailureX"/>
                        )}
                        {this.state.emailBlastFailed && (
                            <p className="ActionsMarkTextNumber">{this.state.emailBlastFailedError}</p>
                        )}                    
                    </Row>

                    <Row className="ActionsCard">
                        <Button variant="primary" type="button" onClick={() => this.markProjectsFinished(projects)}>
                            Mark "Finished"
                        </Button>

                        <p className="ActionsMarkText">Last updated: {moment(administrative.lastMarkedFinished).format('MMMM D YYYY, h:mm a')}</p>

                        {this.state.markedProjectsFinished && (
                            <FaCheckCircle className="ActionsCheckmark"/>
                        )}
                    </Row>

                    <Row className="ActionsCardHeader">
                        <h5>Copy email addresses to clipboard:</h5>
                    </Row>

                    <Row className="ActionsCard">
                        <Button className="ClipboardButtons" type="button" onClick={() => this.copyEmailsToClipboard("Tech")}> 
                            Tech
                        </Button>

                        {this.state.techAddresses && (
                            <FaCheckCircle className="ActionsCheckmarkEmail"/>
                        )}
                        
                        <Button className="ClipboardButtons" type="button" onClick={() => this.copyEmailsToClipboard("Lead")}> 
                            Lead
                        </Button>

                        {this.state.leadAddresses && (
                            <FaCheckCircle className="ActionsCheckmarkEmail"/>
                        )}

                        <Button className="ClipboardButtons" type="button" onClick={() => this.copyEmailsToClipboard("Supervisor")}> 
                            Supervisor
                        </Button>

                        {this.state.supervisorAddresses && (
                            <FaCheckCircle className="ActionsCheckmarkEmail"/>
                        )}

                        <Button className="ClipboardButtons" type="button" onClick={() => this.copyEmailsToClipboard("Any")}> 
                            Any
                        </Button>

                        {this.state.anyAddresses && (
                            <FaCheckCircle className="ActionsCheckmarkEmail"/>
                        )}
                    </Row>
                </Card>
            )
        } else {
            return <img className="LoadingWheel" src={LoadingWheel} alt="loading..." />
        }
    }
}

const mapStateToProps = (state) => {
    return {
        administrative: state.firestore.data.administrative,
        users: state.firestore.data.users,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateAdminTimestamp: (field, time) => dispatch(updateAdminTimestamp(field, time)),
        updatePhase: (refLocation, phase) => dispatch(updatePhase(refLocation, phase)),
        addWriteUp: (writeUps, id) => dispatch(addWriteUp(writeUps, id))
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect(() => [
        {
            collection : "SchedulerProjects", doc: "administrative", storeAs: "administrative"
        },
        {
            collection: 'users'
        },
    ])
)(Actions);