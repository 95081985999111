import React, { Component } from 'react';

import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

import moment from 'moment';

import ModalDeleteWriteup from './Modals/ModalDeleteWriteup';

class Writeup extends Component {
  state = {};

  UIDtoName = (userID) => {
    if (this.props.users[userID]) {
      return this.props.users[userID].firstName + ' ' + this.props.users[userID].lastName
    } else {
      return "Loading user..."
    }
  };

  formatDate = (date) => {
    return moment(date).format("MM/DD/YYYY")
  }

  render() {
    const { userID, writeupID, writeupData } = this.props;

    const writeupStatus = writeupData.status;
    if (writeupStatus !== "Deleted") {
      return(
        <Col xs={4}>
          <Card>
            <Card.Header>
              {`${writeupData.client} - ${this.formatDate(writeupData.incidentDate)}`}
            </Card.Header>
  
            <Card.Body>
              <p style={{marginBottom: 40}}> <i>{`${writeupData.reason}`}</i> </p>
              <p style={{marginBottom: 10}}> {`Source: ${this.UIDtoName(writeupData.reportAuthor)} - ${this.formatDate(writeupData.reportDate)}`} </p>

              {writeupStatus === "Not seen" && (
                <p style={{marginBottom: 10}}> {`Status: `} <b>{`${writeupData.status}`}</b> </p>
              )}
              {!(writeupStatus === "Not seen") && (
                <p style={{marginBottom: 10}}> {`Status: `} <i>{`${writeupData.status}`}</i> </p>
              )}

              <ModalDeleteWriteup userID={userID} writeupID={writeupID}/>
            </Card.Body>
          </Card>
        </Col>
      )
    } else {
      return (<></>)
    }
  }
}

export default Writeup;