import React, { Component } from 'react';
import { connect } from 'react-redux';

import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

import moment from 'moment';

import { updateWriteupStatus } from '../../../store/actions/userActions';

class Writeup extends Component {
  state = {};

  UIDtoName = (userID) => {
    if (this.props.users[userID]) {
      return this.props.users[userID].firstName + ' ' + this.props.users[userID].lastName
    } else {
      return "Loading user..."
    }
  };

  formatDate = (date) => {
    return moment(date).format("MM/DD/YYYY")
  }

  handleSeenButton = (userID, writeupID) => {
    const currentDate = moment().format("MM/DD/YYYY")
    const statusWithTimestamp = `Seen - ${currentDate}`
    this.props.updateWriteupStatus(userID, writeupID, statusWithTimestamp);
  }

  render() {
    const { userID, writeupID, writeupData } = this.props;
    
    if (writeupData.status !== "Deleted") {
      return(
        <Col xs={12} lg={4}>
          <Card>
            <Card.Header>
              {`${writeupData.client} - ${this.formatDate(writeupData.incidentDate)}`}
            </Card.Header>
  
            <Card.Body>
              <p style={{marginBottom: 40}}> <i>{`${writeupData.reason}`}</i> </p>
              <p style={{marginBottom: 10}}> {`Source: ${this.UIDtoName(writeupData.reportAuthor)} - ${this.formatDate(writeupData.reportDate)}`} </p>

              {writeupData.status === "Not seen" && (
                <Button onClick={() => this.handleSeenButton(userID, writeupID)}> Seen </Button>
              )}

              {(writeupData.status !== "Not seen" && writeupData.status !== "Deleted") && (
                <p> <i>Seen</i> </p>
              )}
            </Card.Body>
          </Card>
        </Col>
      )
    } else {
      return (<></>)
    }
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateWriteupStatus: (userID, writeupID, newStatus) => dispatch(updateWriteupStatus(userID, writeupID, newStatus)),
  }
}

export default connect(null, mapDispatchToProps)(Writeup);