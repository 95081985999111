import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';

import moment from 'moment';

import TimePeriod from './TimePeriod';
import UpdateTechProjHistory from './UpdateTechProjHistory';

class DetailedCheckin extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    render() {
        const { users } = this.props

        const lastMonday = moment().day(1);
        const twoMondays = moment().day(1).subtract(7, "days");
        const threeMondays = moment().day(1).subtract(14, "days");
        const fourMondays = moment().day(1).subtract(21, "days");
        const lastSixMonths = moment().subtract(6, 'months').day(1);

        if (users) {
            
            // Add all techs that checked in for this week (and last 6 months) into arrays
            var usersThisWeek = []
            var usersOneWeek = []
            var usersTwoWeeks = []
            var usersThreeWeeks = []
            var usersLastSixMonths = []
            var usersNever = []

            Object.entries(users).filter(Boolean).forEach(entry => {
                const id = entry[0];
                const user = entry[1];

                if (user.role === "tech" && user.checkins) {
                    if (user.checkins[lastMonday.format('M-D-YY')]) {
                        usersThisWeek.push(id);
                    }

                    if (user.checkins[twoMondays.format('M-D-YY')]) {
                        usersOneWeek.push(id);
                    }
                    
                    if (user.checkins[threeMondays.format('M-D-YY')]) {
                        usersTwoWeeks.push(id);
                    }
                    
                    if (user.checkins[fourMondays.format('M-D-YY')]) {
                        usersThreeWeeks.push(id);
                    }

                    for (const checkinStr of Object.keys(user.checkins)) {
                        const checkinDate = moment(checkinStr, 'M-D-YY');
                        if (checkinDate.isSameOrAfter(lastSixMonths)) {
                            usersLastSixMonths.push(id);
                            break;
                        }
                    }
                    
                } else if (user.role === "tech" && !user.checkins) {
                    usersNever.push(id);
                }
            })

            return(
                <div>
                    <UpdateTechProjHistory users={users} year={moment().year().toString()}/>
                    <TimePeriod date={lastMonday} userList={usersThisWeek} users={users}/>
                    <TimePeriod date={twoMondays} userList={usersOneWeek} users={users}/>
                    <TimePeriod date={threeMondays} userList={usersTwoWeeks} users={users}/>
                    <TimePeriod date={fourMondays} userList={usersThreeWeeks} users={users}/>
                    <TimePeriod title="Last 6 Months: " userList={usersLastSixMonths} users={users}/>
                    <TimePeriod date={null} userList={usersNever} users={users}/>
                </div>
            )
        } else {
            return(
                <div>
                    Loading users...
                </div>
            )
        }
    }
}

const mapStateToProps = (state) => {
    return {
        users: state.firestore.data.users,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {

    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect(() => [
        {
            collection: 'users',
        },
    ])
)(DetailedCheckin);