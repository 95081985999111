import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import UserHome from './userView/userHome/UserHome';
import AdminHome from './adminView/adminHome/AdminHome';
import DeactivatedNotice from './otherView/DeactivatedNotice';
import SetupAccountNotice from './otherView/SetupAccountNotice';

import Loading from '../assets/Loading.gif';

import { formatUserRole } from '../../utils/userRoleUtils';

import './Dashboard.css';

const RoleRedirect = (props) => {
    
  const { auth, profile } = props;

  if (!auth.uid) return <Redirect to='/signin' />
  
  switch (profile.role) {
    case 'test':
    case 'tech':
    case 'lead':
    case 'supervisor':
      return <UserHome auth={auth} profile={profile} userRole={formatUserRole(profile.role)} />

    case 'admin':
      return <AdminHome />

    case 'deactivated':
      return <DeactivatedNotice profile={profile} />

    case 'none':
      return <SetupAccountNotice profile={profile} />

    default:
      return <img style={{marginTop: 20}} className="LoadingWheel" src={Loading} alt="loading..." />
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
  }
}

export default connect(mapStateToProps, null)(RoleRedirect);