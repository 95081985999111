import React, { Component } from 'react';
import { connect } from 'react-redux';

import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import moment from 'moment';

import { updatePhase, deleteFromConnectedProjects } from '../../../../store/actions/projectActions';

class ConfirmProject extends Component {
  state = {
    proposedList: [],
  }

	handleSubmit = (refLocation) => {
		this.props.updatePhase(refLocation, "scheduled");
	}

	handleSubmitTest = (refLocation) => {
		this.props.updatePhase(refLocation, "test");
	}

	handleSubmitConfirm = (refLocation) => {
		this.props.updatePhase(refLocation, "confirmed");
	}

	handleDelete = (refLocation) => {
		this.props.updatePhase(refLocation, "deleted");
	}

	handleSubmitAll = (monthProjects) => {
		Object.entries(monthProjects).filter(Boolean).forEach((entry) => {
			const project = entry[1];
			if (project) {
				if (project.phase === "proposed") {
					this.handleSubmit(project.refLocation)
				}
			}
		})
	}

  render() {
		const { projects } = this.props;

		return (
			<Card body>
				<h2>Projects to Publish</h2>

				{Object.entries(projects).filter(Boolean).map((entry) => {
					const id = entry[0]
					const project = entry[1]

					const startTime = moment(project.startTime, "YYYY-MM-DD hh:mm").format("MM/DD/YYYY hh:mm");

					if (project) {
            if (project.phase === 'proposed') {
              return (
                <div key={id} className="container section project-details">
                  <Row>
                    <Col xs={6}>
                      <p>{`${project.client} - ${startTime} - ${project.type}`}</p>
                    </Col>

                    <Col xs={6}>
                      <Button variant="primary" size ="sm"
                        onClick={() => this.handleSubmit(project.refLocation)}
                      >
                        Submit
                      </Button>

                      <Button style={{marginLeft: 5}} variant="danger" size ="sm"
                        onClick={() => this.handleDelete(project.refLocation)}
                      >
                        Delete
                      </Button>

                      <Button style={{marginLeft: 5}} variant="success" size ="sm"
                        onClick={() => this.handleSubmitConfirm(project.refLocation)}
                      >
                        Confirm
                      </Button>

                      <Button style={{marginLeft: 5}} variant="warning" size ="sm"
                        onClick={() => this.handleSubmitTest(project.refLocation)}
                      >
                        Test
                      </Button>
                    </Col>
                  </Row>
                </div>
              )
            } else {
              return <></>
            }
          } else {
            return <></>
          }
				})}

				<Button variant="primary" type="submit" onClick={() => this.handleSubmitAll(projects)}>
					Submit All
				</Button>
			</Card>
		)
	}
}

const mapDispatchToProps = dispatch => {
	return {
    updatePhase: (refLocation, phase) => dispatch(updatePhase(refLocation, phase)),
    deleteFromConnectedProjects: (refToDelete, refToDeleteFrom) => dispatch(deleteFromConnectedProjects(refToDelete, refToDeleteFrom))
	}
}

export default connect(null, mapDispatchToProps)(ConfirmProject);