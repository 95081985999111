import React from 'react';
import { connect } from 'react-redux';

import BottomSignedInLinks from './BottomSignedInLinks';
import BottomSignedOutLinks from './BottomSignedOutLinks';
import './navbar.css';

const BottomNavbar = (props) => {
    const { auth } = props;
    const links = auth.uid ? <BottomSignedInLinks /> : <BottomSignedOutLinks />;
    return (
        <div style={{marginTop: 80}}>
            <nav className="nav-wrapper fixed-bottom grey darken-3">
                <div className="container">
                    { auth.isLoaded && links }
                </div>
            </nav> 
        </div> 
    );
};

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
        profile: state.firebase.profile
    };
};

export default connect(mapStateToProps)(BottomNavbar);