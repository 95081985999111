import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';

import Modal from 'react-modal';
import Button from 'react-bootstrap/Button';

import ModalRemoveProject from './ModalRemoveProject';
import ModalAddProject from './ModalAddProject';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        maxHeight: '80vh',
        overflowY: 'auto',
    },
    overlay: { zIndex: 1000 }
};

function formatDayWithSuffix(day) {
    const suffixes = ["th", "st", "nd", "rd"];
    const v = day % 100;
    return day + (suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0]);
}

function ModalProjectScope(props) {
    const [modalIsOpen, setIsOpen] = useState(false);
    const [fetchedProjects, setFetchedProjects] = useState([]);

    useEffect(() => {
        const fetchProjects = async () => {
            const { firestore, projectScope } = props;
            if (projectScope && projectScope.length > 0) {
                const projectPromises = projectScope.map(ref => ref.get());
                const projectSnapshots = await Promise.all(projectPromises);
                const projectsData = projectSnapshots.map(doc => ({ id: doc.id, ...doc.data(), refLocation: doc.ref }));
                setFetchedProjects(projectsData);
            }
        };
        fetchProjects();
    }, [props.projectScope, props.firestore]);

    function openModal() {
        setIsOpen(true);
    };

    function closeModal() {
        setIsOpen(false);
    };

    const { userID, clients, configuration } = props;

    const config = configuration || { scheme: 'https', host: 'murphymckay.online' };

    // Group projects by clientName, then by year and month
    const groupedProjects = fetchedProjects.reduce((acc, projectData) => {
        const clientID = projectData.client;
        const date = projectData.date.toDate();
        const year = date.getFullYear();
        const month = date.toLocaleString('default', { month: 'long' });

        // todo: convert clientID to clientName
        const clientName = clients[clientID].name || clientID;
        
        if (!acc[clientName]) {
            acc[clientName] = {};
        }

        if (!acc[clientName][year]) {
            acc[clientName][year] = {};
        }

        if (!acc[clientName][year][month]) {
            acc[clientName][year][month] = [];
        }

        acc[clientName][year][month].push(projectData);

        return acc;
    }, {});

    const hasProjects = Object.keys(groupedProjects).length > 0;

    return (
        <>
            <Button onClick={openModal} style={{ marginLeft: 0 }} variant="success">
                Projects
            </Button>

            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Project Scope Modal"
            >
                <h2 style={{ display: 'flex', justifyContent: 'space-between', color: '#f00' }}>
                    {`Project Scope`}
                    <ModalAddProject userID={userID} />
                </h2>

                <div>
                    {hasProjects ? (
                        Object.entries(groupedProjects).map(([clientName, years]) => (
                            <div key={clientName} style={{ marginBottom: '20px' }}>
                                <h3>{clientName}</h3>
                                {Object.entries(years).map(([year, months]) => (
                                    <div key={year} style={{ marginBottom: '10px' }}>
                                        <h4 style={{ textAlign: 'center' }}>{year}</h4>
                                        {Object.entries(months).map(([month, projects]) => (
                                            <div key={month} style={{ marginBottom: '10px' }}>
                                                <h5>{month}</h5>
                                                <ul>
                                                    {projects
                                                        .sort((a, b) => a.date.toDate() - b.date.toDate()) // Sort projects by date
                                                        .map((projectData, index) => {
                                                            const date = projectData.date.toDate();
                                                            const dayWithSuffix = formatDayWithSuffix(date.getDate());
                                                            const projectURL = `${config.scheme}://${config.host}/${projectData.refLocation.path}`;

                                                            return (
                                                                <li key={index} style={{ marginBottom: '10px' }}>
                                                                    <span>{`${dayWithSuffix} - `}</span>
                                                                    <a href={projectURL} target="_blank" rel="noopener noreferrer" style={{ paddingLeft: '0', margin: '0' }}>
                                                                        {projectData.name}
                                                                    </a>
                                                                    <ModalRemoveProject userID={userID} projectID={projectData.id} />
                                                                </li>

                                                            );
                                                        })}
                                                </ul>
                                            </div>
                                        ))}
                                    </div>
                                ))}
                            </div>
                        ))
                    ) : (
                        <p>No projects available in the scope.</p>
                    )}
                </div>
            </Modal>
        </>
    );
};

const mapStateToProps = (state) => ({
    firestore: state.firestore, // Pass Firestore instance to the component
    configuration: state.firestore.data.configuration,
    clients: state.firestore.data.clients, // Fetch clients data from Firestore
});

export default compose(
    connect(mapStateToProps, null),
    firestoreConnect((props) => {
        return [
            {
                collection: 'projects',
                doc: 'configuration',
                storeAs: 'configuration'
            },
            { collection: 'clients' } // Fetch all clients
        ];
    })
)(ModalProjectScope);
