import React, {useState, useEffect} from 'react';

import moment from 'moment';

import GoogleSheetsGenerator from './GoogleSheetsGenerator'
import db from '../../../config/fbConfig'

const ApproveReports = (props) => {
    const [loading, setLoading] = useState(true);
    const [reports, setReports] = useState([]);

    useEffect(() => {
        Object.entries(props.projects).forEach(entry => {
            const id = entry[0];
            const project = entry[1];
            const year = String(moment(project).year());
            const month = moment(project).format('MMMM');

            const getReports = [];
            const subscriber = db
                .collection('SchedulerProjects').doc(year).collection(month).doc(id).collection('Expenses')
                .onSnapshot((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                        if (doc.data().status === "approved") {
                            getReports.push({
                                ...doc.data(),
                                key: doc.id
                            });
                        }
                    });

                    if (getReports.length) {
                        setReports(reports => [...reports, getReports]);
                        setLoading(false);
                    }
                });

                return () => subscriber();
        })
    }, [loading, props.projects]);


    const { startDate, endDate, users } = props;

    if (reports.length) {
        var newReports = []
        var reportIDs = []
        var uniqueUsers = []
        reports.forEach(reportArray => {
            reportArray.forEach(report => {
                if (!reportIDs.includes(report.key)) {
                    reportIDs.push(report.key)
                    newReports.push(report)
                    if (!uniqueUsers.includes(report.userID)) {
                        uniqueUsers.push(report.userID)
                    }
                }
            })
        })

        return (
            <div>
                <GoogleSheetsGenerator reports={newReports} startDate={startDate} endDate={endDate} uniqueUsers={uniqueUsers} users={users}/>
            </div>
        );
    } else {
        return(
            <h5>No reports in the given date range...</h5>
        )
    }
}

export default ApproveReports;