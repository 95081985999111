import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';

import Card from 'react-bootstrap/Card';

import Loading from '../../assets/Loading.gif';

import '../Dashboard.css';

const DeactivatedNotice = (props) => {
    const { adminInfo } = props;

    if (adminInfo) {
        return (
            <Card style={{width: "50%", margin: "auto", marginTop: 50}}>
                <Card.Body>
                    <Card.Title>Deactivated Account</Card.Title>
                    <Card.Text>
                        {"The account you are signed in with has been deactivated by a system administrator."
                            + " Please reach out to " + adminInfo.adminEmail + " if you think this was done mistakenly."}
                    </Card.Text>
                </Card.Body>
            </Card>
        )
    } else {
        return (
            <Card style={{width: "50%", margin: "auto", marginTop: 50}}>
                <Card.Body>
                    <img className="LoadingWheel" src={Loading} alt="loading..." />
                </Card.Body>
            </Card>
        )
    }
}

const mapStateToProps = (state) => {
    console.log(state)
    return {
        adminInfo: state.firestore.data.adminInfo
    }
}

export default compose(
    connect(mapStateToProps, null),
    firestoreConnect(() => [
        {
            collection : "SchedulerProjects", doc : "administrative",
            storeAs: 'adminInfo'
        }
    ])
)(DeactivatedNotice);
