import React, { Component } from 'react';
import { connect } from 'react-redux';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import { FaCheckCircle, FaRegTimesCircle } from "react-icons/fa";
import moment from 'moment';
import ImageUploader from 'react-images-upload';
import { v4 as uuidv4 } from 'uuid';

import './Expenses.css';
import { storage } from '../../../../config/fbConfig';

import { addParkingExpense } from '../../../../store/actions/userActions';

class ParkingReport extends Component {
    constructor(props) {
        super(props);

        this.state = {
            garageName: '',
            garageAddress: '',
            garageCost: 0,
            parkingPicture: null,
            missingPictureError: false,
            
            noSubmission: true,
            submitLoading: false,
            submitSuccess: false,
            submitFailure: false,
        }

        this.onDropParking = this.onDropParking.bind(this);
    }


    onDropParking (picture) {
        this.setState({parkingPicture: picture});
    }

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    handleSubmitParking = (refLocation, startTime, client) => {

        if (this.state.bartPicture === null) {
            this.setState({missingPictureError: true});
            return;
        }

        // Upload the image to Firebase Storage
        const year = moment().format('YYYY');
        const month = moment().format('MMMM');
        const image = this.state.parkingPicture[0];
        const imageName = uuidv4()

        this.setState({noSubmission: false})
        this.setState({submitFailure: false})
        this.setState({submitSuccess: false})
        this.setState({submitLoading: true})

        const uploadTask = storage.ref(`expenses/${year}/${month}/${imageName}`).put(image);
        uploadTask.on('state_changed',
        (snapshot) => {
            // progress function...
            //const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
            //this.setState({progress});
        },
        (error) => {
            this.setState({submitLoading: false})
            this.setState({submitFailure: true})
            console.log(error);
        }, 
        () => {
            // complete function...
            storage.ref(`expenses/${year}/${month}`).child(imageName).getDownloadURL().then(url => {
                // Once the URL is given back, proceed with creating the expense report in Firestore
                console.log(url);
                this.setState({submitLoading: false})
                this.setState({submitSuccess: true})
                this.props.addParkingExpense(
                    refLocation, this.props.auth.uid,
                    this.state.garageName, this.state.garageAddress,
                    Number(this.state.garageCost),
                    url, startTime, client
                )
          })
      });
        //this.setState({reportContent: ""})
    }

    render() {
        const { project } = this.props;

        return(
            <div className="card z-depth-3 project-summary cardMargins">
                <Container>
                    <h1 style={{textAlign: "center"}}>
                        Add Parking
                    </h1>

                    <Row className="removeRowMargin">
                        <Form.Group as={Col} controlId="garageName">
                            <Form.Label> Garage Name </Form.Label>
                            
                            <Form.Control
                                type="text"
                                //value={this.state.reportContent}
                                onChange={this.handleChange}
                                required
                            />
                        </Form.Group>
                    </Row>

                    <Row className="removeRowMargin">
                        <Form.Group as={Col} controlId="garageAddress">
                            <Form.Label>
                                Garage Address
                            </Form.Label>
                            
                            <Form.Control
                                type="text"
                                onChange={this.handleChange}
                                required
                            />
                        </Form.Group>
                    </Row>

                    <Row className="removeRowMargin">
                        <Form.Group as={Col} controlId="garageCost">
                            <Form.Label>
                                Cost ($)
                            </Form.Label>
                            
                            <Form.Control
                                type="number"
                                onChange={this.handleChange}
                                required
                            />
                        </Form.Group>
                    </Row>

                    <ImageUploader
                        withIcon={true}
                        withPreview={true}
                        buttonText='Choose image'
                        onChange={this.onDropParking}
                        imgExtension={['.jpg', '.png', 'jpeg']}
                        label={'Max file size: 5mb, accepted: jpg, png, jpeg'}
                        maxFileSize={5242880}
                        singleImage={true}
                    />

                    {!this.state.submitLoading && (
                        <Button variant="success" onClick={() => this.handleSubmitParking(project.refLocation, project.startTime, project.client)}>Submit</Button>
                    )}

                    {!!this.state.submitLoading && (
                        <Button variant="success" disabled onClick={() => this.handleSubmitParking(project.refLocation, project.startTime, project.client)}>Submitting...</Button>
                    )}

                    {(!this.state.noSubmission && this.state.submitSuccess) && (
                        <FaCheckCircle style={{color: "green", marginLeft: 6, marginTop: 9}}/>
                    )}

                    {(!this.state.noSubmission && this.state.submitFailure) && (
                        <FaRegTimesCircle style={{color: "green", marginLeft: 3, marginTop: 9}}/>
                    )}
                    
                    {!!this.state.missingPictureError && (
                        <p style={{marginTop: 10, color: 'red'}}> A picture is required to submit... </p>
                    )}
                </Container>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        addParkingExpense: (refLocation, userID, garageName, garageAddress, garageCost, pictureURL, startTime, client) =>
            dispatch(addParkingExpense(refLocation, userID, garageName, garageAddress, garageCost, pictureURL, startTime, client)),
    }
}

export default connect(null, mapDispatchToProps)(ParkingReport);