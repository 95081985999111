import React, { useRef, useState } from 'react';
import { connect } from 'react-redux'

import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'

import { createProject } from '../../../../store/actions/projectActions'
import './CreateProject.css'


const CreateProject = (props) => {

    const blankProject = { client: '', address: '',
                            city: '', addressLink: '', workstations: '',
                            type: '', day: '', startTime: '', endTime: '',
                            bartStation: '', bartDistance: '',
                            notes: '' };

    const [projectState, setProjectState] = useState([
        {...blankProject}
    ]);

    const addProject = () => {
        setProjectState([...projectState, {
            client: projectState[0].client,
            address: projectState[0].address, city: projectState[0].city,
            addressLink: projectState[0].addressLink, workstations: projectState[0].workstations,
            type: '', day: '', startTime: '', endTime: '',
            bartStation: projectState[0].bartStation, bartDistance: projectState[0].bartDistance,
            notes: projectState[0].notes
        }]);
    };

    const handleProjectChange = (e) => {
        const updatedProjects = [...projectState];
        updatedProjects[e.target.dataset.index][e.target.id] =
            e.target.value;
        setProjectState(updatedProjects)
    }

    //const [validated, setValidated] = useState(false);
    const formRef = useRef(null);

    const handleSubmit = (event) => {
        // Handle specific changes before sending to Firebase
        projectState.forEach(project => {
            event.preventDefault();
            project.startTime = project.day + ' ' + project.startTime
            project.endTime = project.day + ' ' + project.endTime
            delete project.day

            if (project.type === "Post-move") {
                project.workstations = 0
            }
        })
        props.createProject(projectState)
        handleFormReset()
    }

    const handleFormReset = () => {
        Array.from(document.querySelectorAll("input")).forEach(
            input => (input.value = "")
          );

        setProjectState([{...blankProject}])
    }

    return (
        <Card body>
            <Form ref={formRef} onSubmit={handleSubmit}>
                <h4>Create a New Project</h4>
                {
                     projectState.map((val, index) => {
                        return (
                            <div key={index}>
                                <Form.Row>
                                    <Form.Group className="FormEntries" as={Col} controlId="type">
                                        <Form.Label>Type</Form.Label>
                                        <Form.Control
                                            as="select"
                                            data-index={index}
                                            onChange={handleProjectChange}
                                        >     
                                            <option></option>
                                            <option>Disconnect</option>
                                            <option>Reconnect</option>
                                            <option>Shuffle</option>
                                            <option>Post-move</option>
                                            <option>Server</option>
                                            <option>Support</option>
                                            {/* Don't forget to change EditProject */}
                                        </Form.Control>
                                    </Form.Group>

                                    <Form.Group className="FormEntries" as={Col} controlId="workstations">
                                        {projectState[index].type !== "Server" && (
                                            <Form.Label>Workstations</Form.Label>
                                        )}

                                        {projectState[index].type === "Server" && (
                                            <Form.Label>Servers</Form.Label>
                                        )}

                                        <Form.Control
                                            type="number"
                                            data-index={index}
                                            placeholder={projectState[0].workstations || "Enter a number"}
                                            onChange={handleProjectChange}
                                            
                                        />
                                    </Form.Group>
                                </Form.Row>

                                {(index === 0 || projectState[index].type) && (
                                    <div>
                                        <Form.Row>
                                            <Form.Group className="FormEntries" as={Col} controlId="client">
                                                <Form.Label>Client</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    data-index={index}
                                                    placeholder={projectState[0].client || "Enter client name"}
                                                    onChange={handleProjectChange}
                                                    
                                                />
                                            </Form.Group>
                                        </Form.Row>


                                        <Form.Row>
                                            <Form.Group className="FormEntries" as={Col} controlId="address">
                                                <Form.Label>Address</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    data-index={index}
                                                    placeholder={projectState[0].address || "Enter address"}
                                                    onChange={handleProjectChange}
                                                    
                                                />
                                            </Form.Group>

                                            <Form.Group className="FormEntries" as={Col} controlId="city">
                                                <Form.Label>City</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    data-index={index}
                                                    placeholder={projectState[0].city || "Enter city"}
                                                    onChange={handleProjectChange}
                                                    
                                                />
                                            </Form.Group>

                                            <Form.Group className="FormEntries" as={Col} controlId="addressLink">
                                                <Form.Label>Google Maps Link</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    data-index={index}
                                                    placeholder={projectState[0].addressLink || "Enter link"}
                                                    onChange={handleProjectChange}
                                                />
                                            </Form.Group>
                                        </Form.Row>


                                        <Form.Row>
                                            <Form.Group className="FormEntries" as={Col} controlId="day">
                                                <Form.Label>Date</Form.Label>
                                                <Form.Control
                                                    type="date"
                                                    data-index={index}
                                                    placeholder="Enter a day"
                                                    onChange={handleProjectChange}
                                                    required
                                                />
                                            </Form.Group>
                                        
                                            <Form.Group className="FormEntries" as={Col} controlId="startTime">
                                                <Form.Label>Start Time</Form.Label>
                                                <Form.Control
                                                    type="time"
                                                    data-index={index}
                                                    placeholder="Enter the start time"
                                                    onChange={handleProjectChange}
                                                    required
                                                />
                                            </Form.Group>

                                            <Form.Group className="FormEntries" as={Col} controlId="endTime">
                                                <Form.Label>End Time</Form.Label>
                                                <Form.Control
                                                    type="time"
                                                    data-index={index}
                                                    placeholder="Enter the end time"
                                                    onChange={handleProjectChange}
                                                    required
                                                />
                                            </Form.Group>
                                        </Form.Row>

                                        <Form.Row>
                                            <Form.Group className="FormEntries"as={Col} controlId="bartStation">
                                                <Form.Label>Nearest BART Station</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    data-index={index}
                                                    placeholder={projectState[0].bartStation || "Enter the nearest BART Station"}
                                                    onChange={handleProjectChange}
                                                />
                                            </Form.Group>

                                            <Form.Group className="FormEntries"as={Col} controlId="bartDistance">
                                                <Form.Label>Distance to BART</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    data-index={index}
                                                    placeholder={projectState[0].bartDistance || "Enter the distance to BART"}
                                                    onChange={handleProjectChange}
                                                />
                                            </Form.Group>
                                        </Form.Row>

                                        <Form.Row>
                                            <Col xs={6}>
                                                <Form.Group controlId="notes">
                                                    <Form.Label> Notes </Form.Label>
                                                    <Form.Control
                                                        as="textarea"
                                                        data-index={index}
                                                        type="text"
                                                        placeholder="Enter any additional notes"
                                                        onChange={handleProjectChange}
                                                    />  
                                                </Form.Group>
                                            </Col>
                                        </Form.Row>
                                    </div>
                                )}
                            </div>
                        )
                    })
                }
                <Button variant="primary" onClick={addProject}>
                    Add Project
                </Button>
                {' '}
                <Button type="Submit" variant="success">
                    Submit
                </Button>
            </Form>
        </Card>
    );    
};

const mapDispatchToProps = dispatch => {
    return {
      createProject: (projectArray) => dispatch(createProject(projectArray)),
    }
  
}

export default connect(null, mapDispatchToProps)(CreateProject);