import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import moment from 'moment';
import { confirmUserWithResponse, userDeny, addToActivityLog } from '../../../../store/actions/projectActions';
import ModalDropOff from '../userCalendar/projectView/Modals/ModalDropOff';
import ModalDenyProject from '../userCalendar/projectView/Modals/ModalDenyProject';
import './UserHomeMobile.css';

class UserHomeMobile extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  };

  handleConfirm = (refLocation, userID, userRole) => {
    this.props.confirmUserWithResponse(refLocation, userID, userRole);
    var tempProp = {...this.state.signupButton};
    tempProp.message = 'Confirmed';
    tempProp.isDisabled = true;
    this.setState(() => ({confirmButton: tempProp}));
    this.props.addToActivityLog(refLocation, `${userRole.lower} confirm`, userID, userID, userRole);
  }

  render() {
    const { auth, categories, profile, adminEmail, userRole } = this.props;
    const {
      finishedProjects,
      requestedProjects,
      needToConfirmProjects,
      confirmedProjects,
      deniedProjects,
      droppedOffProjects,
      removedProjects
    } = categories;

    const getHref = (role) => {
      switch (role) {
          case 'supervisor':
              return "/s/";
          case 'lead':
              return "/l/";
          case 'tech':
              return "/t/";
          case 'admin':
              return "/a/";
          default:
              return "/t"; // Default case if userRole is not one of the expected values
      }
    };

    return (
      <div>
        {finishedProjects.length !== 0 && (
          <div style={{ marginTop: '5px', marginBottom: '15px' }}>
            <h4 style={{ textAlign: "center" }}>Completed Projects</h4>
            {finishedProjects.map(project => {
              return (
                <div key={project.refLocation.id}>
                  <div className="card z-depth-0 project-summary">
                    <div className="card-content grey-text text-darken-3">
                      <span className="inlineSpan"> {project.client} </span>
                      <span className="inlineSpan"> {project.city} </span>
                      <span className="inlineSpan"> {project.address} </span>
                      <span className="inlineSpan card-title">
                        {moment(project.startTime, "YYYY-MM-DD hh:mm").format('dddd MM/DD - h:mm a')}
                      </span>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}


        {!!needToConfirmProjects.length && (
          <div style={{ marginTop: '5px', marginBottom: '15px' }}>
            <h4 style={{ textAlign: "center" }}>Projects You Need to Confirm</h4>
            {needToConfirmProjects.map(project => {
              return (
                <div key={project.refLocation.id}>
                  <div className="card z-depth-0 project-summary">
                    <div className="card-content grey-text text-darken-3">
                      <span className="inlineSpan"> {project.client} </span>
                      <span className="inlineSpan"> {project.city} </span>
                      <span className="inlineSpan"> {project.address} </span>
                      <span className="inlineSpan card-title">
                        {moment(project.startTime, "YYYY-MM-DD hh:mm").format('dddd MM/DD - h:mm a')}
                      </span>
                      <Row style={{ marginLeft: "0px" }}>
                        <Button className="bigButton" variant='success' size='sm' type='submit'
                          onClick={() => this.handleConfirm(project.refLocation, auth.uid, userRole)}
                        >
                          Confirm
                        </Button>
                        &nbsp;
                        <ModalDenyProject
                            refLocation={project.refLocation}
                            userID={auth.uid}
                            client={project.client}
                            startTime={project.startTime}
                            profile={profile}
                            adminEmail={adminEmail}
                            userRole={userRole}
                        />
                      </Row>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}

        {!!confirmedProjects.length && (
          <div style={{ marginTop: '5px', marginBottom: '15px' }}>
            <h4 style={{ textAlign: "center" }}>Projects You Are Confirmed for</h4>
            {confirmedProjects.map(project => {
              return (
                // <Col xs={12} key={project.refLocation.id}>
                <div key={project.refLocation.id}>
                  {/* <Link style={{ padding: 0, margin: 0 }} to={`/t/${moment(project.startTime, "YYYY-MM-DD hh:mm").format('YYYY')}/${moment(project.startTime, "YYYY-MM-DD hh:mm").format('MMMM')}/${project.refLocation.id}`}> */}
                    {/* <span style={{ display: "block" }}> */}
                    <div className="card z-depth-0 project-summary">
                        <div className="card-content grey-text text-darken-3">
                          <span className="inlineSpan"> {project.client + ' - '} </span>
                          <span className="inlineSpan"> {project.city} </span>
                          <span className="inlineSpan"> {project.address} </span>
                          <span className="inlineSpan card-title">
                            {moment(project.startTime, "YYYY-MM-DD hh:mm").format('dddd MM/DD - h:mm a')}
                          </span>
                          <Row style={{ marginLeft: "0px" }}>
                        <Button style={{ marginLeft: 0 }}
                            href={getHref(userRole.lower) + 
                            moment(project.startTime, "YYYY-MM-DD hh:mm").format('YYYY') + '/' +
                            moment(project.startTime, "YYYY-MM-DD hh:mm").format('MMMM') + '/' +
                            project.refLocation.id}>
                            View Project
                        </Button>
                        &nbsp;
                        <ModalDropOff
                            refLocation={project.refLocation}
                            userID={auth.uid}
                            client={project.client}
                            startTime={project.startTime}
                            profile={profile}
                            adminEmail={adminEmail}
                            userRole={userRole}
                        />
                      </Row>
                        </div>
  
                      </div>
                    {/* </span> */}
                  {/* </Link> */}
                  </div>
                // </Col>
              );
            })}
          </div>
        )}

        {!!requestedProjects.length && (
          <div style={{ marginTop: '5px', marginBottom: '15px' }}>
            <h4 style={{ textAlign: "center" }}>Projects You Have Requested to Be On</h4>
            {requestedProjects.map(project => {
              return (
                <div key={project.refLocation.id}>
                  <div className="card z-depth-0 project-summary">
                    <div className="card-content grey-text text-darken-3">
                      <span className="inlineSpan"> {project.client} </span>
                      <span className="inlineSpan"> {project.city} </span>
                      <span className="inlineSpan"> {project.address} </span>
                      <span className="inlineSpan card-title">
                        {moment(project.startTime, "YYYY-MM-DD hh:mm").format('dddd MM/DD - h:mm a')}
                      </span>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}

        {/* Denied Projects Section */}
        {!!deniedProjects.length && (
          <div style={{ marginTop: '5px', marginBottom: '15px' }}>
            <h4 style={{ textAlign: "center" }}>Projects You Have Denied</h4>
            {deniedProjects.map(project => {
              return (
                <div key={project.refLocation.id}>
                  <div className="card z-depth-0 project-summary">
                    <div className="card-content grey-text text-darken-3">
                      <span className="inlineSpan"> {project.client} </span>
                      <span className="inlineSpan"> {project.city} </span>
                      <span className="inlineSpan"> {project.address} </span>
                      <span className="inlineSpan card-title">
                        {moment(project.startTime, "YYYY-MM-DD hh:mm").format('dddd MM/DD - h:mm a')}
                      </span>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}


          {/* Dropped Off Projects Section */}
          {!!droppedOffProjects.length && (
          <div style={{ marginTop: '5px', marginBottom: '15px' }}>
            <h4 style={{ textAlign: "center" }}>Projects You Have Dropped Off Of</h4>
            {droppedOffProjects.map(project => {
              return (
                <div key={project.refLocation.id}>
                  <div className="card z-depth-0 project-summary">
                    <div className="card-content grey-text text-darken-3">
                      <span className="inlineSpan"> {project.client} </span>
                      <span className="inlineSpan"> {project.city} </span>
                      <span className="inlineSpan"> {project.address} </span>
                      <span className="inlineSpan card-title">
                        {moment(project.startTime, "YYYY-MM-DD hh:mm").format('dddd MM/DD - h:mm a')}
                      </span>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}

          {/* Removed Projects Section */}
          {/* {!!removedProjects.length && (
          <div style={{ marginTop: '5px', marginBottom: '15px' }}>
            <h4 style={{ textAlign: "center" }}>Projects You Have Been Removed From</h4>
            {removedProjects.map(project => {
              return (
                <div key={project.refLocation.id}>
                  <div className="card z-depth-0 project-summary">
                    <div className="card-content grey-text text-darken-3">
                      <span className="inlineSpan"> {project.client} </span>
                      <span className="inlineSpan"> {project.city} </span>
                      <span className="inlineSpan"> {project.address} </span>
                      <span className="inlineSpan card-title">
                        {moment(project.startTime, "YYYY-MM-DD hh:mm").format('dddd MM/DD - h:mm a')}
                      </span>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )} */}

      </div>
    );
  };
};

const mapStateToProps = (state) => {
    return {
        profile: state.firebase.profile,
        auth: state.firebase.auth,
        email: state.firestore.data.EmailList,
    };
};

const mapDispatchToProps = dispatch => {
  return {
      confirmUserWithResponse: (refLocation, userID, userRole) => dispatch(confirmUserWithResponse(refLocation, userID, userRole)),
      userDeny: (refLocation, userID, userRole) => dispatch(userDeny(refLocation, userID, userRole)),
      addToActivityLog: (refLocation, action, authorID, subjectID, userRole) => dispatch(addToActivityLog(refLocation, action, authorID, subjectID, userRole)),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(UserHomeMobile);
