import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import Card from 'react-bootstrap/Card';
import BasicInfo from '../../userView/userCalendar/projectView/BasicInfo';
import ConnectedProjects from '../adminCalendar/projectView/ConnectedProjects';
import ConfirmedUserList from '../adminCalendar/projectView/ConfirmedUserList';
import ProposedUserList from '../adminCalendar/projectView/ProposedUserList';
import ManualAddUser from '../adminCalendar/projectView/ManualAddUser';
import ProjectControls from '../adminCalendar/projectView/ProjectControls';
import { formatUserRole } from '../../../../utils/userRoleUtils';

import Container from 'react-bootstrap/Container';
import moment from 'moment'

class ProjectOverviewAdmin extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    sortAvailableUsers = (unsortedUsers) => {
        var sortedUsers = [];
    
        for (var user in unsortedUsers) {
            sortedUsers.push([user, unsortedUsers[user]]);
        }
        sortedUsers.sort(function (a, b) {
            if (a[1].time > b[1].time) return 1
            if (a[1].time < b[1].time) return -1
            return 0;
        });
    
        return sortedUsers;
    }

    sortConfirmedUsers = (unsortedUsers) => {
        var sortedUsers = [];

        if (Object.keys(unsortedUsers).length > 0) {
            for (var user in unsortedUsers) {
                sortedUsers.push([user, unsortedUsers[user]]);
            }

            var ordering = {}, // map for efficient lookup of sortIndex
            sortOrder = ["dropped off", "denied", "no response", "confirmed"];
            for (var i=0; i<sortOrder.length; i++)
                ordering[sortOrder[i]] = i;
            
                sortedUsers.sort( function(a, b) {
                return (ordering[a[1]] - ordering[b[1]]) || a[1].localeCompare(b[1]);
            });   
        }
    
        return sortedUsers;
    }

    render() {
        const { auth, project, projects, users } = this.props;

        const userRoleSupervisor = formatUserRole('supervisor')
        const userRoleLead = formatUserRole('lead')
        const userRoleTech = formatUserRole('tech')

        if (project) {
            const sortedConfirmedSupervisorList = project.confirmedSupervisorList ? this.sortConfirmedUsers(project.confirmedSupervisorList) : 
            (Array.isArray(project.supervisorList) ? this.sortConfirmedUsers(project.supervisorList.reduce((acc, userID) => {
                acc[userID] = 'confirmed';
                return acc;
            }, {})) : []);

            const sortedAvailableSupervisorList = project.proposedSupervisorList ? this.sortAvailableUsers(project.proposedSupervisorList) : [];
            const availableSupervisorList = project.proposedSupervisorList ? project.proposedSupervisorList : [];

            const sortedConfirmedLeadList = project.confirmedLeadList ? this.sortConfirmedUsers(project.confirmedLeadList) : 
            (Array.isArray(project.leadList) ? this.sortConfirmedUsers(project.leadList.reduce((acc, userID) => {
                acc[userID] = 'confirmed';
                return acc;
            }, {})) : []);

            const sortedAvailableLeadList = project.proposedLeadList ? this.sortAvailableUsers(project.proposedLeadList) : [];
            const availableLeadList = project.proposedLeadList ? project.proposedLeadList : [];

            const sortedConfirmedTechList = project.confirmedTechList ? this.sortConfirmedUsers(project.confirmedTechList) : [];
            const sortedAvailableTechList = project.proposedTechList ? this.sortAvailableUsers(project.proposedTechList) : [];

            return (
              <Container>
              <a href="/calendaradmin" className="previous" style={{ marginTop: 10, marginBottom: 5 }}> &laquo; Return </a>
      
                <div>
                    <Card body style={{marginTop: 45}}>
                        <div style={{marginBottom: 40}}>
                            <BasicInfo project={project} phase={project.phase} />
                        </div>

                        <div style={{marginBottom: 40}}>
                            <ConnectedProjects project={project} projects={projects}/>
                        </div>

                        <hr className="hr"/>

                        {/* Supervisor */}
                        <h2 style={{ marginBottom: 15, textAlign: 'center' }}>Supervisors</h2>
                        <div style={{marginBottom: 40}}>
                            <ConfirmedUserList auth={auth} project={project} users={users} userRole={userRoleSupervisor} confirmedUserListSorted={sortedConfirmedSupervisorList}  />
                            <ManualAddUser     auth={auth} project={project} users={users} userRole={userRoleSupervisor} proposedUserList={availableSupervisorList}               />
                            <ProposedUserList  auth={auth} project={project} users={users} userRole={userRoleSupervisor} proposedUserList={availableSupervisorList} proposedUserListSorted={sortedAvailableSupervisorList}    />
                        </div>

                        <hr className="hr"/>

                        {/* Lead */}
                        <h2 style={{ marginBottom: 15, textAlign: 'center' }}>Leads</h2>
                        <div style={{marginBottom: 40}}>
                            <ConfirmedUserList auth={auth} project={project} users={users} userRole={userRoleLead} confirmedUserListSorted={sortedConfirmedLeadList}  />
                            <ManualAddUser     auth={auth} project={project} users={users} userRole={userRoleLead} proposedUserList={availableLeadList}               />
                            <ProposedUserList  auth={auth} project={project} users={users} userRole={userRoleLead} proposedUserList={availableLeadList} proposedUserListSorted={sortedAvailableLeadList}    />
                        </div>

                        <hr className="hr"/>

                        {/* Tech */}
                        <h2 style={{ marginBottom: 15, textAlign: 'center' }}>Techs</h2>
                        <div style={{marginBottom: 40}}>
                            <ConfirmedUserList auth={auth} project={project} users={users} userRole={userRoleTech} confirmedUserListSorted={sortedConfirmedTechList}  />
                            <ManualAddUser     auth={auth} project={project} users={users} userRole={userRoleTech} proposedUserList={project.proposedTechList}        />
                            <ProposedUserList  auth={auth} project={project} users={users} userRole={userRoleTech} proposedUserList={project.proposedTechList} proposedUserListSorted={sortedAvailableTechList}    />
                        </div>

                        <hr className="hr"/>

                        <div style={{marginBottom: 40}}>
                            <ProjectControls project={project} users={users}/>
                        </div>
                        
                        {/* Display Project ID */}
                        <span style={{fontSize: 10}}>{project.refLocation.id}</span>
                    </Card>
                </div>
                        <a href="/calendaradmin" className="previous" style={{ marginTop: 10, marginBottom: 5 }}> &laquo; Return </a>
                        </Container>
            );
        } else {
            return (
                <></>
            );
        };
    } ;  
};

const mapStateToProps = (state) => {
    return {
      auth: state.firebase.auth,
      project: state.firestore.data.project,
      projects: {
        ...state.firestore.data.MonthProjects,
        ...state.firestore.data.NextMonthProjects,
        ...state.firestore.data.LastMonthProjects,
      },
      users: state.firestore.data.users,
    };
};

export default compose(
    connect(mapStateToProps, null),
    firestoreConnect((props) => [
        {
            collection: 'users'
        },
        {
            collection : 'SchedulerProjects', doc: moment().subtract(1, 'M').format('MMMM') === "December" ? moment().subtract(1, 'Y').format('YYYY') : moment().format('YYYY'),
            subcollections: [{ collection : moment().subtract(1, 'M').format('MMMM') }],
            storeAs: 'LastMonthProjects'
        },
        {
            collection : 'SchedulerProjects', doc: moment().format('YYYY'),
            subcollections: [{ collection : moment().format('MMMM') }],
            storeAs: 'MonthProjects'
        },
        {
            collection : 'SchedulerProjects', doc: moment().add(1, 'M').format('MMMM') === "January" ? moment().add(1, 'Y').format('YYYY') : moment().format('YYYY'),
            subcollections: [{ collection : moment().add(1, 'M').format('MMMM') }],
            storeAs: 'NextMonthProjects'
        },
        {
          collection: 'SchedulerProjects', doc: props.match.params.year,
          subcollections: [{ collection: props.match.params.month, doc: props.match.params.id }],
          storeAs: 'project'
        },
    ])
)(ProjectOverviewAdmin);