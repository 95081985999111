import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'

import { FaCheckCircle, FaRegTimesCircle } from "react-icons/fa";

import './BugReport.css'

import {reportABug} from '../../store/actions/userActions'


class BugReportMobile extends Component {

    state = {
        reportContent: "",
    }

    handleChange = (e) => {
        this.setState({
          [e.target.id]: e.target.value
        })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({reportContent: ""})
        this.props.reportABug(this.props.auth.uid, this.state.reportContent)
    }

    render() {
        const { auth, userError, userSuccess } = this.props
        if (!auth.uid) return <Redirect to='/signin' />

        return (
            <div className="customDashboardContainerMobile">
                <h1 style={{textAlign: "center"}}>
                    Submit a Bug
                </h1>
                <Form >
                    <Form.Group as={Col} controlId="reportContent">
                        <Form.Label>Provide a description of the bug:</Form.Label>
                        <Form.Control
                            as="textarea" rows={3}
                            value={this.state.reportContent}
                            onChange={this.handleChange}
                            required
                        />
                    </Form.Group>
                    <Button className="submitButton" onClick={this.handleSubmit}>{"Submit"}</Button>
                </Form>
                <div className="red-text center">
                    { userError ? <div><FaRegTimesCircle className="feedbackIcon"/> {userError}</div> : null }
                </div>
                <div className="green-text center">
                { userSuccess ? <div><FaCheckCircle className="feedbackIcon"/> {userSuccess}</div> : null }
                </div>
            </div>
        )   
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
      reportABug: (userID, report) => dispatch(reportABug(userID, report)),
    }
  
}

export default connect(null, mapDispatchToProps)(BugReportMobile);