import React, { Component } from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import moment from 'moment';

class Comments extends Component {
  UIDtoName = (userID) => {
    if (this.props.users[userID]) {
      return `${this.props.users[userID].firstName} ${this.props.users[userID].lastName}`;
    } else {
      return "Loading user...";
    }
  };

  render() {
    const { project } = this.props;

    var commentsSorted = Object.values(project.comments);
    commentsSorted.sort(function (a, b) {
      if (a.timestamp > b.timestamp) return 1;
      if (a.timestamp < b.timestamp) return -1;
      return 0;
    })

    if (commentsSorted.length) {
      return (
        Object.entries(commentsSorted).map(entry => {
          const commentID = entry[0];
          const content = entry[1];
    
          return (
            <div key={commentID} className="card-panel grey white-text" style={{display: "flex", justifyContent: "center",  width: "90%"}}>
              <Container>
                <Row>
                  <Col xs={6} className="CommentName">
                    {this.UIDtoName(content.userID)}
                  </Col>
      
                  <Col xs={6} className="CommentTime" style={{textAlign: "right"}}>
                    {moment.unix(content.timestamp.seconds).format('dddd, MMMM Do, h:mm A')}
                  </Col>
                </Row>
      
                <Row className="Comment">
                  {content.comment}
                </Row>
              </Container>
            </div>
          )
        })
      )
    } else {
      return (
        <> </>
      )
    }
  }
}

export default Comments;