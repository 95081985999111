import React, { Component } from 'react';
import { connect } from 'react-redux';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

import moment from 'moment';
import SelectSearch from 'react-select-search';

class SearchSkill extends Component {
  state = {
    certificationSearch: "",
    experienceSearch: "",
  }

  handleCertChange = (cert) => {
    this.setState({certificationSearch: cert})
  }

  handleExpChange = (exp) => {
    this.setState({experienceSearch: exp})
  }

  UIDtoName = (userID) => {
    if (this.props.users[userID]) {
      return this.props.users[userID].firstName + ' ' + this.props.users[userID].lastName
    } else {
      return "Loading user..."
    }
  };

  render() {
    const { users } = this.props;

    const ComptiaCerts = [{name: "CompTIA ITF+", value: "CompTIA ITF+"}, {name: "CompTIA A+", value: "CompTIA A+"}, {name: "CompTIA Network+", value: "CompTIA Network+"}, {name: "CompTIA Security+", value: "CompTIA Security+"}, {name: "CompTIA Cloud+", value: "CompTIA Cloud+"}, {name: "CompTIA Linux+", value: "CompTIA Linux+"}, {name: "CompTIA Server+", value: "CompTIA Server+"}, {name: "CompTIA CySA+", value: "CompTIA CySA+"}, {name: "CompTIA CASP+", value: "CompTIA CASP+"}, {name: "CompTIA PenTest+", value: "CompTIA PenTest+"}, {name: "CompTIA Data+", value: "CompTIA Data+"}, {name: "CompTIA Project+", value: "CompTIA Project+"}, {name: "CompTIA CTT+", value: "CompTIA CTT+"}, {name: "CompTIA Cloud Essentials+", value: "CompTIA Cloud Essentials+"}];
    const GoogleCerts = [{name: "Google - Digital Marketing & E-commerce", value: "Google - Digital Marketing & E-commerce"}, {name: "Google - IT Support", value: "Google - IT Support"}, {name: "Google - Data Analytics", value: "Google - Data Analytics"}, {name: "Google - Project Management", value: "Google - Project Management"}, {name: "Google - UX Design", value: "Google - UX Design"}]
    const MicrosoftCerts = ["Microsoft - Placement Cert 1", "Microsoft - Placement Cert 2", "Microsoft - Placement Cert 3"];
    const CiscoCerts = ["Cisco - Technology (Entry)", "Cisco - Technology (Associate)", "Cisco - Technology (Professional)", "Cisco - Technology (Expert)"];

    const ServerExperience = [{name: "Server Racks - <1 year", value: "Server Racks - <1 year"}, {name: "Server Racks - 1 to 2 years", value: "Server Racks - 1 to 2 years"}, {name: "Server Racks - 2 to 3 years", value: "Server Racks - 2 to 3 years"}, {name: "Server Racks - 3+ years", value: "Server Racks - 3+ years"}]
    const WindowsExperience = [{name: "Windows Imaging - <1 year", value: "Windows Imaging - <1 year"}, {name: "Windows Imaging - 1 to 2 years", value: "Windows Imaging - 1 to 2 years"}, {name: "Windows Imaging - 2 to 3 years", value: "Windows Imaging - 2 to 3 years"}, {name: "Windows Imaging - 3+ years", value: "Windows Imaging - 3+ years"}]
    const MacExperience = [{name: "Mac Imaging - <1 year", value: "Mac Imaging - <1 year"}, {name: "Mac Imaging - 1 to 2 years", value: "Mac Imaging - 1 to 2 years"}, {name: "Mac Imaging - 2 to 3 years", value: "Mac Imaging - 2 to 3 years"}, {name: "Mac Imaging - 3+ years", value: "Mac Imaging - 3+ years"}]

    //var allCerts = [...ComptiaCerts, ...GoogleCerts, ...MicrosoftCerts, ...CiscoCerts];
    var allCerts = [...ComptiaCerts, ...GoogleCerts];
    allCerts.sort();

    var allExperience = [...ServerExperience, ...WindowsExperience, ...MacExperience];
    allExperience.sort();

    var userCertList = [];
    var userExpList = [];

    if (this.state.certificationSearch) {
      Object.entries(users).forEach(entry => {
        const id = entry[0];
        const user = entry[1];
        if (user.certifications) {
          if (user.certifications.includes(this.state.certificationSearch)) {
            userCertList.push(id)
          }
        }
      })
    }

    if (this.state.experienceSearch) {
      Object.entries(users).forEach(entry => {
        const id = entry[0];
        const user = entry[1];
        if (user.experience) {
          if (user.experience.includes(this.state.experienceSearch)) {
            userExpList.push(id)
          }
        }
      })
    }

    return(
      <Card className="ManageCard">
        <Card.Header as="h5"> Search a Skill </Card.Header>

        <Card.Body>
          <Row>
            <Col md={12} lg={6}>
                <div className="card-content grey-text text-darken-3">
                    <Row>
                        <Card.Title style={{marginRight: 15}}> Certifications </Card.Title>
                        <SelectSearch options={allCerts} search={true} onChange={this.handleCertChange} placeholder="Choose..." emptyMessage="No certifications loaded..." />
                    </Row>

                    <Row>
                      <Col>
                      {userCertList.map(user => {
                        return (
                          <p>{this.UIDtoName(user)}</p>
                        )
                      })}
                      </Col>
                    </Row>
                </div>
            </Col>

            <Col xs={12} lg={6}>
              <div className="card-content grey-text text-darken-3">
                <Row>
                  <Card.Title style={{marginRight: 15}}> Experience </Card.Title>
                  <SelectSearch options={allExperience} search={true} onChange={this.handleExpChange} placeholder="Choose..." emptyMessage="No experience loaded..." />
                </Row>

                <Row>
                  <Col>
                    {userExpList.map(user => {
                      return (
                        <p>{this.UIDtoName(user)}</p>
                      )
                    })}
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card> 
    )
  }
}

export default SearchSkill;