import React, { Component } from 'react';


import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

import SelectSearch from 'react-select-search';

import ModalProjectsForClients from './Modals/ModalProjectsForClients';

class SearchClient extends Component {
    constructor(props) {
        super(props);

        this.state = {
            clientSearchID: '',
        };
    }

    handleClientChange = (id) => {
        this.setState({ clientSearchID: id });
    }

    render() {
        const { clients, projects } = this.props;
        var clientOptions = [];
        Object.entries(clients).forEach(entry => {
            const id = entry[0];
            const client = entry[1];
            clientOptions.push({ name: client.name, value: id });
        });

        clientOptions.sort(function (a, b) {
            if (a.name > b.name) return 1;
            if (a.name < b.name) return -1;
            return 0;
        });

        return (
            <Card className="ManageCard">
                <Card.Header as="h5"> Search a Company </Card.Header>
                <Card.Body>
                    <Row>
                        <Col xs={12} lg={4}>
                            <div className="card-content grey-text text-darken-3">
                                <Row>
                                    <Card.Title style={{ marginRight: 15 }}> Companies </Card.Title>
                                    <SelectSearch options={clientOptions} search={true} onChange={this.handleClientChange} placeholder="Choose a company..." emptyMessage="No companies loaded..." />
                                </Row>

                                <Row>
                                    {clients[this.state.clientSearchID] && (
                                        <div className="card-content grey-text text-darken-3">
                                            <Row>
                                                <p>{"Name: "} <b>{clients[this.state.clientSearchID].name}</b></p>
                                            </Row>

                                            <Row>
                                                <p>{"ID: "} <b>{this.state.clientSearchID}</b></p>
                                            </Row>

                                            <Row>
                                                <ModalProjectsForClients
                                                    clientID={this.state.clientSearchID}
                                                    clientName={clients[this.state.clientSearchID].name}
                                                />
                                            </Row>
                                        </div>
                                    )}
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        );
    }
}

export default SearchClient;