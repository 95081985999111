import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';

import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import moment from 'moment';

import { updatePhase } from '../../../../store/actions/projectActions';

class ConfirmProjectMobile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      proposedList: []
    }
  }

  handleSubmit = (refLocation) => {
    console.log("Updating: ", refLocation.id)
    this.props.updatePhase(refLocation, 'scheduled')
  }

  handleSubmitTest = (refLocation) => {
    console.log("Updating: ", refLocation.id)
    this.props.updatePhase(refLocation, 'test')
  }

  handleSubmitConfirm = (refLocation) => {
    console.log("Updating: ", refLocation.id)
    this.props.updatePhase(refLocation, 'confirmed')
  }

  handleDelete = (refLocation) => {
    console.log("Deleting: ", refLocation.id)
    this.props.updatePhase(refLocation, "deleted")
  }

  render() {
    const { monthProjects, nextMonthProjects, lastMonthProjects } = this.props;

    const threeMonthsProjects = {
      ...monthProjects,
      ...nextMonthProjects,
      ...lastMonthProjects,
    }

    return (
        <Card body>
            <h2>Projects to Publish</h2>
            {Object.entries(threeMonthsProjects).filter(Boolean).map((entry) => {
              const id = entry[0]
              const project = entry[1]

              if (project) {
                if (project.phase === 'proposed') {
                  return(
                      <div key = {id} className="container section project-details">
                          <Row>
                              <Col xs={12}>
                                  <li>{project.client + ' - ' + project.type + ' - ' + id}</li>
                              </Col>

                              <Col xs={12}>
                                  <Button variant="primary" size = 'sm' type="submit"
                                      onClick={() => this.handleSubmit(project.refLocation)}
                                  >
                                      Submit
                                  </Button>
                                  &nbsp;
                                  <Button variant="danger" size = 'sm' type="submit"
                                      onClick={() => this.handleDelete(project.refLocation)}
                                  >
                                      Delete
                                  </Button>
                                  &nbsp;
                                  <Button variant="success" size = 'sm' type="submit"
                                      onClick={() => this.handleSubmitConfirm(project.refLocation)}
                                  >
                                      Confirm
                                  </Button>
                                  &nbsp;
                                  <Button variant="warning" size = 'sm' type="submit"
                                      onClick={() => this.handleSubmitTest(project.refLocation)}
                                  >
                                      Test
                                  </Button>
                              </Col>
                          </Row>
                      </div>
                  )
                } else {
                  return <></>
                }
              } else {
                return <></>
                }         
              })
            }
        </Card>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    monthProjects: state.firestore.data.MonthProjects,
    nextMonthProjects: state.firestore.data.NextMonthProjects,
    lastMonthProjects: state.firestore.data.LastMonthProjects,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updatePhase: (refLocation, phase) => dispatch(updatePhase(refLocation, phase)),
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect(() => [
      {
        collection : 'SchedulerProjects', doc : moment().format('YYYY'),
        subcollections: [{ collection : moment().format('MMMM') }],
        storeAs: 'MonthProjects'
      },
      {
        collection : 'SchedulerProjects', doc : moment().add(1, 'M').format('MMMM') === "January" ? moment().add(1, 'Y').format('YYYY') : moment().format('YYYY'),
        subcollections: [{ collection : moment().add(1, 'M').format('MMMM') }],
        storeAs: 'NextMonthProjects'
      },
      {
        collection : 'SchedulerProjects', doc : moment().subtract(1, 'M').format('MMMM') === "December" ? moment().subtract(1, 'Y').format('YYYY') : moment().format('YYYY'),
        subcollections: [{ collection : moment().subtract(1, 'M').format('MMMM') }],
        storeAs: 'LastMonthProjects'
      },
      {
        collection: 'users'
      }
  ])
)(ConfirmProjectMobile);