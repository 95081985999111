// THIS CAN EASILY BE OPTIMIZED BY SUB-COMPONENTS FOR EACH WEEK

import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import emailjs from 'emailjs-com';
import moment from 'moment';

import { FaCheckCircle } from "react-icons/fa";
import Loading from '../../assets/Loading.gif';

import { updateAdminTimestamp } from '../../../store/actions/userActions';

//import './EmailCenter.css'

class EmailCenter extends Component {
    constructor(props) {
        super(props);

        this.state = {
            threeWeekEmailSent: false,
            threeWeekEmailFailed: false,
            fourWeekEmailSent: false,
            fourWeekEmailFailed: false,
            fiveWeekEmailSent: false,
            fiveWeekEmailFailed: false,
            sixWeekEmailSent: false,
            sixWeekEmailFailed: false,
            sevenWeekEmailSent: false,
            sevenWeekEmailFailed: false,
            emailErrorText: "",

            oneWeekCopy: false,
            twoWeekCopy: false,
            threeWeekCopy: false,
            fourWeekCopy: false,
            fiveWeekCopy: false,
            sixWeekCopy: false,
            sevenWeekCopy: false,
            eightWeekCopy: false,
        }
    }

    UIDtoName = (UID) => {
        if (this.props.users[UID]) {
            return this.props.users[UID].firstName + ' ' + this.props.users[UID].lastName
        } else {
            return "Loading..."
        }
    }

    copyEmails = (UIDs, weekNum) => {
        var emailList = [];
        UIDs.map(ID => {
            return emailList.push(this.props.users[ID].email);
        })
        console.log("emails", emailList);
        navigator.clipboard.writeText(emailList)

        this.setState({oneWeekCopy: false});
        this.setState({twoWeekCopy: false});
        this.setState({threeWeekCopy: false});
        this.setState({fourWeekCopy: false});
        this.setState({fiveWeekCopy: false});
        this.setState({sixWeekCopy: false});
        this.setState({sevenWeekCopy: false});
        this.setState({eightWeekCopy: false});

        switch (weekNum) {
            case 1: this.setState({oneWeekCopy: true}); break;
            case 2: this.setState({twoWeekCopy: true}); break;
            case 3: this.setState({threeWeekCopy: true}); break;
            case 4: this.setState({fourWeekCopy: true}); break;
            case 5: this.setState({fiveWeekCopy: true}); break;
            case 6: this.setState({sixWeekCopy: true}); break;
            case 7: this.setState({sevenWeekCopy: true}); break;
            case 8: this.setState({eightWeekCopy: true}); break;
            default: break;
        }
    }

    sendThreeWeekEmail = (UIDs) => {
        // TEST EMAIL USING MICHAEL@MURPHYMCKAY.COM
        // UIDs = ['koyylz6epbNpz7tCltFvjXR4Cfy1'];
        UIDs.forEach(id => {
            const templateParams = {
                to_name: this.props.users[id].firstName,
                to_email: this.props.users[id].email,
            };

            emailjs.send('scheduler@murphymckay', 'template_ne63nmm', templateParams, "user_UgmdOeeH6OCjwrAqPoGpi")
                .then((result) => {
                    this.setState({threeWeekEmailSent: true})
                    this.setState({threeWeekEmailFailed: false})
                    this.props.updateAdminTimestamp("lastThreeWeeksEmail", moment().format("YYYY-MM-DD HH:mm"))
                }, (error) => {
                this.setState({emailErrorText: error.text})
                this.setState({threeWeekEmailSent: false})
                this.setState({threeWeekEmailFailed: true})
            });
            console.log("Sent to", templateParams.to_email)
        })
    }

    sendFourWeekEmail = (UIDs) => {
        UIDs.forEach(id => {
            const templateParams = {
                to_name: this.props.users[id].firstName,
                to_email: this.props.users[id].email,
            };

            emailjs.send('scheduler@murphymckay', 'template_719joq8', templateParams, "user_UgmdOeeH6OCjwrAqPoGpi")
            .then((result) => {
                this.setState({fourWeekEmailSent: true})
                this.setState({fourWeekEmailFailed: false})
                this.props.updateAdminTimestamp("lastFourWeeksEmail", moment().format("YYYY-MM-DD HH:mm"))
            }, (error) => {
            this.setState({emailErrorText: error.text})
            this.setState({fourWeekEmailSent: false})
            this.setState({fourWeekEmailFailed: true})
        });
        console.log("Sent to", templateParams.to_email)
        })
    }

    sendFiveWeekEmail = (UIDs) => {
        UIDs.forEach(id => {
            const templateParams = {
                to_name: this.props.users[id].firstName,
                to_email: this.props.users[id].email,
            };

            emailjs.send('scheduler@murphymckay', 'template_a8sdfk', templateParams, "user_UgmdOeeH6OCjwrAqPoGpi")
            .then((result) => {
                this.setState({fiveWeekEmailSent: true})
                this.setState({fiveWeekEmailFailed: false})
                this.props.updateAdminTimestamp("lastFiveWeeksEmail", moment().format("YYYY-MM-DD HH:mm"))
            }, (error) => {
            this.setState({emailErrorText: error.text})
            this.setState({fiveWeekEmailSent: false})
            this.setState({fiveWeekEmailFailed: true})
        });
        console.log("Sent to", templateParams.to_email)
        })
    }

    sendSixWeekEmail = (UIDs) => {
        UIDs.forEach(id => {
            const templateParams = {
                to_name: this.props.users[id].firstName,
                to_email: this.props.users[id].email,
            };

            emailjs.send('scheduler@murphymckay', 'template_q47e0hl', templateParams, "user_UgmdOeeH6OCjwrAqPoGpi")
            .then((result) => {
                this.setState({sixWeekEmailSent: true})
                this.setState({sixWeekEmailFailed: false})
                this.props.updateAdminTimestamp("lastSixWeeksEmail", moment().format("YYYY-MM-DD HH:mm"))
            }, (error) => {
            this.setState({emailErrorText: error.text})
            this.setState({sixWeekEmailSent: false})
            this.setState({sixWeekEmailFailed: true})
        });
        console.log("Sent to", templateParams.to_email)
        })
    }

    sendSevenWeekEmail = (UIDs) => {
        UIDs.forEach(id => {
            const templateParams = {
                to_name: this.props.users[id].firstName,
                to_email: this.props.users[id].email,
            };

            emailjs.send('scheduler@murphymckay', 'template_olu701i', templateParams, "user_UgmdOeeH6OCjwrAqPoGpi")
            .then((result) => {
                this.setState({sevenWeekEmailSent: true})
                this.setState({sevenEmailFailed: false})
                this.props.updateAdminTimestamp("lastSevenWeeksEmail", moment().format("YYYY-MM-DD HH:mm"))
            }, (error) => {
            this.setState({emailErrorText: error.text})
            this.setState({sevenWeekEmailSent: false})
            this.setState({sevenWeekEmailFailed: true})
        });
        console.log("Sent to", templateParams.to_email)
        })
    }

    render() {
        const { administrative, users } = this.props

        if (users) {
            // Find number of check-ins
            var maxCheckins = 0;
            Object.values(users).forEach(user => {
                if (user.checkins) {
                    if (Object.keys(user.checkins).length > maxCheckins) {
                        maxCheckins = Object.keys(user.checkins).length
                    }
                }
            })

            var thisMonday = moment().subtract(moment().day() - 1, 'days').format('M-D-YY')
            var oneMonday = moment().subtract(moment().day() - 1 + (1 * 7), 'days').format('M-D-YY')
            var twoMondays = moment().subtract(moment().day() - 1 + (2 * 7), 'days').format('M-D-YY')
            var threeMondays = moment().subtract(moment().day() - 1 + (3 * 7), 'days').format('M-D-YY')
            var fourMondays = moment().subtract(moment().day() - 1 + (4 * 7), 'days').format('M-D-YY')
            var fiveMondays = moment().subtract(moment().day() - 1 + (5 * 7), 'days').format('M-D-YY')
            var sixMondays = moment().subtract(moment().day() - 1 + (6 * 7), 'days').format('M-D-YY')
            var sevenMondays = moment().subtract(moment().day() - 1 + (7 * 7), 'days').format('M-D-YY')
            var eightMondays = moment().subtract(moment().day() - 1 + (8 * 7), 'days').format('M-D-YY')

            var oneWeekUsers = []
            var twoWeekUsers = []
            var threeWeekUsers = []
            var fourWeekUsers = []
            var fiveWeekUsers = []
            var sixWeekUsers = []
            var sevenWeekUsers = []
            var eightWeekUsers = []

            var oneWeekUsersText = []
            var twoWeekUsersText = []
            var threeWeekUsersText = []
            var fourWeekUsersText = []
            var fiveWeekUsersText = []
            var sixWeekUsersText = []
            var sevenWeekUsersText = []
            var eightWeekUsersText = []
        

            Object.entries(users).forEach(entry => {
                var id = entry[0]
                var user = entry[1]
                
                if (user.role === "tech") {
                    if (user.checkins) {
                        if (!user.checkins[thisMonday]) {
                            if (!user.checkins[eightMondays] && !user.checkins[sevenMondays] && !user.checkins[sixMondays] && !user.checkins[fiveMondays] && !user.checkins[fourMondays] && !user.checkins[threeMondays] && !user.checkins[twoMondays] && !user.checkins[oneMonday]) {
                                eightWeekUsersText.push(user.firstName + " " + user.lastName);
                                return eightWeekUsers.push(id)
                            } else if (!user.checkins[sevenMondays] && !user.checkins[sixMondays] && !user.checkins[fiveMondays] && !user.checkins[fourMondays] && !user.checkins[threeMondays] && !user.checkins[twoMondays] && !user.checkins[oneMonday]) {
                                sevenWeekUsersText.push(user.firstName + " " + user.lastName);
                                return sevenWeekUsers.push(id)
                            } else if (!user.checkins[sixMondays] && !user.checkins[fiveMondays] && !user.checkins[fourMondays] && !user.checkins[threeMondays] && !user.checkins[twoMondays] && !user.checkins[oneMonday]) {
                                sixWeekUsersText.push(user.firstName + " " + user.lastName);
                                return sixWeekUsers.push(id)
                            } else if (!user.checkins[fiveMondays] && !user.checkins[fourMondays] && !user.checkins[threeMondays] && !user.checkins[twoMondays] && !user.checkins[oneMonday]) {
                                fiveWeekUsersText.push(user.firstName + " " + user.lastName);
                                return fiveWeekUsers.push(id)
                            } else if (!user.checkins[fourMondays] && !user.checkins[threeMondays] && !user.checkins[twoMondays] && !user.checkins[oneMonday]) {
                                fourWeekUsersText.push(user.firstName + " " + user.lastName);
                                return fourWeekUsers.push(id)
                            } else if (!user.checkins[threeMondays] && !user.checkins[twoMondays] && !user.checkins[oneMonday]) {
                                threeWeekUsersText.push(user.firstName + " " + user.lastName);
                                return threeWeekUsers.push(id)
                            } else if (!user.checkins[twoMondays] && !user.checkins[oneMonday]) {
                                twoWeekUsersText.push(user.firstName + " " + user.lastName);
                                return twoWeekUsers.push(id)
                            } else if (!user.checkins[oneMonday]) {
                                oneWeekUsersText.push(user.firstName + " " + user.lastName);
                                return oneWeekUsers.push(id)
                            }
                        }
                    }
                }
            })

            function lastNameSort(a, b) {
                var splitA = a.split(" ");
                var splitB = b.split(" ");
                var lastA = splitA[splitA.length - 1];
                var lastB = splitB[splitB.length - 1];
            
                if (lastA < lastB) return -1;
                if (lastA > lastB) return 1;
                return 0;
            }

            eightWeekUsersText = eightWeekUsersText.sort(lastNameSort);
            sevenWeekUsersText = sevenWeekUsersText.sort(lastNameSort);
            sixWeekUsersText = sixWeekUsersText.sort(lastNameSort);
            fiveWeekUsersText = fiveWeekUsersText.sort(lastNameSort);
            fourWeekUsersText = fourWeekUsersText.sort(lastNameSort);
            threeWeekUsersText = threeWeekUsersText.sort(lastNameSort);
            twoWeekUsersText = twoWeekUsersText.sort(lastNameSort);
            oneWeekUsersText = oneWeekUsersText.sort(lastNameSort);
        }

        if (users && administrative) {
            return (
                <Container>
                    <a href="/manage" className="previous" style={{marginTop: 10}} >&laquo; Return</a>
                    <Row>
                        <Col xs={12} lg={3}>
                            <div className="card z-depth-2 project-summary">
                                <div className="card-content grey-text text-darken-3">
                                    <span className="card-title"> 1 Week </span>
                                    
                                    {oneWeekUsersText.map(name => {
                                        return <p>{name}</p>
                                    })}

                                    <Row style={{marginBottom: 0}}>
                                        <Col>
                                            <Button style={{marginTop: 10}} onClick={() => this.copyEmails(oneWeekUsers, 1)}>
                                                Copy
                                            </Button>
                                        </Col>
                                        <Col>
                                            {this.state.oneWeekCopy && (
                                                <FaCheckCircle style={{marginLeft: 0}} className="ActionsCheckmark"/>
                                            )}   
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>
                        
                        <Col xs={12} lg={3}>
                            <div className="card z-depth-2 project-summary">
                                <div className="card-content grey-text text-darken-3">
                                    <span className="card-title"> 2 Weeks </span>

                                    {twoWeekUsersText.map(name => {
                                        return <p>{name}</p>
                                    })}

                                    <Row style={{marginBottom: 0}}>
                                        <Col>
                                            <Button style={{marginTop: 10}} onClick={() => this.copyEmails(twoWeekUsers, 2)}>
                                                Copy
                                            </Button>
                                        </Col>
                                        <Col>
                                            {this.state.twoWeekCopy && (
                                                <FaCheckCircle style={{marginLeft: 0}} className="ActionsCheckmark"/>
                                            )}   
                                        </Col>
                                    </Row> 
                                </div>
                            </div>
                        </Col>

                        <Col xs={12} lg={3}>
                            <div className="card z-depth-2 project-summary">
                                <div className="card-content grey-text text-darken-3">
                                    <span className="card-title"> 3 Weeks </span>

                                    {threeWeekUsersText.map(name => {
                                        return <p>{name}</p>
                                    })}

                                    <Row style={{marginBottom: 0}}>
                                        <Col>
                                            <Button style={{marginTop: 10}} onClick={() => this.copyEmails(threeWeekUsers, 3)}>
                                                Copy
                                            </Button>
                                        </Col>
                                        <Col>
                                            {this.state.threeWeekCopy && (
                                                <FaCheckCircle style={{marginLeft: 0}} className="ActionsCheckmark"/>
                                            )}   
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col>
                                            <Button style={{marginTop: 10}} variant="success" onClick={() => this.sendThreeWeekEmail(threeWeekUsers)}>
                                                Email
                                            </Button>
                                        </Col>
                                        <Col>
                                            {this.state.threeWeekEmailSent && (
                                                <FaCheckCircle className="ActionsCheckmarkEmail"/>
                                            )}
                                        </Col>
                                    </Row>

                                    <Row>
                                        <p className="ActionsEmailText">Last sent: {moment(administrative.lastThreeWeeksEmail).format('MMMM D YYYY, h:mm a')}</p>
                                    </Row>
                                </div>
                            </div>
                        </Col>

                        <Col xs={12} lg={3}>
                            <div className="card z-depth-2 project-summary">
                                <div className="card-content grey-text text-darken-3">
                                    <span className="card-title"> 4 Weeks </span>

                                    {fourWeekUsersText.map(name => {
                                        return <p>{name}</p>
                                    })}

                                    <Row style={{marginBottom: 0}}>
                                        <Col>
                                            <Button style={{marginTop: 10}} onClick={() => this.copyEmails(fourWeekUsers, 4)}>
                                                Copy
                                            </Button>
                                        </Col>
                                        <Col>
                                            {this.state.fourWeekCopy && (
                                                <FaCheckCircle style={{marginLeft: 0}} className="ActionsCheckmark"/>
                                            )}   
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col>
                                            <Button style={{marginTop: 10}} variant="success" onClick={() => this.sendFourWeekEmail(fourWeekUsers)}>
                                                Email
                                            </Button>
                                        </Col>
                                        <Col>
                                            {this.state.fourWeekEmailSent && (
                                                <FaCheckCircle className="ActionsCheckmarkEmail"/>
                                            )}
                                        </Col>
                                    </Row>
                                    
                                    <Row>
                                        <p className="ActionsEmailText">Last sent: {moment(administrative.lastFourWeeksEmail).format('MMMM D YYYY, h:mm a')}</p>
                                    </Row>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} lg={3}>
                            <div className="card z-depth-2 project-summary">
                                <div className="card-content grey-text text-darken-3">
                                    <span className="card-title"> 5 Weeks </span>
                                    
                                    {fiveWeekUsersText.map(name => {
                                        return <p>{name}</p>
                                    })}

                                    <Row style={{marginBottom: 0}}>
                                        <Col>
                                            <Button style={{marginTop: 10}} onClick={() => this.copyEmails(fiveWeekUsers, 5)}>
                                                Copy
                                            </Button>
                                        </Col>
                                        <Col>
                                            {this.state.fiveWeekCopy && (
                                                <FaCheckCircle style={{marginLeft: 0}} className="ActionsCheckmark"/>
                                            )}   
                                        </Col>
                                    </Row>
            
                                    <Row>
                                        <Col>
                                            <Button style={{marginTop: 10}} variant="success" onClick={() => this.sendFiveWeekEmail(fiveWeekUsers)}>
                                                Email
                                            </Button>
                                        </Col>
                                        <Col>
                                            {this.state.fiveWeekEmailSent && (
                                                <FaCheckCircle className="ActionsCheckmarkEmail"/>
                                            )}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <p className="ActionsEmailText">Last sent: {moment(administrative.lastFiveWeeksEmail).format('MMMM D YYYY, h:mm a')}</p>
                                    </Row>     
                                </div>
                            </div>
                        </Col>

                        <Col xs={12} lg={3}>
                            <div className="card z-depth-2 project-summary">
                                <div className="card-content grey-text text-darken-3">
                                    <span className="card-title"> 6 Weeks </span>
                                    
                                    {sixWeekUsersText.map(name => {
                                        return <p>{name}</p>
                                    })}

                                    <Row style={{marginBottom: 0}}>
                                        <Col>
                                            <Button style={{marginTop: 10}} onClick={() => this.copyEmails(sixWeekUsers, 6)}>
                                                Copy
                                            </Button>
                                        </Col>
                                        <Col>
                                            {this.state.sixWeekCopy && (
                                                <FaCheckCircle style={{marginLeft: 0}} className="ActionsCheckmark"/>
                                            )}
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col>
                                            <Button style={{marginTop: 10}} variant="success" onClick={() => this.sendSixWeekEmail(sixWeekUsers)}>
                                                Email
                                            </Button>
                                        </Col>
                                        <Col>
                                            {this.state.sixWeekEmailSent && (
                                                <FaCheckCircle className="ActionsCheckmarkEmail"/>
                                            )}
                                        </Col>
                                    </Row>
                                    
                                    <Row>
                                        <p className="ActionsEmailText">Last sent: {moment(administrative.lastSixWeeksEmail).format('MMMM D YYYY, h:mm a')}</p>
                                    </Row>
                                </div>
                            </div>
                        </Col>

                        <Col xs={12} lg={3}>
                            <div className="card z-depth-2 project-summary">
                                <div className="card-content grey-text text-darken-3">
                                    <span className="card-title"> 7 Weeks </span>
                                    
                                    {sevenWeekUsersText.map(name => {
                                        return <p>{name}</p>
                                    })}

                                    <Row style={{marginBottom: 0}}>
                                        <Col>
                                            <Button style={{marginTop: 10}} onClick={() => this.copyEmails(sevenWeekUsers, 7)}>
                                                Copy
                                            </Button>
                                        </Col>
                                        <Col>
                                            {this.state.sevenWeekCopy && (
                                                <FaCheckCircle style={{marginLeft: 0}} className="ActionsCheckmark"/>
                                            )}   
                                        </Col>
                                    </Row>
            
                                    <Row>
                                        <Col>
                                            <Button style={{marginTop: 10}} variant="success" onClick={() => this.sendSevenWeekEmail(sevenWeekUsers)}>
                                                Email
                                            </Button>
                                        </Col>
                                        <Col>
                                            {this.state.sevenWeekEmailSent && (
                                                <FaCheckCircle className="ActionsCheckmarkEmail"/>
                                            )}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <p className="ActionsEmailText">Last sent: {moment(administrative.lastSevenWeeksEmail).format('MMMM D YYYY, h:mm a')}</p>
                                    </Row>
                                </div>
                            </div>
                        </Col>

                        <Col xs={12} lg={3}>
                            <div className="card z-depth-2 project-summary">
                                <div className="card-content grey-text text-darken-3">
                                    <span className="card-title"> 8+ Weeks </span>
                                    
                                    {eightWeekUsersText.map(name => {
                                        return <p>{name}</p>
                                    })}

                                    <Row style={{marginBottom: 0}}>
                                        <Col>
                                            <Button style={{marginTop: 10}} onClick={() => this.copyEmails(eightWeekUsers, 8)}>
                                                Copy
                                            </Button>
                                        </Col>
                                        <Col>
                                            {this.state.eightWeekCopy && (
                                                <FaCheckCircle style={{marginLeft: 0}} className="ActionsCheckmark"/>
                                            )}   
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            )
        } else return(
            <img style={{marginTop: 20}}className="LoadingWheel" src={Loading} alt="loading..." />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        administrative: state.firestore.data.administrative,
        users: state.firestore.data.users,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateAdminTimestamp: (field, time) => dispatch(updateAdminTimestamp(field, time)),
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect(() => [
        {
            collection : "SchedulerProjects", doc: "administrative", storeAs: "administrative"
        },
        {
            collection: 'users',
        },
    ])
)(EmailCenter);