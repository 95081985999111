import React, { Component } from 'react';
import { connect } from 'react-redux';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import EditProject from './EditProject';
import ModalDeleteProject from './Modals/ModalDeleteProject';
import ModalActivityLog from './Modals/ModalActivityLog';

import { updatePhase } from '../../../../../store/actions/projectActions';

import './ProjectControls.css';

class ProjectControls extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    };

    handleCloseSignups = (refLocation) => {
        this.props.updatePhase(refLocation, "confirmed");
    };

    handleReOpenSignups = (refLocation) => {
        this.props.updatePhase(refLocation, "scheduled");
    };

    handleDelete = (refLocation) => {
        this.props.updatePhase(refLocation, "deleted");
    };

    sortActivityLog = (unsortedLog) => {
        var sortedLog = [];

        if (Object.keys(unsortedLog).length > 0) {
            for (var entry in unsortedLog) {
                sortedLog.push([entry, unsortedLog[entry]]);
            };

            sortedLog.sort(function (a, b) {
                if (a[1].time && b[1].time) {
                    if (a[1].time.seconds > b[1].time.seconds) return 1;
                    if (a[1].time.seconds < b[1].time.seconds) return -1;
                }
                return 0;
            }); 
        };
        
        return sortedLog;
    }

    render() {
        const { project, users } = this.props;

        var sortedActivityLog = {};
        if (project.activityLog) {
            sortedActivityLog = this.sortActivityLog(project.activityLog);
        };

        return(
            <>
                <h2>Project Controls</h2>

                {/* Close Submissions button */}
                {project.phase === "scheduled" && (
                    <Row>
                        <Col xs={8}>
                            <span><i>Close Project from Signups</i></span>
                        </Col>
                        
                        <Col xs={4}>
                            <Button
                                variant="success"
                                size = 'sm'
                                type="submit"
                                onClick={() => this.handleCloseSignups(project.refLocation)}
                            >
                                Close
                            </Button>
                        </Col>
                    </Row>
                )}

                {/* Re-open Submissions button*/}
                {project.phase === "confirmed" && (
                    <Row>
                        <Col xs={8}>
                            <span><i>Re-Open Project for Signups</i></span>
                        </Col>
                        
                        <Col xs={4}>
                            <Button
                                variant="warning"
                                size = 'sm'
                                type="submit"
                                onClick={() => this.handleReOpenSignups(project.refLocation)}
                            >   
                                Open
                            </Button>
                        </Col>
                    </Row>
                )}
                        
                {/* Edit Project Button */}
                {project.phase !== "finished" && (
                    <Row>
                        <Col xs={8}>
                            <span><i>Edit Project</i></span>
                        </Col>

                        <Col xs={4}>
                            <EditProject project={project} users={users}/>
                        </Col>
                    </Row>
                )}

                {/* View Activity Log Button */}
                <Row>
                    <Col xs={8}>
                        <span><i>View Activity Log</i></span>
                    </Col>

                    <Col xs={4}>
                        <ModalActivityLog activityLog={sortedActivityLog} users={users} />
                    </Col>
                </Row>
                        
                {/* DELETE button */}
                <Row>
                    <Col xs={8}>
                        <span><i>Delete Project</i></span>
                    </Col>
                    
                    <Col xs={4}>
                        <ModalDeleteProject refLocation={project.refLocation}/>
                    </Col>
                </Row>
            </>
        );
    };
};

const mapDispatchToProps = dispatch => {
    return {
        updatePhase: (refLocation, phase) => dispatch(updatePhase(refLocation, phase)),
    };
};
  
export default connect(null, mapDispatchToProps)(ProjectControls);






