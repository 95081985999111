const initState = {
    emailError: null
}

const emailReducer = (state = initState, action) => {
    switch(action.type){
        case 'ADD_EMAIL_ADDRESS':
            console.log('add email address success');
            return {
                ...state,
                commentError: null
            }
        case 'ADD_EMAIL_ADDRESS_ERROR':
            console.log('add email address error');
            return {
                ...state,
                commentError: 'Add email address failed'
            }
        case 'REMOVE_EMAIL_ADDRESS':
            console.log('remove email address success');
            return {
                ...state,
                commentError: null
            }
        case 'REMOVE_EMAIL_ADDRESS_ERROR':
            console.log('remove email address error');
            return {
                ...state,
                commentError: 'Remove email address failed'
            }
        default:
            return state;
    }
}

export default emailReducer;