import React from 'react';
import { connect } from 'react-redux'

import Modal from 'react-modal';
import Button from 'react-bootstrap/Button'

import { updateWriteupStatus } from '../../../../../store/actions/userActions';
 
const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)'
  },
  overlay                 : {zIndex: 1000}
};

function ModalDeleteWriteup(props){
  var subtitle;
  const [modalIsOpen,setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    subtitle.style.color = '#f00';
  }

  function closeModal(){
    setIsOpen(false);
  }
  
  function deleteProject(userID, writeupID) {
    props.updateWriteupStatus(userID, writeupID, "Deleted");
    closeModal();
  }

  const { userID, writeupID } = props;

  return (
    <>
      {/* Button is visible at all times, clicking it will open the modal */}
      <Button variant="danger" onClick={openModal}> Delete </Button>

      {/* Modal JSX */}
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        ariaHideApp={false}
        contentLabel="Warning Modal"
      >
        <h2 ref={_subtitle => (subtitle = _subtitle)}> {`Delete Writeup`} </h2>

        <p> {"Are you sure you want to delete this writeup?"} </p>
    
        <Button variant="primary" size="sm" onClick={closeModal} style={{marginRight: 10}}> Cancel </Button>
        <Button variant="danger" size="sm" onClick={() => deleteProject(userID, writeupID)}> Delete </Button>
      </Modal>
    </>
  );
}

const mapDispatchToProps = dispatch => {
  return {
    updateWriteupStatus: (userID, writeupID, newStatus) => dispatch(updateWriteupStatus(userID, writeupID, newStatus)),
  }
}
 
export default connect(null, mapDispatchToProps)(ModalDeleteWriteup);