import React from 'react';

import Card from 'react-bootstrap/Card';

import '../Dashboard.css';

const DeactivatedNotice = () => {
    return (
        <Card style={{width: "50%", margin: "auto", marginTop: 50}}>
            <Card.Body>
                <Card.Title>Awaiting Account Setup</Card.Title>
                <Card.Text>
                    {"Your account must be configured by a system administrator. One has been notified and will take action soon."}
                </Card.Text>
            </Card.Body>
        </Card>
    )
}

export default DeactivatedNotice;
