import React from 'react'
import { GoogleSpreadsheet } from "google-spreadsheet";
import Button from 'react-bootstrap/Button';
import moment from 'moment';
import Table from 'react-bootstrap/Table';

const SPREADSHEET_ID = '1MkrNL98f98m6agP8yBRseqY49DXKeqySU9ycYGgliE8';

const doc = new GoogleSpreadsheet(SPREADSHEET_ID);




const resetSheet = async () => {
    await authenticateCredentialsAndLoadDoc();

    try {
        // Clear all sheets except the first

        for (let step = 1; step < doc.sheetCount; step++) {
            var sheet = await doc.sheetsByIndex[step];
            sheet.delete()
        }

        const statSheet = Object.values(doc.sheetsByIndex)[0];
        var statRows = await statSheet.getRows();

        while (Object.values(statRows).length) {
            for (const row in statRows) {
                await statRows[row].del();
            }
            statRows = await statSheet.getRows();
        }

    } catch (e) {
      console.error('Error: ', e);
    }
};

const fillSheet = async (uniqueUsers, users, reports, startDate, endDate) => {

    await authenticateCredentialsAndLoadDoc();

    try {
        // Set the title
        await doc.updateProperties({ title: `Expense Report ${startDate} to ${endDate}` });

        uniqueUsers.forEach(async(userID) => {

            // Create new sheets, one for each tech
            const newSheet = await doc.addSheet(
                {title: users[userID].firstName + ' ' + users[userID].lastName,
                headerValues: ['Date', 'Description', 'Project_Site', 'Amount', 'URL']}
            )

            // Fill all data, addRows is a batch command
            var reportArray = []
            reports.forEach(async(report_array) => {
                var report = report_array[1];
                if (report.userID === userID) {
                    const date = moment(report.projectDate).format("MM/DD/YY");
                    reportArray.push({Date: date, Description: report.type, Project_Site: report.client, Amount: `$${report.totalCost}`, URL: report.pictureURL});
                }
            })
            newSheet.addRows(reportArray);
            await newSheet.saveUpdatedCells();

            /* Format all data:
            *   Column C must have WRAP wrapStrategy
            *   Column D must have currency format and left align
            *   Column E must have CLIP wrapaStrategy
            */
            Object.values(doc._rawSheets).forEach(async(rawSheet, index) => {
                // Skip title/stats page
                if (index !== 0) {
                    const sheet = doc.sheetsByIndex[index];
                    const rows = await sheet.getRows();   
                    await sheet.loadCells(`C1:E${rows.length + 1}`);
                    
                    Object.values(rows).forEach(async(row) => {
                        var cellC = await sheet.getCellByA1(`C${row.rowNumber}`);
                        cellC.wrapStrategy = 'WRAP';
                        var cellD = await sheet.getCellByA1(`D${row.rowNumber}`);
                        cellD.numberFormat = {type: "currency"};
                        cellD.horizontalAlignment = 'LEFT';
                        var cellE = await sheet.getCellByA1(`E${row.rowNumber}`);
                        cellE.wrapStrategy = 'CLIP';
                    })

                    await newSheet.saveUpdatedCells();
                }
            })
        })
    } catch (e) {
      console.error('Error: ', e);
    }
};

const addFooters = async () => {
    // THIS WILL BREAK IF DOC IS REFORMATTED
    await authenticateCredentialsAndLoadDoc();

    try {
        var summaryCurrentRow = 2;
        for (const sheet of Object.values(doc.sheetsByIndex)) {
            // Skip title/stats page
            console.log("sheet", sheet)
            if (sheet.index !== 0) {
                const rows = await sheet.getRows();
                await sheet.addRow({Date: sheet.title});

                await sheet.loadCells(`D1:D${rows.length + 2}`);

                const totalCostCell = sheet.getCellByA1(`D${rows.length + 2}`);
                const totalValue = `=SUM(D2:D${rows.length + 1})`;
                totalCostCell.formula = totalValue;
                totalCostCell.textFormat = { bold: true };
                const sumTitle = sheet.title;
                const sumRows = rows.length;

                await sheet.saveUpdatedCells();


                const summarySheet = Object.values(doc.sheetsByIndex)[0];
                await summarySheet.getRows();
                await summarySheet.loadCells(`A2:D50`);

                var summaryNameCell = summarySheet.getCellByA1(`A${summaryCurrentRow}`);
                var summaryReportNumCell = summarySheet.getCellByA1(`B${summaryCurrentRow}`);
                var summaryValueCell = summarySheet.getCellByA1(`C${summaryCurrentRow}`);

                summaryNameCell.value = sumTitle;
                summaryReportNumCell.value = sumRows;
                summaryReportNumCell.horizontalAlignment = 'CENTER';
                summaryValueCell.value = totalCostCell.formattedValue;
                summaryValueCell.horizontalAlignment = 'CENTER';

                summaryCurrentRow++;
                await summarySheet.saveUpdatedCells();
            }
        }
        
    } catch (e) {
      console.error('Error: ', e);
    }
};

const copyCSV = (textToCopy) => {
    navigator.clipboard.writeText(textToCopy)
};

const GoogleSheetsGenerator = (props) => {
    const { reports, startDate, endDate, uniqueUsers, users } = props;

    var csv_string = "";

    // Sort reports first by name, then by date
    var sortedReports = []
    for (var report in reports) {
        sortedReports.push([report, reports[report]]);
    }
    sortedReports.sort(function (a, b) {
        if (users[a[1].userID].lastName === users[b[1].userID].lastName) {
            if (moment(a[1].projectDate).isAfter(moment(b[1].projectDate))) return 1
            if (moment(a[1].projectDate).isBefore(moment(b[1].projectDate))) return -1
            return 0;
        } else {
            if (users[a[1].userID].lastName > users[b[1].userID].lastName) return 2
            if (users[a[1].userID].lastName < users[b[1].userID].lastName) return -2
            return 0;
        }
    });

    return(
        <div>
            <div>
                <Button onClick={() => resetSheet()}>Reset Sheets</Button>
                <Button onClick={() => fillSheet(uniqueUsers, users, sortedReports, startDate, endDate)}>Fill Sheets</Button>
                <Button onClick={() => addFooters()}>Footers</Button>
                <Button variant="warning" style={{marginLeft: 30}} onClick={() => copyCSV(csv_string)}> CSV to Clipboard </Button>
            </div>

            <div>
                <Table striped bordered hover style={{marginTop: 20}}>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Date</th>
                            <th>Description</th>
                            <th>Project_Site</th>
                            <th>Amount</th>
                            <th>URL</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.values(sortedReports).map(entry => {

                            const report = entry[1]
                            
                            csv_string += users[report.userID].firstName + " " + users[report.userID].lastName + "," +
                                            moment(report.projectDate).format("MM/DD/YY") + "," +
                                            report.type + "," +
                                            report.client + "," +
                                            report.totalCost + "," +
                                            report.pictureURL + " \n"
                            
                            return(
                                <tr>
                                    <td>{users[report.userID].firstName + " " + users[report.userID].lastName}</td>
                                    <td>{moment(report.projectDate).format("MM/DD/YY")}</td>
                                    <td>{report.type}</td>
                                    <td>{report.client}</td>
                                    <td>{report.totalCost}</td>
                                    <td>{report.pictureURL}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            </div>
        </div>

    )
}
export default GoogleSheetsGenerator;



// Helper Functions //
const authenticateCredentialsAndLoadDoc = async () => {
    try {
        await doc.useServiceAccountAuth({
            client_email: 'react-to-google-sheets@mmatablet-11c34.iam.gserviceaccount.com',
            private_key: '-----BEGIN PRIVATE KEY-----\nMIIEvgIBADANBgkqhkiG9w0BAQEFAASCBKgwggSkAgEAAoIBAQDHEWAOEpr2c7S3\nbtXcaPQtGml4V7K0pwQG+ksAu08X90kNXECPU//MJ1FQP2EUb0KIv22m7VwRPxJP\nYmtoA/eKcWWZfZNjoJDPpadXvTAucS9AQtAK+VGhwAZ7C/RiDXGNxGNMXPpHivLc\ncnDPDHzcLGSTUnnVUvjVhaTmMfl8nTt6fcrW8oexicjC26X3zfySuKfrKVmv1yZs\n6WRRiggfblaR3xf/mQ8Eknxq6mTxx2qjeljQFPeek1SsU3Jhj11z05+4HViQoutu\nj+fqsLnRAPRps4PhBV/TlKWU9/muCP0Qth0EF767fpiCPFdOHrpY5dwpFYqLD23J\n5pXraCCbAgMBAAECggEARgAcpGYzesV+P2CosGxxrKiUfP5tt2vhlSiPlk7Hhdht\nplaR1zTFBv+ubscStrJXFaom/2OJFAF3SVYFCdsXQWYVwY8wdICiW3pXaZl752Di\nqji0Wp+IbpYNUViHU7GBhEYSWuh5FA5Oz2kotQ4hm/S6WznN4M9f9AmeTlo1bnVn\nD3JI556e9+C/741/8vqKTW5mxQtKIL25n5XnkqpdsrV1msAEAcPhHwIxE8PxdAyX\nOBcd2gx6xQdUF6hOUNdIFsczmrqHuVM7NCc4ACfmpqUd+smQcsPg0TdrXrV+ctpz\nE4OS/aoyj8N/PdXd70hssluk16vjdBkcGtj8pDfB8QKBgQDjTqCRv7vOqmJWx8uc\nNn871vbLjVQ1ChGCPdV/cJAq0/OtST7h567bO03AfewhA1/gKRSu+PSiDf7e1EWb\n4XcEfe1b7KeiH056ybWPr5jRYv9MjuL+pZ85DWsDP59Ruh22Ch3Y2fIlNeFyOmT1\nPlBPy1XdF4T4PJOBLcBvtZFiawKBgQDgMjQIHr65AAw8tOyvdz352Q8ic035N/uz\nwSdVBJcmj1KDBhn6uJxwVBwSE/BzFjYMvUy1fFgjvNiWUGu/xVVC8s7fKJBZ7XCJ\n3fOFMCtYJaBWsRzmS0k6y8foD0xB3LnI1BmAhRFJDXyAcFHBoyOt7wp+H6inPC03\n9Bw/BjqmkQKBgQCOXA8aWR+xZmqieQ0fL85t1srIXyIu+bhPfpR49TGFcSs7CxWT\n/Ds52KFwfizl3CwuwuvOTeNAn01Rl1ZlM0cMDCYdHVE7qynGow/QBqTJ9pt4AkFO\nRMI8xBeSeEU6tTqoaRuaVZ1fCqjAlqvmIHnCD9Id3v4LTjz/eXz9OZTtMwKBgQDN\nqH/eawWdgxO2pGjx1Lp/dMPTYF5InThBfo/sB1f4QUT5eCFgwvSOfjl5w9ywVdGj\nAgtZ+ASKU/OCf6c3tXcvALkQMI+qTosLjRSUOsmoBGv+2l2eS7WTP9fTYiUbzbSH\nKzlxkZ93me15jI8Sx+o05bCEXdpB/oukZkN69OXcYQKBgAwYenG+zQG2gDrFspFS\nClAop6JKv2ohHdRQ9VYwhhTuYD1oV2ErecRc3WvkVqEiDK0yxGG5LrlAQyg6CbSR\nq4L9oVfV+tjhIsh8nXwXH9/tWEi/pnCR+VZZD647A8EN3IFZAiZbsCfQgNKup4x2\n6O/0tKScq4Yh8FHmUa5PGLPE\n-----END PRIVATE KEY-----\n'
        });

        await doc.loadInfo();
    } catch (e) {
        console.error('Authentication Error: ', e);
    }
}