import React from 'react';

import Modal from 'react-modal';
import Button from 'react-bootstrap/Button';

import moment from 'moment';
 
const customStyles = {
    content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)',
        maxHeight: "88vh",
        overflowY: "auto",
    },
    overlay                 : {zIndex: 1000}
};
 
// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
//Modal.setAppElement('#yourAppElement')
 
function ModalActivityLog(props){
    var subtitle;
    const [modalIsOpen,setIsOpen] = React.useState(false);

    function openModal() {
        setIsOpen(true);
    };
 
    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        subtitle.style.color = '#f00';
    };
 
    function closeModal(){
        setIsOpen(false);
    };

    function firestoreTimestampToMoment(timestamp){
        if (!timestamp) {
            return null;
        }

        const jsDate = timestamp.toDate();
        return moment(jsDate).format('MMMM Do YYYY, h:mm:ss a');
    };

    function UIDtoName(userID){
        if (props.users[userID]) {
            return (`${props.users[userID].firstName} ${props.users[userID].lastName}`);
        } else {
            return "Loading user..."
        }
    };

    function LogToString(logItem){
        const timestamp = firestoreTimestampToMoment(logItem.time);
        const action = logItem.action;
        const author = UIDtoName(logItem.authorID);
        const subject = UIDtoName(logItem.subjectID);
        const userRole = logItem.userRole ? logItem.userRole : '';
        const userRoleFormatted = logItem.userRole ? `(${logItem.userRole.charAt(0).toUpperCase()}${logItem.userRole.slice(1)})` : '';

        switch (action) {
            case "tech signup":
            case "lead signup":
            case "supervisor signup":
            case "admin signup":
                return (<p>{`${timestamp}: `} <b>{`${author} ${userRoleFormatted}`}</b> {`signed up`}</p>);

            case "tech undo signup":
            case "lead undo signup":
            case "supervisor undo signup":
            case "admin undo signup":
                return(<p> {`${timestamp}: `} <b>{`${author} ${userRoleFormatted}`}</b> {`undid their signup`} </p>);

            case "tech confirm":
            case "lead confirm":
            case "supervisor confirm":
                return(<p> {`${timestamp}: `} <b>{`${author} ${userRoleFormatted}`}</b> {`confirmed the project`} </p>);

            case "tech deny":
            case "lead deny":
            case "supervisor deny":
                return (<p> {`${timestamp}: `} <b>{`${author} ${userRoleFormatted}`}</b> {`denied the project`} </p>);

            case "tech drop off":
            case "lead drop off":
            case "supervisor drop off":
                return (<p>{`${timestamp}: `} <b>{`${author} ${userRoleFormatted}`}</b> {`dropped off`} </p>);

            case "admin manual add tech":
            case "admin manual add lead":
            case "admin manual add supervisor":
                return (<p> {`${timestamp}: `} <b>{`${author}`}</b> {`manually added`} <b>{`${subject}`}</b> {`as a ${userRole}`} </p>);

            case "admin add tech":
            case "admin add lead":
            case "admin add supervisor":
                return (<p> {`${timestamp}: `} <b>{`${author}`}</b> {`added`} <b>{`${subject}`}</b> {`as a ${userRole}`} </p>);

            case "admin remove tech":
            case "admin remove lead":
            case "admin remove supervisor":
                return (<p> {`${timestamp}: `} <b>{`${author}`}</b> {`removed`} <b>{`${subject}`}</b> {`as a ${userRole}`} </p>);

            case "admin move from proposed to confirmed":
                return (<p> {`${timestamp}: `} <b>{`${author}`}</b> {`moved`} <b>{`${subject}`}</b> {`from Proposed to Confirmed`} </p>);

            case "admin email tech no response":
            case "admin email lead no response":
            case "admin email supervisor no response":
                return (<p> {`${timestamp}: `} <b>{`${author}`}</b> {`emailed`} <b>{`${subject}`}</b> {`to confirm the project`} </p>);

            default:
                return (<p> <b>Error: Unknown Action</b> </p>);
        };
    };

    const { activityLog } = props;

    return (
        <>
            {/* Button is visible at all times, clicking it will open the modal */}
            <Button onClick={openModal}> Open </Button>

            {/* Modal JSX */}
            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Information Modal"
            >
                <h2 ref={_subtitle => (subtitle = _subtitle)}>
                    {"Activity Log"}
                </h2>
                
                {Object.values(activityLog).map(entry => {
                    const key = entry[0];
                    const logItem = entry[1];

                    return(
                        <div key={key}>
                            {LogToString(logItem)}
                        </div>
                    );
                })}
            
                <Button variant="primary" size="sm" onClick={closeModal}> Close </Button>
            </Modal>
        </>
    );
};

export default ModalActivityLog;