import React, { Component } from 'react'
import { compose } from 'redux';
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase';
import { Redirect } from 'react-router-dom'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import update from 'immutability-helper';

import ProjectCalendarMobile from './ProjectCalendarMobile';
import Actions from './Actions';
import CreateProject from './CreateProject';
import ConfirmProject from './ConfirmProject';
import ProjectView from './projectView/ProjectView';

import './ProjectCalendar.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';

const localizer = momentLocalizer(moment)

class ProjectCalendar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeProject: {}
        }
    }

    // TODO: Convert Javascript Date objects to Moment objects
    convertFirestoreTimestampToJSDate = (projects) => {
        // Convert project.startTime/endTime from Firebase timestamp to Date object
        var projectsArray = Object.values(projects);
        var dateProjectsArray = [];
        var tempObject;

        projectsArray.filter(Boolean).forEach((object) => {
            tempObject = update(object,
                {startTime: {$apply: function(x) {return new Date(x)}}}
            );
            tempObject = update(tempObject,
                {endTime: {$apply: function(x) {return new Date(x)}}}
            );
            if (tempObject.phase !== "deleted") {    // Don't show "deleted" calendar items
                dateProjectsArray.push(tempObject);
            }
        });

        return dateProjectsArray;
    }

    eventStyleGetter = (event) => {
        var backgroundColor = 'black';
        var color = 'white';

        switch (event.phase) {
            case 'proposed':
                backgroundColor = 'lightgrey';
                color = 'black';
                break;

            case 'scheduled':
                backgroundColor = 'black';
                break;

            case 'finished':
                backgroundColor = '#6094bc';
                break;

            case 'test':
                backgroundColor = 'plum';
                break;

            case 'confirmed':
                backgroundColor = 'green';

                if (Object.keys(event.confirmedTechList).length === 0) {
                    backgroundColor = '#004b00';
                } else {
                    Object.values(event.confirmedTechList).every(confirmation => {
                        if (confirmation === "dropped off - unseen" || confirmation === "denied - unseen") {
                            backgroundColor = 'red';
                            return false;
                        } else if (confirmation === "no response") {
                            backgroundColor = 'goldenrod';
                        }
                        return true;
                    })
                }
                break;

            default:
                backgroundColor = 'purple';
        }
        
        var style = {
            backgroundColor: backgroundColor,
            borderRadius: '0px',
            opacity: 0.8,
            color: color,
            border: '0px',
            display: 'block'
        };

        return {
            style: style
        };
    }

    render() {
        const { auth, projects } = this.props;
        if (!auth.uid) return <Redirect to='/signin' />
    
        if (Object.keys(projects).length) {

            const calendarEvents = this.convertFirestoreTimestampToJSDate(projects);
            const mobileCalendarEvents = Object.values(projects);

            if (window.innerWidth < 1200) {
                return <ProjectCalendarMobile projects={mobileCalendarEvents} />
            } else {
                return (
                    <Container fluid>
                        <Row style={{marginBottom: "0px"}}> 
                            <Col xs={9}>
                                <Calendar
                                    localizer={localizer}
                                    events={calendarEvents}
                                    titleAccessor='client'
                                    startAccessor="startTime"
                                    endAccessor="endTime"
                                    onSelectEvent={(event) => this.setState({activeProject: event.refLocation})} 
                                    eventPropGetter={(this.eventStyleGetter)}
                                    style={{ height: 1000 }}
                                />

								<CreateProject/>
                            
                                <Row>
                                    <Col xs={6}>
                                        <Actions projects={projects}/>
                                    </Col>
                                    
                                    {/* Projects to Publish */}
                                    <Col xs={6}>
                                        <ConfirmProject projects={projects}/>
                                    </Col>
                                </Row>
                            </Col>

                            <Col xs={3}>
                                <ProjectView auth={auth} project={projects[this.state.activeProject.id]} projects={projects}/>
                            </Col>
                        </Row>
                    </Container>
                )
            }
        } else {
            return (
                <div>
                    Loading projects...
                    <CreateProject />
                </div>
            )
        }
    }   
}

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
        projects: {
            ...state.firestore.data.MonthProjects,
            ...state.firestore.data.NextMonthProjects,
            ...state.firestore.data.LastMonthProjects,
        },
    };
};

 // Conditional code is included for the December -> January transition //
 export default compose(
    connect(mapStateToProps, null),
    firestoreConnect(() => [
        {
            collection : 'SchedulerProjects', doc: moment().subtract(1, 'M').format('MMMM') === "December" ? moment().subtract(1, 'Y').format('YYYY') : moment().format('YYYY'),
            subcollections: [{ collection : moment().subtract(1, 'M').format('MMMM') }],
            storeAs: 'LastMonthProjects'
        },
        {
            collection : 'SchedulerProjects', doc: moment().format('YYYY'),
            subcollections: [{ collection : moment().format('MMMM') }],
            storeAs: 'MonthProjects'
        },
        {
            collection : 'SchedulerProjects', doc: moment().add(1, 'M').format('MMMM') === "January" ? moment().add(1, 'Y').format('YYYY') : moment().format('YYYY'),
            subcollections: [{ collection : moment().add(1, 'M').format('MMMM') }],
            storeAs: 'NextMonthProjects'
        },
    ])
)(ProjectCalendar);