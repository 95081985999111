export const addProjectToClient = (clientID, projectPath) => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore();

        try {
            // Fetch project data from Firestore
            const projectDocRef = firestore.doc(projectPath);
            const projectDoc = await projectDocRef.get();
            if (!projectDoc.exists) {
                throw new Error(`Project with path ${projectPath} not found`);
            }
            const projectData = projectDoc.data();
            const projectClientID = projectData.client;

            // Reference to the client's document in Firestore
            const clientDocRef = firestore.collection('clients').doc(clientID);
            const clientDoc = await clientDocRef.get();
            if (!clientDoc.exists) {
                throw new Error(`Client with ID ${clientID} not found`);
            }

            // Check if projectClientID matches clientID
            if (clientID != projectClientID) {
                throw new Error(`ClientID for project (${projectClientID}) doesn't match clientID for client (${clientID})`)
            }

            // Prepare the final project data to store
            const projectInfo = {
                date: projectData.date,
                name: projectData.name,
                refLocation: projectDocRef
            };

            // Add or update the project document within the 'projects' collection
            const projectCollectionRef = clientDocRef.collection('projects');
            await projectCollectionRef.doc(projectDoc.id).set(projectInfo, { merge: true });

            dispatch({ type: 'UPDATE_PROJECTS', clientID });

        } catch (err) {
            console.error("Error updating projects:", err);
            dispatch({ type: 'UPDATE_PROJECTS_ERROR', err });
        }
    };
};

export const resetProjectsForClient = (clientID) => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore();

        try {
            // Reference to the client's document in Firestore
            const clientDocRef = firestore.collection('clients').doc(clientID);
            const clientDoc = await clientDocRef.get();
            if (!clientDoc.exists) {
                throw new Error(`Client with ID ${clientID} not found`);
            }

            // Delete the existing projects collection
            const projectCollectionRef = clientDocRef.collection('projects');
            const projectsSnapshot = await projectCollectionRef.get();
            if (!projectsSnapshot.empty) {
                const batchDelete = firestore.batch();
                projectsSnapshot.forEach(doc => {
                    batchDelete.delete(doc.ref);
                });
                await batchDelete.commit();
                console.log(`All projects within the 'projects' collection for client ${clientID} have been deleted.`);
            } else {
                console.log(`No projects found in the 'projects' collection for client ${clientID}.`);
            }

            // Add all projects with matching clientID from the /projects collection
            const batchAdd = firestore.batch();
            const years = Array.from({ length: new Date().getFullYear() - 2019 + 1 }, (v, i) => (2019 + i).toString());
            const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

            for (const year of years) {
                for (const month of months) {
                    const monthRef = firestore.collection('projects').doc(year).collection(month);
                    const projectsQuerySnapshot = await monthRef.where('client', '==', clientID).get();

                    projectsQuerySnapshot.forEach((projectDoc) => {
                        const projectData = projectDoc.data();
                        const projectID = projectDoc.id;
                        const projectPath = `projects/${year}/${month}/${projectID}`;
                        const projectDocRef = firestore.doc(projectPath);

                        // Construct the project info to add to client's projects collection
                        const projectInfo = {
                            date: projectData.date,
                            name: projectData.name,
                            refLocation: projectDocRef
                        };

                        // Add the project to the client's projects collection
                        batchAdd.set(projectCollectionRef.doc(projectID), projectInfo, { merge: true });
                    });
                }
            }

            // Commit the batch addition to Firestore
            await batchAdd.commit();
            console.log(`Projects for client ${clientID} have been reset and updated.`);

        } catch (err) {
            console.error("Error updating projects:", err);
            dispatch({ type: 'UPDATE_PROJECTS_ERROR', err });
        }
    };
};