import React, { Component } from 'react';
import ProjectGroupMobile from './ProjectGroupMobile';
import './mobile.css';

class ProjectSignUpMobile extends Component {
  render() {
    const { auth, availableProjects, profile, projects, removedEntries, userRole } = this.props;

    return (
      <div className="topMobileContainer">
        <a style={{ marginTop: 5 }} href="/" className="previous">&laquo; Return</a>
        {availableProjects.length ? availableProjects.map(project => (
          <ProjectGroupMobile
            profile={profile}
            key={project.refLocation.id}
            auth={auth}
            projects={projects}
            project={project}
            removedEntries={removedEntries}
            userRole={userRole}
          />
        )) : (
          <div className="noProjectsMessage">
            There are no projects available at this time.
          </div>
        )}
        {availableProjects.length > 0 && <a href="/" className="previous">&laquo; Return</a>}
      </div>
    );
  }
}

export default ProjectSignUpMobile;