import React from 'react';
import { connect } from 'react-redux'

import Modal from 'react-modal';
import Button from 'react-bootstrap/Button'

import { homeMessageSeen } from '../../../../store/actions/userActions'

const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    transform             : 'translate(-50%, -50%)'
  },
  overlay                 : {zIndex: 1000}
};

const customStylesMobile = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-49%',
    transform             : 'translate(-50%, -50%)'
  },
  overlay                 : {zIndex: 1000}
};
 
// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
//Modal.setAppElement('#yourAppElement')
 
function ModalBox(props){
  var subtitle;
  const [modalIsOpen,setIsOpen] = React.useState(true);
 
  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = '#f00';
  }
 
  function handleAccept(id) {
    props.homeMessageSeen(id)
    closeModal()
  }

  function closeModal(){
    setIsOpen(false);
  }

  const {id} = props;

  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={window.innerWidth < 1200 ? customStylesMobile : customStyles}
        contentLabel="Home Message"
        shouldCloseOnOverlayClick={false}
      >

        <h2 ref={_subtitle => (subtitle = _subtitle)}>Submit Expense Reports</h2>
        
        <div>While using this app on a mobile device, an "Expenses" button will now be visible at the top of the page.
          Tap it to be redirected to a page where you can submit reports for that project.
        </div>

        <Button style={{marginTop: 10}} variant="primary" size="sm" onClick={() => handleAccept(id)}>I Understand</Button>
      </Modal>
    </div>
  );
}

const mapDispatchToProps = dispatch => {
  return {
    homeMessageSeen: (id) => dispatch(homeMessageSeen(id)),
  }
}
 
export default connect(null, mapDispatchToProps)(ModalBox);