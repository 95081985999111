import React, { Component } from 'react'

import Card from 'react-bootstrap/Card';

class NotificationCenter extends Component {
    constructor(props) {
        super(props);

        this.state = {}
    }

    

    render() {

        var unresolvedReports = 0;
        // BUGGED
        /*
        Object.values(reports).filter(Boolean).forEach(report => {
            if (!report.resolved) {
                unresolvedReports += 1;
            }
        })*/

        return(
            <Card body >
                <h4 className="NotificationCenterCard">Notification Center</h4>

                {!!(unresolvedReports === 1) && (
                    <div className="card z-depth-1 project-summary">
                        <b>1 new bug report</b>
                    </div>
                )}

                {!!(unresolvedReports > 1) && (
                    <div className="card z-depth-1 project-summary">
                        <b>{unresolvedReports} new bug reports</b>
                    </div>
                )}
            </Card>
        )
    }

}

export default NotificationCenter;