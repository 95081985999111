import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase';

import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';

import moment from 'moment';

import NewWriteupForm from './NewWriteupForm';
import Writeup from './Writeup';
import Loading from '../../../assets/Loading.gif';

class TechWriteups extends Component {
  state = {};

  render() {
    const { users } = this.props;

    if (users) {
      const uid = this.props.match.params.uid;
      const userData = users[uid];

      var writeups = Object.entries(userData.writeups);
      writeups = this.sortWriteupsByDate(writeups);

      return(
        <Container fluid="lg">
          <a href="/manage" className="previous" style={{marginTop: 10}}> &laquo; Return </a>

          <Card>
            <Card.Header>
              <h2>{`Writeups for: ${userData.firstName} ${userData.lastName}`}</h2>
            </Card.Header>

            <Card.Body>
              <Row> <NewWriteupForm userID={uid} userData={userData} users={users}/> </Row>

              <Row>
                {writeups.map(entry => {
                  const writeupID = entry[0];
                  const writeupData = entry[1];
                  return <Writeup key={writeupID} userID={uid} writeupID={writeupID} writeupData={writeupData} users={users}/>
                })}
              </Row>
            </Card.Body>
          </Card>
        </Container>
      )
    } else {
      return(
        <Container>
          <img style={{marginTop: 20}}className="LoadingWheel" src={Loading} alt="loading..." />
        </Container>
      )
    }
  }



  /***** HELPER FUNCTIONS BEGIN *****/
  sortWriteupsByDate = (writeups) => {
    writeups.sort(function (a,b) {        
      if (moment(a[1].incidentDate).isAfter(b[1].incidentDate)) return 1;
      if (moment(a[1].incidentDate).isSameOrBefore(b[1].incidentDate)) return -1;
      return 0;
    })

    return writeups;
  }
  /***** HELPER FUNCTIONS END *****/
}

const mapStateToProps = (state) => {
  return {
    users: state.firestore.data.users,
  }
};

export default compose(
  connect(mapStateToProps, null),
  firestoreConnect([
    {
      collection : 'users',
    },
  ])
)(TechWriteups);