import React, { useState } from 'react';
import { connect } from 'react-redux';

import Modal from 'react-modal';
import Button from 'react-bootstrap/Button';

import { FaPlus } from 'react-icons/fa';
import { IconContext } from "react-icons";

import { addProjectToClient } from '../../../../store/actions/clientActions';

const customStyles = {
    content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)'
    },
    overlay                 : {zIndex: 1000}
};

function ModalAddProjectToClient(props){
    var subtitle;
    const [modalIsOpen, setIsOpen] = useState(false);
    const [projectURL, setProjectURL] = useState('');

    function openModal() {
        setIsOpen(true);
    };

    function afterOpenModal() {
        subtitle.style.color = '#f00';
    };

    function closeModal(){
        setIsOpen(false);
        setProjectURL('');
    };

    function addProjectFromURL(clientID, projectURL) {
        const urlParts = projectURL.split('/');
        if (urlParts.length === 7) {
            // const scheme = urlParts[0].slice(0, -1);
            // const host = urlParts[2];
            const projectPath = urlParts.slice(3).join('/');
            props.addProjectToClient(clientID, projectPath);
            closeModal();
        } else {
            alert('Invalid URL format. Please check the URL and try again.');
        }
    }
    

    const { clientID } = props;
    return (
        <>
            {/* Plus Icon is visible at all times, clicking it will open the modal */}
            <IconContext.Provider value={{ className: "global-class-name", style: { cursor: 'pointer' } }}>
                <FaPlus onClick={openModal}/>
            </IconContext.Provider>

            {/* Modal JSX */}
            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Add Project Modal"
            >
                <h2 ref={_subtitle => (subtitle = _subtitle)}>
                    {`Add New Project`}
                </h2>
                
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
                    <input
                        type="text"
                        placeholder="Project URL"
                        value={projectURL}
                        onChange={(e) => setProjectURL(e.target.value)}
                        style={{ flex: 1, marginRight: '10px' }}
                    />
                </div>
                
                <Button variant="secondary" onClick={closeModal} style={{marginRight: 10}}>Cancel</Button>
                <Button onClick={() => addProjectFromURL(clientID, projectURL)} variant="primary">Add</Button>
            </Modal>
        </>
    );
};

const mapDispatchToProps = dispatch => {
    return {
        addProjectToClient: (clientID, projectPath) => dispatch(addProjectToClient(clientID, projectPath)),
    };
};

export default connect(null, mapDispatchToProps)(ModalAddProjectToClient);
