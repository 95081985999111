import React, { useState } from 'react';
import { connect } from 'react-redux';

import Modal from 'react-modal';
import Button from 'react-bootstrap/Button';

import { FaRedo } from 'react-icons/fa';
import { IconContext } from "react-icons";

import { resetProjectsForClient } from '../../../../store/actions/clientActions';

const customStyles = {
    content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)'
    },
    overlay                 : {zIndex: 1000}
};

function ModalResetProjectsForClient(props){
    var subtitle;
    const [modalIsOpen, setIsOpen] = useState(false);

    function openModal() {
        setIsOpen(true);
    };

    function afterOpenModal() {
        subtitle.style.color = '#f00';
    };

    function closeModal(){
        setIsOpen(false);
    };

    function resetProjects(clientID) {
        props.resetProjectsForClient(clientID);
        closeModal();
    }
    

    const { clientID } = props;
    return (
        <>
            {/* Plus Icon is visible at all times, clicking it will open the modal */}
            <IconContext.Provider value={{ className: "global-class-name", style: { cursor: 'pointer' } }}>
                <FaRedo onClick={openModal}/>
            </IconContext.Provider>

            {/* Modal JSX */}
            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Warning Modal"
            >
                <h2 ref={_subtitle => (subtitle = _subtitle)}>
                    {`Reset Projects`}
                </h2>
                
                <p>{`Are you sure you want to reset all projects for the client: ${clientID}?`}</p>

                <Button variant="primary" size="sm" onClick={closeModal} style={{ marginRight: 10 }}> Cancel </Button>
                <Button variant="danger" size="sm" onClick={() => resetProjects(clientID)}>Reset</Button>
            </Modal>
        </>
    );
};

const mapDispatchToProps = dispatch => {
    return {
        resetProjectsForClient: (clientID) => dispatch(resetProjectsForClient(clientID)),
    };
};

export default connect(null, mapDispatchToProps)(ModalResetProjectsForClient);
