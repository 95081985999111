import React from 'react';

import Row from 'react-bootstrap/Row'
import Card from 'react-bootstrap/Card'

import mmaLogo from '../assets/mma-logo.png'
import './ErrorScreen.css'

const ErrorScreenMobile = (props) => {

    return(
        <div>
            <Card body className="ErrorBoxMobile">
                <Row>
                    <h3 className="Oops">Oops!</h3>
                </Row>

                <Row>
                    <h5>Looks like you ran into an unknown error! If possible, please use the bug reporting tool below to describe what you were doing before the error occurred.
                        We'll try and get things running normally as soon as possible!
                    </h5>
                </Row>

                <Row>
                    <a href="/bugreport" className="Oops"> Link to Bug Reporter </a>
                </Row> 
            </Card>

            <Row>
                <img src={mmaLogo} alt={"Murphy McKay Logo"} className="MMALogo"/>
            </Row>
        </div>
    )
}

export default ErrorScreenMobile;