import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { Link } from 'react-router-dom';

import moment from 'moment';

class ArchiveProjectList extends Component {
  render() {
    const { projects, hideDeletedProjects } = this.props;

    var sortedProjects = Object.entries(projects);

    sortedProjects.sort(function (a, b) {
      if (a[1].startTime > b[1].startTime) return 1;
      if (a[1].startTime < b[1].startTime) return -1;
      return 0;
    });

    if (hideDeletedProjects) {
      return (
        sortedProjects.map(entry => {
          const id = entry[0];
          const project = entry[1];
          const projectLink = `/s/${moment(project.startTime, "YYYY-MM-DD hh:mm").format('YYYY/MMMM')}/${project.refLocation.id}`;

          if (project.phase !== "deleted") {
            return (
              <div key={id}>
                <Link to={projectLink}>
                  {`${project.startTime} - ${project.client}`}
                </Link>
              </div>
            );
          } else {
            return (<></>);
          }
        })
      );
    } else {
      return (
        sortedProjects.map(entry => {
          const id = entry[0];
          const project = entry[1];
          const projectLink = `/s/${moment(project.startTime, "YYYY-MM-DD hh:mm").format('YYYY/MMMM')}/${project.refLocation.id}`;

          if (project.phase === "deleted") {
            return (
              <div key={id}>
                <Link to={projectLink}>
                  {`${project.startTime} - ${project.client} - DELETED`}
                </Link>
              </div>
            );
          } else {
            return (
              <div key={id}>
                <Link to={projectLink}>
                  {`${project.startTime} - ${project.client}`}
                </Link>
              </div>
            );
          }
        })
      );
    }
  }
}

const mapStateToProps = (state, props) => {
  return {
    projects: {
      ...state.firestore.data[props.month]
    }
  };
};

export default compose(
  connect(mapStateToProps, null),
  firestoreConnect((props) => {
    if (props.year) {
      return [
        {
          collection: 'SchedulerProjects', doc: props.year,
          subcollections: [{ collection: props.month }],
          storeAs: props.month
        },
      ];
    } else {
      return [
        {
          collection: 'SchedulerProjects',
          doc: moment().format('YYYY'),
          subcollections: [{ collection: moment().format('MMMM') }],
          storeAs: 'currentProjects'
        },
      ];
    }
  })
)(ArchiveProjectList);
