import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Dropdown from 'react-bootstrap/Dropdown';

import moment from 'moment';

import ProjectList from './ProjectList';

class TechHistory extends Component {
    constructor(props) {
        super(props);

        this.state = {
            year: moment().format('YYYY'),
        };
    };

    render() {
        const uid = this.props.match.params.uid;
        
        var years = [];
        const currentYear = parseInt(moment().format('YYYY'));

        var year = 2020;
        while (year <= currentYear) {
            years.push(year.toString())
            year++;
        }

        if (this.props.userData) {
            const userData = this.props.userData[uid]

            return(
                <Container>
                    <a href="/manage" className="previous" style={{marginTop: 10}}> &laquo; Return </a>

                    <Card>
                        <Card.Header>
                            <Row>
                                <Col md="auto">
                                    <h2>{`Projects from: ${userData.firstName} ${userData.lastName}`}</h2>
                                </Col>
    
                                <Dropdown>
                                    <Dropdown.Toggle variant="outline-primary" id="dropdown-basic">
                                        {this.state.year}
                                    </Dropdown.Toggle>
    
                                    <Dropdown.Menu>
                                        {years.map(year => {
                                            return (<Dropdown.Item onClick={() => this.setState(() => ({year: year}))}> {year} </Dropdown.Item>)
                                        })}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Row>
                        </Card.Header>
    
                        <Card.Body>
                            <ProjectList year={this.state.year} month={this.state.month} uid={uid}/>
                        </Card.Body>
                    </Card>
                </Container>
            )
        } else {
            return(
                <Container>
                    Loading...
                </Container>
            )
        }
    }
}

const mapStateToProps = (state) => {
    return {
        userData: state.firestore.data.users,
    };
};

export default compose(
    connect(mapStateToProps, null),
    firestoreConnect((props) => [
        {
            collection : 'users', doc : props.match.params.uid,
            storeas : 'userData'
        },
    ])
)(TechHistory);