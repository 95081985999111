import React from 'react';
import { connect } from 'react-redux'

import Modal from 'react-modal';
import Button from 'react-bootstrap/Button'

import { updatePhase } from '../../../../../../store/actions/projectActions'
 
const customStyles = {
    content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)'
    },
    overlay                 : {zIndex: 1000}
};
 
// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
//Modal.setAppElement('#yourAppElement')
 
function ModalDeleteProject(props){
    var subtitle;
    const [modalIsOpen,setIsOpen] = React.useState(false);

    function openModal() {
        setIsOpen(true);
    }
 
    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        subtitle.style.color = '#f00';
    }
 
    function closeModal(){
        setIsOpen(false);
    }
    
    function deleteProject(refLocation) {
        props.updatePhase(refLocation, "deleted");
        closeModal();
    }

    const { refLocation } = props;

    return (
        <>
            {/* Button is visible at all times, clicking it will open the modal */}
            <Button
                variant="danger"
                onClick={openModal}
            >   
                Delete
            </Button>

            {/* Modal JSX */}
            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Warning Modal"
            >
                <h2 ref={_subtitle => (subtitle = _subtitle)}>
                    {"Delete Project"}
                </h2>
                
                <p>{"Are you sure you want to delete this project? This cannot be undone easily..."}</p>
            
                <Button variant="primary" size="sm" onClick={closeModal} style={{marginRight: 10}}> Cancel </Button>
                <Button variant="danger" size="sm" onClick={() => deleteProject(refLocation)}> Delete </Button>
            </Modal>
        </>
    );
}

const mapDispatchToProps = dispatch => {
  return {
    updatePhase: (refLocation, phase) => dispatch(updatePhase(refLocation, phase)),
  }
}
 
export default connect(null, mapDispatchToProps)(ModalDeleteProject);