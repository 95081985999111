import React from 'react';
import { connect } from 'react-redux';

import Modal from 'react-modal';
import Button from 'react-bootstrap/Button';

import { FaTrash } from 'react-icons/fa';
import { IconContext } from "react-icons";

import { removeProjectFromScope } from '../../../../store/actions/userActions';

const customStyles = {
    content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)'
    },
    overlay                 : {zIndex: 1000}
};
 
// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
//Modal.setAppElement('#yourAppElement')
 
function ModalRemoveProject(props){
    var subtitle;
    const [modalIsOpen,setIsOpen] = React.useState(false);

    function openModal() {
        setIsOpen(true);
    };
 
    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        subtitle.style.color = '#f00';
    };
 
    function closeModal(){
        setIsOpen(false);
    };
    
    function removeProject(userID, projectID) {
        props.removeProjectFromScope(userID, projectID);
        closeModal();
    };

    const { userID, projectID } = props;
    return (
        <>
            {/* Trashcan Icon is visible at all times, clicking it will open the modal */}
            <IconContext.Provider style={{textAlign: 'right'}} value={{ className: "global-class-name" }}>
                <FaTrash onClick={openModal}/>
            </IconContext.Provider>



            {/* Modal JSX */}
            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Warning Modal"
            >
                <h2 ref={_subtitle => (subtitle = _subtitle)}>
                    {`Remove projectID`}
                </h2>
                
                <p>{"Are you sure you want to remove " + projectID + " from this project scope?"}</p>
            
                <Button variant="primary" size="sm" onClick={closeModal} style={{marginRight: 10}}> Cancel </Button>
                <Button variant="danger"  size="sm" onClick={() => removeProject(userID, projectID)}> {"Remove"} </Button>
            </Modal>
        </>
    );
};

const mapDispatchToProps = dispatch => {
    return {
        removeProjectFromScope: (userID, projectID) => dispatch(removeProjectFromScope(userID, projectID)),
    };
};

export default connect(null, mapDispatchToProps)(ModalRemoveProject);