import React, { Component } from 'react';
import { connect } from 'react-redux';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import { FaCheckCircle, FaRegTimesCircle } from "react-icons/fa";
import moment from 'moment';
import ImageUploader from 'react-images-upload';
import { v4 as uuidv4 } from 'uuid';

import './Expenses.css';
import { storage } from '../../../../config/fbConfig';

import BartExample from '../../../assets/BARTexample.png';

import { addBartExpense } from '../../../../store/actions/userActions';

class BartReport extends Component {
    constructor(props) {
        super(props);

        this.state = {
            toOrigin: "",
            toDestination: "",
            leaveOrigin: "",
            leaveDestination: "",
            toCost: 0,
            leaveCost: 0,
            bartPicture: null,
            missingPictureError: false,

            noSubmission: true,
            submitLoading: false,
            submitSuccess: false,
            submitFailure: false,
        }

        this.onDropBart = this.onDropBart.bind(this);
    }

    onDropBart (picture) {
        this.setState({bartPicture: picture})
    }

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    handleSubmit = (refLocation, startTime, client) => {

        if (this.state.bartPicture === null) {
            this.setState({missingPictureError: true});
            return;
        }

        // Upload the image to Firebase Storage
        const year = moment().format('YYYY');
        const month = moment().format('MMMM');
        const image = this.state.bartPicture[0];
        const imageName = uuidv4()

        this.setState({noSubmission: false})
        this.setState({submitFailure: false})
        this.setState({submitSuccess: false})
        this.setState({submitLoading: true})

        const uploadTask = storage.ref(`expenses/${year}/${month}/${imageName}`).put(image);
        uploadTask.on('state_changed',
        (snapshot) => {
            // progress function...
            //const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
            //this.setState({progress});
        },
        (error) => {
            // error function...
            this.setState({submitLoading: false})
            this.setState({submitFailure: true})
            console.log(error);
        }, 
        () => {
            // complete function...
            storage.ref(`expenses/${year}/${month}`).child(imageName).getDownloadURL().then(url => {
                // Once the URL is given back, proceed with creating the expense report in Firestore
                console.log(url);
                this.setState({submitLoading: false})
                this.setState({submitSuccess: true})
                this.props.addBartExpense(
                    refLocation, this.props.auth.uid,
                    this.state.toOrigin, this.state.toDestination,
                    this.state.leaveOrigin, this.state.leaveDestination,
                    Number(this.state.toCost), Number(this.state.leaveCost),
                    url, startTime, client
                )
            })
        });
        //this.setState({reportContent: ""})
    }

    render() {
        const { project } = this.props;

        return(
            <div className="card z-depth-3 project-summary cardMargins">
                <Container>
                    <h1 style={{textAlign: "center"}}>Add BART</h1>

                    <Row className="removeRowMargin">
                        <h4> To the Project: </h4>
                    </Row>

                    <Row className="removeRowMargin">
                        <Form.Group as={Col} controlId="toOrigin">
                            <Form.Label> Origin Station </Form.Label>
                            
                            <Form.Control
                                type="text"
                                //value={this.state.reportContent}
                                onChange={this.handleChange}
                                required
                            />
                        </Form.Group>
                    </Row>

                    <Row className="removeRowMargin">
                        <Form.Group as={Col} controlId="toDestination">
                            <Form.Label>
                                Destination Station
                            </Form.Label>
                            
                            <Form.Control
                                type="text"
                                onChange={this.handleChange}
                                required
                            />
                        </Form.Group>
                    </Row>

                    <Row className="removeRowMargin">
                        <Form.Group as={Col} controlId="toCost">
                            <Form.Label>
                                Cost ($)
                            </Form.Label>
                            
                            <Form.Control
                                type="number"
                                onChange={this.handleChange}
                                required
                            />
                        </Form.Group>
                    </Row>

                    <Row className="removeRowMargin">
                        <h4> Leaving the Project: </h4>
                    </Row>

                    <Row className="removeRowMargin">
                        <Form.Group as={Col} controlId="leaveOrigin">
                            <Form.Label>
                                Origin Station
                            </Form.Label>
                            
                            <Form.Control
                                type="text"
                                onChange={this.handleChange}
                                required
                            />
                        </Form.Group>
                    </Row>

                    <Row className="removeRowMargin">
                        <Form.Group as={Col} controlId="leaveDestination">
                            <Form.Label>
                                Destination Station
                            </Form.Label>
                            
                            <Form.Control
                                type="text"
                                onChange={this.handleChange}
                                required
                            />
                        </Form.Group>
                    </Row>

                    <Row className="removeRowMargin">
                        <Form.Group as={Col} controlId="leaveCost">
                            <Form.Label>
                                Cost ($)
                            </Form.Label>
                            
                            <Form.Control
                                type="number"
                                onChange={this.handleChange}
                                required
                            />
                        </Form.Group>
                    </Row>

                            <h5 style={{textAlign: "center"}}>Acceptable Example</h5>
                            <div>
                                <img style={{maxWidth: "50%", height: "auto", marginLeft: "25%", marginRight: "auto"}}
                                    src={BartExample} alt={"Example of BART report submission"}
                                />
                            </div>
                        

                            <ImageUploader
                                withIcon={true}
                                withPreview={true}
                                buttonText='Choose image'
                                onChange={this.onDropBart}
                                imgExtension={['.jpg', '.png', 'jpeg']}
                                label={'Max file size: 5mb, accepted: jpg, png, jpeg'}
                                maxFileSize={5242880}
                                singleImage={true}
                            />


                    {!this.state.submitLoading && (
                        <Button onClick={() => this.handleSubmit(project.refLocation, project.startTime, project.client)} variant="success"> Submit </Button>
                    )}

                    {!!this.state.submitLoading && (
                        <Button disabled onClick={() => this.handleSubmit(project.refLocation, project.startTime, project.client)} variant="success"> Submitting... </Button>
                    )}

                    {(!this.state.noSubmission && this.state.submitSuccess) && (
                        <FaCheckCircle style={{color: "green", marginLeft: 6, marginTop: 9}}/>
                    )}

                    {(!this.state.noSubmission && this.state.submitFailure) && (
                        <FaRegTimesCircle style={{color: "green", marginLeft: 3, marginTop: 9}}/>
                    )}

                    {!!this.state.missingPictureError && (
                        <p style={{marginTop: 10, color: 'red'}}> A picture is required to submit... </p>
                    )}
                </Container>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        addBartExpense: (refLocation, userID, toOrigin, toDestination, leaveOrigin, leaveDestination, toCost, leaveCost, pictureURL, startTime, client) => 
            dispatch(addBartExpense(refLocation, userID, toOrigin, toDestination, leaveOrigin, leaveDestination, toCost, leaveCost, pictureURL, startTime, client)),
    }
}

export default connect(null, mapDispatchToProps)(BartReport);