import React, { Component } from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

import './Expenses.css';

class ViewParkingReport extends Component {
    constructor(props) {
        super(props);

        this.state = {

        }
    }

    displayStatus = (status, report) => {
        switch(status) {
            case 'pending':
                return <p style={{color: '#abb012'}}><b>Pending </b></p>

            case 'approved':
                return <p style={{color: 'green'}}><b>Approved</b></p>

            case 'denied':
                return (
                    <div>
                        <p style={{color: 'red'}}><b>Denied</b></p>
                        <p>{`Reason: ${report.denyReason}`}</p>
                        <span><i>Please submit a new report </i></span>
                    </div>  
                )

            default:
                console.log("ERROR: DEFAULT CASE REACHED");
                break;
        }
    }

    render() {
        const { report } = this.props;

        return(
            <Card className="reviewCard">
                <Card.Header as="h5">Parking Report</Card.Header>

                <Card.Body>
                        <Row style={{marginBottom: 0}}>
                            <Col xs={12} md={6}>
                                <div className="card z-depth-0 project-summary">
                                    <div className="card-content grey-text text-darken-3">
                                        <span className="card-title">{this.displayStatus(report.status, report)}</span>
                                        <span className="card-title">{"Type: " + report.type}</span>
                                        <span className="card-title">{"Garage Name: " + report.garageName}</span>
                                        <span className="card-title">{"Garage Address: " + report.garageAddress}</span>
                                        <span className="card-title">{"Cost: $" + report.totalCost}</span>
                                    </div>
                                </div>
                            </Col>

                            <Col style={{textAlign: 'center'}} xs={12} md={6}>
                                <img style={{width: '100%'}} src={report.pictureURL} alt="proof of expense"></img>
                                <a style={{fontSize: 10, margin: 0, marginBottom: 10}} href={report.pictureURL} className="previous">Link to image</a>
                            </Col>
                        </Row>
                    </Card.Body>
            </Card>
        )
    }
}

export default ViewParkingReport;