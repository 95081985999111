import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Col from 'react-bootstrap/Col';

import moment from 'moment';

import './ProjectCalendarMobile.css';

class ProjectCalendarMobile extends Component {
  state = {}

  handleConfirm = (refLocation, userID) => {
    this.props.techConfirm(refLocation, userID)

    var tempProp = {...this.state.signupButton}
    tempProp.message = 'Confirmed'
    tempProp.isDisabled = true
    this.setState(() => ({confirmButton: tempProp}))
  }

  render() {
    const { projects } = this.props;

    var confirmedProjects = [];
    var scheduledProjects = [];
    var proposedProjects = [];
    var finishedProjects = [];

    // Add projects to their respective new arrays
    projects.forEach(project => {
      if (project.phase === 'confirmed') {
        confirmedProjects.push(project)
      } else if (project.phase === 'scheduled') {
        scheduledProjects.push(project)
      } else if (project.phase === 'proposed') {
        proposedProjects.push(project)
      } else if (project.phase === 'finished') {
        finishedProjects.push(project)
      }
    })

    confirmedProjects.sort((a, b) => new Date(a.startTime) - new Date(b.startTime));
    scheduledProjects.sort((a, b) => new Date(a.startTime) - new Date(b.startTime));
    proposedProjects.sort((a, b) => new Date(a.startTime) - new Date(b.startTime));
    finishedProjects.sort((a, b) => new Date(b.startTime) - new Date(a.startTime));
    
    return (
      <div>
        {!!confirmedProjects.length && (
          <div>
            <h3 style={{textAlign: "center"}}>Next Up</h3>

            {confirmedProjects.map(project => {
              const projectLink = `/a/${moment(project.startTime, "YYYY-MM-DD hh:mm").format('YYYY/MMMM')}/${project.refLocation.id}`;
              return(
                <Col xs={12} key={project.refLocation.id}>
                  <Link style={{"padding": 0, "margin": 0}} to={projectLink}>
                    <span style={{"display": "block"}}>
                      <div className="card z-depth-3 project-summary">
                        <div className="card-content grey-text text-darken-3">
                          <span className="inlineSpan"> {project.client + ' - '} </span>
                          <span className="inlineSpan"> {project.city} </span>
                          <span className="inlineSpan card-title">
                            {moment(project.startTime, "YYYY-MM-DD hh:mm").format('dddd MM/DD - h:mm a')}
                          </span>
                          <span className="inlineSpan"> Confirmed Techs: {Object.keys(project.confirmedTechList).length}</span>
                          &nbsp;  
                          <span className="inlineSpan"> Reserve Techs: {Object.keys(project.proposedTechList).length} </span>
                        </div>
                      </div>
                    </span>
                  </Link>
                </Col>
              )
            })}
          </div>
        )}

        {!!scheduledProjects.length && (
          <div>
            <h3 style={{textAlign: "center"}}>Projects Still Recruiting</h3>

            {scheduledProjects.map(project => {
              return(
                <Col xs={12} key={project.refLocation.id}>
                  <Link style={{"padding": 0, "margin": 0}} to={'/a/' + moment(project.startTime, "YYYY-MM-DD hh:mm").format('YYYY') + '/' +
                    moment(project.startTime, "YYYY-MM-DD hh:mm").format('MMMM') + '/' +
                    project.refLocation.id}
                  >
                    <div className="card z-depth-3 project-summary">
                      <div className="card-content grey-text text-darken-3">
                        <span className="inlineSpan"> {project.client} </span>
                        <span className="inlineSpan"> {project.city} </span>
                        <span className="inlineSpan"> {project.address} </span>
                        <span className="inlineSpan card-title">
                          {moment(project.startTime, "YYYY-MM-DD hh:mm").format('dddd MM/DD - h:mm a')}
                        </span>
                        <span className="inlineSpan"> Confirmed Techs: {Object.keys(project.confirmedTechList).length}</span>
                        &nbsp;  
                        <span className="inlineSpan"> Reserve Techs: {Object.keys(project.proposedTechList).length} </span>
                      </div>
                    </div>
                  </Link>
                </Col>
              )
            })}
          </div>
        )}

        {!!proposedProjects.length && (
          <div>
            <h3 style={{textAlign: "center"}}>Proposed Projects</h3>

            {proposedProjects.map(project => {
              return(
                <div key={project.refLocation.id}>
                  <div className="card z-depth-0 project-summary">
                    <div className="card-content grey-text text-darken-3">
                      <span className="inlineSpan"> {project.client} </span>
                      <span className="inlineSpan"> {project.city} </span>
                      <span className="inlineSpan"> {project.address} </span>
                      <span className="inlineSpan card-title">
                        {moment(project.startTime, "YYYY-MM-DD hh:mm").format('dddd MM/DD - h:mm a')}
                      </span>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        )}

        {!!finishedProjects.length && (
          <div>
            <h3 style={{textAlign: "center"}}>Previous Projects</h3>

            {finishedProjects.map(project => {
              return(
                <div key={project.refLocation.id}>
                  <div className="card z-depth-0 project-summary">
                    <div className="card-content grey-text text-darken-3">
                      <span className="inlineSpan"> {project.client} </span>
                      <span className="inlineSpan"> {project.city} </span>
                      <span className="inlineSpan"> {project.address} </span>
                      <span className="inlineSpan card-title">
                        {moment(project.startTime, "YYYY-MM-DD hh:mm").format('dddd MM/DD - h:mm a')}
                      </span>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        )}
      </div>
    ) 
  }   
}

export default ProjectCalendarMobile;