import React, { useState } from 'react';
import { connect } from 'react-redux'

import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'

import emailjs from 'emailjs-com';

import ConfirmProjectMobile from './ConfirmProjectMobile'

import { createProject } from '../../../../store/actions/projectActions';
import { updateUsersNotifications } from '../../../../store/actions/userActions';


const CreateProjectMobile = (props) => {

    const blankProject = { client: '', alias: '', address: '',
                            city: '', addressLink: '', workstations: '',
                            type: '', day: '', startTime: '', endTime: '',
                            bartStation: '', bartDistance: ''};

    const [projectState, setProjectState] = useState([
        {...blankProject}
    ]);

    const addProject = () => {
        setProjectState([...projectState, {
            client: projectState[0].client, alias: projectState[0].alias,
            address: projectState[0].address, city: projectState[0].city,
            addressLink: projectState[0].addressLink, workstations: projectState[0].workstations,
            type: '', day: '', startTime: '', endTime: '',
            bartStation: projectState[0].bartStation, bartDistance: projectState[0].bartDistance
        }]);
    };

    const handleProjectChange = (e) => {
        const updatedProjects = [...projectState];
        updatedProjects[e.target.dataset.index][e.target.id] =
            e.target.value;
        setProjectState(updatedProjects) 
    }

    const handleSubmit = () => {
        // Handle specific changes before sending to Firebase
        projectState.forEach(project => {
            project.startTime = project.day + ' ' + project.startTime
            project.endTime = project.day + ' ' + project.endTime
            delete project.day

            if (project.type === "Post-move") {
                project.workstations = 0
            }
        })

        props.createProject(projectState)

        handleFormReset()
    }

    const handleFormReset = () => {
        Array.from(document.querySelectorAll("input")).forEach(
            input => (input.value = "")
          );

        setProjectState([{...blankProject}])
    }

    const sendEmail = () => {
        var allEmails = []
        Object.entries(this.props.users).forEach(entry => {
            const id = entry[0]
            const user = entry[1]

            if (user.role === "tech" && user.notifications === undefined) {
                console.log("Fixed: ", id)
                this.props.updateUsersNotifications(id)
                allEmails.push(user.email)
            } else if (user.role === 'tech' && user.email) {
                if (user.notifications.email.newProjects) {
                    allEmails.push(user.email)
                }
            }
        })
    
        while (allEmails.length) {
            const templateParams = {
                Bcc: allEmails.splice(0, 98)
            }
          
            emailjs.send('scheduler@murphymckay', 'template_myp5vme', templateParams, "user_UgmdOeeH6OCjwrAqPoGpi")
                .then((result) => {
                    console.log(result.text);
                }, (error) => {
                    console.log(error.text);
                });
          
            console.log("Sent to", templateParams.Bcc)
        }
    }

    return (
        <div className="dashboard container">
            <Form onSubmit={handleSubmit}>
                <h4>Create a New Project</h4>
                {
                     projectState.map((val, index) => {
                        return (
                            <div key={index}>
                                <Form.Row>
                                    <Form.Group as={Col} controlId="type">
                                        <Form.Label>Type</Form.Label>
                                        <Form.Control
                                            as="select"
                                            data-index={index}
                                            onChange={handleProjectChange}
                                            required
                                        >     
                                            <option></option>
                                            <option>Disconnect</option>
                                            <option>Reconnect</option>
                                            <option>Shuffle</option>
                                            <option>Post-move</option>
                                            <option>Server</option>
                                            <option>Support</option>
                                            {/* Don't forget to change EditProject */}
                                        </Form.Control>
                                    </Form.Group>

                                    <Form.Group as={Col} controlId="workstations">
                                        <Form.Label>Workstations</Form.Label>
                                        <Form.Control
                                            type="number"
                                            data-index={index}
                                            placeholder={projectState[0].workstations || "Enter a number"}
                                            onChange={handleProjectChange}
                                            required
                                        />
                                    </Form.Group>
                                </Form.Row>

                                {(index === 0 || projectState[index].type) && (
                                    <div>
                                        <Form.Row>
                                            <Form.Group as={Col} controlId="client">
                                                <Form.Label>Client</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    data-index={index}
                                                    placeholder={projectState[0].client || "Enter client name"}
                                                    onChange={handleProjectChange}
                                                    required
                                                />
                                            </Form.Group>

                                            <Form.Group as={Col} controlId="alias">
                                                <Form.Label>Client ID</Form.Label>
                                                <Form.Control 
                                                    type="text"
                                                    data-index={index}
                                                    placeholder={projectState[0].alias || "Enter client alias"}
                                                    onChange={handleProjectChange}
                                                    required
                                                />
                                            </Form.Group>
                                        </Form.Row>


                                        <Form.Row>
                                            <Form.Group as={Col} controlId="address">
                                                <Form.Label>Address</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    data-index={index}
                                                    placeholder={projectState[0].address || "Enter address"}
                                                    onChange={handleProjectChange}
                                                    required
                                                />
                                            </Form.Group>

                                            <Form.Group as={Col} controlId="city">
                                                <Form.Label>City</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    data-index={index}
                                                    placeholder={projectState[0].city || "Enter city"}
                                                    onChange={handleProjectChange}
                                                    required
                                                />
                                            </Form.Group>

                                            <Form.Group as={Col} controlId="addressLink">
                                                <Form.Label>Google Maps Link</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    data-index={index}
                                                    placeholder={projectState[0].addressLink || "Enter link"}
                                                    onChange={handleProjectChange}
                                                />
                                            </Form.Group>
                                        </Form.Row>


                                        <Form.Row>
                                            <Form.Group as={Col} controlId="day">
                                                <Form.Label>Date</Form.Label>
                                                <Form.Control
                                                    type="date"
                                                    data-index={index}
                                                    placeholder="Enter a day"
                                                    onChange={handleProjectChange}
                                                    required
                                                />
                                            </Form.Group>
                                        
                                            <Form.Group as={Col} controlId="startTime">
                                                <Form.Label>Start Time</Form.Label>
                                                <Form.Control
                                                    type="time"
                                                    data-index={index}
                                                    placeholder="Enter the start time"
                                                    onChange={handleProjectChange}
                                                    required
                                                />
                                            </Form.Group>

                                            <Form.Group as={Col} controlId="endTime">
                                                <Form.Label>End Time</Form.Label>
                                                <Form.Control
                                                    type="time"
                                                    data-index={index}
                                                    placeholder="Enter the end time"
                                                    onChange={handleProjectChange}
                                                    required
                                                />
                                            </Form.Group>
                                        </Form.Row>

                                        <Form.Row>
                                            <Form.Group as={Col} controlId="bartStation">
                                                <Form.Label>Nearest BART Station</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    data-index={index}
                                                    placeholder={projectState[0].bartStation || "Enter the nearest BART Station"}
                                                    onChange={handleProjectChange}
                                                />
                                            </Form.Group>

                                            <Form.Group as={Col} controlId="bartDistance">
                                                <Form.Label>Distance to BART</Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    data-index={index}
                                                    placeholder={projectState[0].bartDistance || "Enter the distance to BART"}
                                                    onChange={handleProjectChange}
                                                />
                                            </Form.Group>
                                        </Form.Row>
                                    </div>
                                )}
                            </div>
                        )
                    })
                }
                <Button variant="primary" onClick={addProject}>
                    Add Project
                </Button>
                {' '}
                <Button variant="success" onClick={handleSubmit}>
                    Submit
                </Button>
            </Form>
            
            <ConfirmProjectMobile/>
            
            <Button variant="primary" type="button" onClick={() => sendEmail()}> 
                Send Emails
            </Button>
        </div>
    );    
};

const mapDispatchToProps = dispatch => {
    return {
      createProject: (projectArray) => dispatch(createProject(projectArray)),
      updateUsersNotifications: (id) => dispatch(updateUsersNotifications(id)),
    }
  
}

export default connect(null, mapDispatchToProps)(CreateProjectMobile);