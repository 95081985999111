import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';

import moment from 'moment';

import { updateUserProjectHistoryCount, updateAdminTimestamp } from '../../../store/actions/userActions';

class UpdateTechProjHistory extends Component {
    constructor(props) {
        super(props);

        this.state = {
            expandList: false,
            sortByFirstName: true,
        }
    }

    handleButton = () => {
        Object.entries(this.props.users).forEach(entry => {
            const id = entry[0];
            const user = entry[1];

            if (user.role === "tech") {
                var projectCount = 0;
                const sixMonthsAgo = moment().subtract(6, 'months');
                const now = moment();
                Object.values(this.props.projects).forEach(project => {
                    if (moment(project.startTime).isSameOrAfter(sixMonthsAgo) && moment(project.startTime).isSameOrBefore(now)) {
                        if (Object.keys(project.confirmedTechList).includes(id)) {
                            if (project.confirmedTechList[id] === "confirmed") {
                                projectCount++;
                            }
                        }
                    }
                })
                console.log("Updating: ", id, `${this.props.users[id].firstName} ${this.props.users[id].lastName}}`);
                this.props.updateUserProjectHistoryCount(id, projectCount);
                this.props.updateAdminTimestamp("lastTechProjHistoryUpdate", moment().format("YYYY-MM-DD HH:mm"))
            }
        })
    }

    render() {
        const { administrative } = this.props;
        if (administrative) {        
            return (
                <Container>
                    <div className="card z-depth-2 project-summary">
                        <div className="card-content grey-text text-darken-3">
                            <span className="card-title">
                                Update Tech Project History (Last 6 Months)
                            </span>
                            <Row>
                                <Col xs={1}>
                                    <Button variant="warning" onClick={() => this.handleButton()}> Update </Button>
                                </Col>
                
                                <Col xs={10}>
                                    <span className="card-body">
                                        {`Last Updated: ${moment(administrative.lastTechProjHistoryUpdate).format('MMMM D YYYY, h:mm a')}`}
                                    </span>
                                </Col>
                            </Row>
                            
                        </div>
                    </div>  
                </Container>
            )
        } else {
            return (
                <p>Loading...</p>
            )
        }
    }
}

const mapStateToProps = (state) => {
    return {
        administrative: state.firestore.data.administrative,
        projects: {
            ...state.firestore.data.MonthProjects,
            ...state.firestore.data.NextMonthProjects,
            ...state.firestore.data.LastMonthProjects,
            ...state.firestore.data.TwoMonthsAgoProjects,
            ...state.firestore.data.ThreeMonthsAgoProjects,
            ...state.firestore.data.FourMonthsAgoProjects,
            ...state.firestore.data.FiveMonthsAgoProjects,
            ...state.firestore.data.SixMonthsAgoProjects,
        }
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateUserProjectHistoryCount: (userID, projectCount) => dispatch(updateUserProjectHistoryCount(userID, projectCount)),
        updateAdminTimestamp: (field, time) => dispatch(updateAdminTimestamp(field, time)),
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect(() => [
        {
            collection : "SchedulerProjects", doc: "administrative", storeAs: "administrative"
        },
        {
            collection : 'SchedulerProjects', doc: moment().format('YYYY'),
            subcollections: [{ collection : moment().format('MMMM') }],
            storeAs: 'MonthProjects'
        },
        {
            collection : 'SchedulerProjects', doc: moment().subtract(1, 'months').format('YYYY'),
            subcollections: [{ collection : moment().subtract(1, 'M').format('MMMM') }],
            storeAs: 'LastMonthProjects'
        },
        {
            collection : 'SchedulerProjects', doc: moment().subtract(2, 'months').format('YYYY'),
            subcollections: [{ collection : moment().subtract(2, 'M').format('MMMM') }],
            storeAs: 'TwoMonthsAgoProjects'
        },
        {
            collection : 'SchedulerProjects', doc: moment().subtract(3, 'months').format('YYYY'),
            subcollections: [{ collection : moment().subtract(3, 'M').format('MMMM') }],
            storeAs: 'ThreeMonthsAgoProjects'
        },
        {
            collection : 'SchedulerProjects', doc: moment().subtract(4, 'months').format('YYYY'),
            subcollections: [{ collection : moment().subtract(4, 'M').format('MMMM') }],
            storeAs: 'FourMonthsAgoProjects'
        },
        {
            collection : 'SchedulerProjects', doc: moment().subtract(5, 'months').format('YYYY'),
            subcollections: [{ collection : moment().subtract(5, 'M').format('MMMM') }],
            storeAs: 'FiveMonthsAgoProjects'
        },
        {
            collection : 'SchedulerProjects', doc: moment().subtract(6, 'months').format('YYYY'),
            subcollections: [{ collection : moment().subtract(6, 'M').format('MMMM') }],
            storeAs: 'SixMonthsAgoProjects'
        },
        {
            collection: 'users',
        },
        {
            collection: "Reports"
        }
    ])
)(UpdateTechProjHistory);