import React, { useState } from 'react';
import { connect } from 'react-redux';

import Modal from 'react-modal';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';

import moment from 'moment';
import emailjs from 'emailjs-com';

import { FaCircle } from 'react-icons/fa';
import { IconContext } from "react-icons";

import './EditProject.css';
import { updateProjectDetail } from '../../../../../store/actions/projectActions';
 
const customStyles = {
  content : {
    top                   : '47%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    maxHeight: "88vh",
    overflowY: "auto",
  },
  overlay                 : {zIndex: 100}
};
 
// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
//Modal.setAppElement('#yourAppElement')
 
function EditProject(props){

  const editedProjectValues = { client: '', alias: '', address: '',
                            city: '', addressLink: '', workstations: '',
                            type: '', startTime: '', endTime: '',
                            bartStation: '', bartDistance: '' };

  const [editedProjectState, setEditedProjectState] = useState({...editedProjectValues});

  var subtitle;
  const [modalIsOpen,setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }
 
  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = '#f00';
  }
 
  function closeModal(){
    setIsOpen(false);
  }

  function handleEditProject(e) {
    const updatedProjects = {...editedProjectState};
    updatedProjects[e.target.id] = e.target.value;
    setEditedProjectState(updatedProjects)
  }

  function checkIfFilled(values) {
    var anythingChanged = false

    values.forEach(value => {
      if (value) {
        anythingChanged = true
      }
    })
    return anythingChanged
  }

  function discardChanges() {
    setEditedProjectState({...editedProjectValues})
    closeModal()
  }

  function confirmChanges(project) {
    Object.entries(editedProjectState).forEach(entry => {
      const key = entry[0]
      const value = entry[1]

      
      if (value) {
        // Firebase specific checks
        if (key === "startTime" || key === "endTime") {
          const time = moment(project.startTime, "YYYY-MM-DD hh:mm").format('YYYY-MM-DD') + ' ' + value
          props.updateProjectDetail(project.refLocation, key, time)
        } else {
          props.updateProjectDetail(project.refLocation, key, value)
        }

        // EmailJS specific checks
        if ((key === "startTime" || key === "address") && project.phase === "confirmed") {

          var IDsToUpdate = Object.keys(project.confirmedTechList);
          var emailsToUpdate = [];

          IDsToUpdate.forEach(id => {
            if (props.users[id].notifications.email.projectUpdated) {
              emailsToUpdate.push(props.users[id].email)
            }
          })

          var templateParams = { Bcc: emailsToUpdate }
          emailjs.send('scheduler@murphymckay', 'template_6dgpdja', templateParams, "user_UgmdOeeH6OCjwrAqPoGpi")
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
        }
        
      }
    })

    setEditedProjectState({...editedProjectValues})
    closeModal()
  }

  const { title, project } = props;

  return (
    <div>
      <Button onClick={openModal}>
          Edit
      </Button>

      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Warning Modal"
        shouldCloseOnOverlayClick={false}
      >

      <h2 ref={_subtitle => (subtitle = _subtitle)}>{title}</h2>

      <Container>
        <Row style={{marginLeft: 0, marginRight: 0}}>
          <Col style={{marginLeft: 0, marginRight: 0}}>
              <Card style={{marginLeft: 20, marginRight: 20, padding: 20}}>
                <h2>Current</h2>
                <p style={{marginBottom: 48, marginTop: 12, maxWidth: 200}}>{"Client: " + project.client}</p>
                <p style={{marginBottom: 48, maxWidth: 200}}>{"Address: " + project.address}</p>
                <p style={{marginBottom: 48, maxWidth: 200}}>{"City: " + project.city}</p>
                <p style={{marginBottom: 48, maxWidth: 200}}>{"Start Time: " + moment(project.startTime, "YYYY-MM-DD hh:mm").format('h:mm a')}</p>
                <p style={{marginBottom: 48, maxWidth: 200}}>{"End Time: " + moment(project.endTime, "YYYY-MM-DD hh:mm").format('h:mm a')}</p>
                <p style={{marginBottom: 48, maxWidth: 200}}>{"Type: " + project.type}</p>
                <p style={{marginBottom: 48, maxWidth: 200}}>{"Workstations: " + project.workstations}</p>
                <p style={{marginBottom: 48, maxWidth: 200}}>{"Google Maps Link: "}</p>
                <p style={{marginBottom: 48, maxWidth: 200}}>{"BART Station: " + project.bartStation}</p>
                <p style={{marginBottom: 48, maxWidth: 200}}>{"BART Distance: " + project.bartDistance}</p>
                <p style={{marginBottom: 48, maxWidth: 200}}>{"Notes: " + project.notes}</p>
              </Card>
          </Col>


          <Col style={{marginLeft: 0, marginRight: 0}}>
              <Card className="rightCard">
                <h2 style={{marginLeft: -15}}>New</h2>

                <Row className="formRows">
                  <Form.Group style={{maxWidth: "80%"}} controlId="client">
                    <Form.Control
                      type="text"
                      placeholder={project.client}
                      onChange={handleEditProject}
                      required
                    />
                  </Form.Group>

                  {editedProjectState.client && (
                    <IconContext.Provider value={{ color: 'green', className: "global-class-name" }}>
                      <FaCircle className="greenDot"/>
                    </IconContext.Provider>
                  )}
                </Row>
                    
                <Row className="formRows">  
                  <Form.Group style={{maxWidth: "80%"}} controlId="address">
                    <Form.Control
                      type="text"
                      placeholder={project.address}
                      onChange={handleEditProject}
                      required
                    />
                  </Form.Group>

                  {editedProjectState.address && (
                    <IconContext.Provider value={{ color: 'green', className: "global-class-name" }}>
                      <FaCircle className="greenDot"/>
                    </IconContext.Provider>
                  )}
                </Row>

                <Row className="formRows">
                  <Form.Group style={{maxWidth: "80%"}} controlId="city">
                    <Form.Control
                      type="text"
                      placeholder={project.city}
                      onChange={handleEditProject}
                      required
                    />
                  </Form.Group>

                  {editedProjectState.city && (
                    <IconContext.Provider value={{ color: 'green', className: "global-class-name" }}>
                      <FaCircle className="greenDot"/>
                    </IconContext.Provider>
                  )}
                </Row>
                
                <Row className="formRows">
                  <Form.Group controlId="startTime">
                    <Form.Control
                      type="time"
                      placeholder={moment(project.startTime, "YYYY-MM-DD hh:mm").format('h:mm a')}
                      onChange={handleEditProject}
                      required
                    />
                  </Form.Group>

                  {editedProjectState.startTime && (
                    <IconContext.Provider value={{ color: 'green', className: "global-class-name" }}>
                      <FaCircle className="greenDot"/>
                    </IconContext.Provider>
                  )}
                </Row>

                <Row className="formRows">
                  <Form.Group controlId="endTime">
                    <Form.Control
                      type="time"
                      placeholder={moment(project.endTime, "YYYY-MM-DD hh:mm").format('h:mm a')}
                      onChange={handleEditProject}
                      required
                    />
                  </Form.Group>

                  {editedProjectState.endTime && (
                    <IconContext.Provider value={{ color: 'green', className: "global-class-name" }}>
                      <FaCircle className="greenDot"/>
                    </IconContext.Provider>
                  )}
                </Row>
                
                <Row className="formRows">
                  <Form.Group controlId="type" style={{marginTop: 13, marginBottom: 28}}>
                    <Form.Control
                      as="select"
                      placeholder={project.type}
                      onChange={handleEditProject}
                      required
                    >     
                      <option></option>
                      <option>Disconnect</option>
                      <option>Reconnect</option>
                      <option>Shuffle</option>
                      <option>Post-move</option>
                      <option>Server</option>
                      <option>Support</option>
                    </Form.Control>
                  </Form.Group>

                  {editedProjectState.type && (
                    <IconContext.Provider value={{ color: 'green', className: "global-class-name" }}>
                      <FaCircle className="greenDot"/>
                    </IconContext.Provider>
                  )}
                </Row>

                <Row className="formRows">
                  <Form.Group style={{maxWidth: "80%"}} controlId="workstations">
                    <Form.Control
                      type="number"
                      placeholder={project.workstations}
                      onChange={handleEditProject}
                      required
                    />
                  </Form.Group>

                  {editedProjectState.workstations && (
                    <IconContext.Provider value={{ color: 'green', className: "global-class-name" }}>
                      <FaCircle className="greenDot"/>
                    </IconContext.Provider>
                  )}                       
                </Row>

                <Row className="formRows">
                  <Form.Group style={{maxWidth: "80%"}} controlId="addressLink">  
                    <Form.Control
                      type="text"
                      placeholder={project.addressLink}
                      onChange={handleEditProject}
                    />
                  </Form.Group>

                  {editedProjectState.addressLink && (
                    <IconContext.Provider value={{ color: 'green', className: "global-class-name" }}>
                      <FaCircle className="greenDot"/>
                    </IconContext.Provider>
                  )}
                </Row>

                <Row className="formRows">
                  <Form.Group style={{maxWidth: "80%"}} controlId="bartStation">
                    <Form.Control
                      type="text"
                      placeholder={project.bartStation}
                      onChange={handleEditProject}
                    />
                  </Form.Group>

                  {editedProjectState.bartStation && (
                    <IconContext.Provider value={{ color: 'green', className: "global-class-name" }}>
                      <FaCircle className="greenDot"/>
                    </IconContext.Provider>
                  )}
                </Row>

                <Row className="formRows">
                  <Form.Group style={{maxWidth: "80%"}} controlId="bartDistance">
                    <Form.Control
                      type="number"
                      placeholder={project.bartDistance}
                      onChange={handleEditProject}
                    />
                  </Form.Group>

                  {editedProjectState.bartDistance && (
                    <IconContext.Provider value={{ color: 'green', className: "global-class-name" }}>
                      <FaCircle className="greenDot"/>
                    </IconContext.Provider>
                  )}
                </Row>

                <Row className="formRows">
                  <Form.Group style={{maxWidth: "80%"}} controlId="notes">
                    <Form.Control
                      type="text"
                      placeholder={project.notes}
                      onChange={handleEditProject}
                    />
                  </Form.Group>

                  {editedProjectState.notes && (
                    <IconContext.Provider value={{ color: 'green', className: "global-class-name" }}>
                      <FaCircle className="greenDot"/>
                    </IconContext.Provider>
                  )}
                </Row>

              </Card>
          </Col>
        </Row>

      </Container>

      <Button
        variant="success"
        size="sm"
        disabled={!checkIfFilled(Object.values(editedProjectState))}
        onClick={() => confirmChanges(project)}>
          Confirm Changes
      </Button>
      &nbsp;

      {!checkIfFilled(Object.values(editedProjectState)) && (
        <Button variant="primary" size="sm" onClick={closeModal}> Go Back </Button>
      )}
      
      {checkIfFilled(Object.values(editedProjectState)) && (
        <Button variant="danger"  size="sm" onClick={discardChanges}> Discard Changes </Button>
      )}
      </Modal>
    </div>
  );
}

const mapDispatchToProps = dispatch => {
  return {
    updateProjectDetail: (refLocation, detailToUpdate, valueToUse) => dispatch(updateProjectDetail(refLocation, detailToUpdate, valueToUse)),
  }
}
 
export default connect(null, mapDispatchToProps)(EditProject);