import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';
import { Redirect } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import UserInfoProfileMobile from './UserInfoProfileMobile';
import ContactInfo from './ContactInfo';
import Certifications from './Certifications';
import Writeups from './Writeups/Writeups';
import Loading from '../assets/Loading.gif';
import './UserInfoProfile.css';

class UserInfoProfile extends Component {
  state = {};

  render() {
    const { auth, profile, users } = this.props;
    if (!auth.uid) return <Redirect to='/signin' />;

    if (window.innerWidth < 1200) {
      return <UserInfoProfileMobile auth={auth} profile={profile} users={users} />;
    } else if (auth && profile.firstName) {
      return (
        <Container>
          <Row>
            <ContactInfo profile={profile} />
          </Row>
          <Row>
            <Certifications auth={auth} profile={profile} />
          </Row>
          <Row>
            <Writeups auth={auth} profile={profile} users={users} />
          </Row>
          <Row>
            <div style={{ height: '750px', width: '100%' }}>
              <iframe
                src="https://drive.google.com/file/d/191VBBPlaN1Y8oJxcBBREt3qIarQJCsRW/preview"
                style={{ width: '100%', height: '100%' }}
                frameBorder="0"
              ></iframe>
            </div>
          </Row>
        </Container>
      );
    } else {
      return <img style={{ marginTop: 20 }} className="LoadingWheel" src={Loading} alt="loading..." />;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    profile: state.firebase.profile,
    auth: state.firebase.auth,
    users: state.firestore.data.users,
  };
};

export default compose(
  connect(mapStateToProps, null),
  firestoreConnect(() => [
    {
      collection: 'users',
    },
  ])
)(UserInfoProfile);
