import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { firestoreConnect } from 'react-redux-firebase';
import UserHomeMobile from './UserHomeMobile';
import LoginMessage from './LoginMessage';
import JumboHello from './JumboHello';
import UserCalendar from '../userCalendar/UserCalendar';
import moment from 'moment';
import './UserHome.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import LoadingWheel from '../../../assets/Loading.gif';
import isEqual from 'lodash/isEqual';

class UserHome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeProject: {},
    };
  };

  shouldComponentUpdate(nextProps, nextState) {
    // Check administrative changes first
    if (!isEqual(this.props.administrative, nextProps.administrative)) {
        return true;
    }

    if (!isEqual(this.props.projects, nextProps.projects)) {
        return true;
    }

    // Finally, check for state changes
    return !isEqual(this.state, nextState);
}


// componentDidUpdate(prevProps) {
//   if (prevProps.administrative !== this.props.administrative) {
//     console.log('Administrative updated:', this.props.administrative);
//   }
//   if (prevProps.projects !== this.props.projects) {
//     console.log('Projects updated:', this.props.projects);
//   }
// }

  render() {
    const { auth, profile, userRole, administrative, projects } = this.props;

    if (!auth.uid) return <Redirect to='/signin' />
    if (!(administrative && projects)) return <img className="LoadingWheel" src={LoadingWheel} alt="loading..." />
    
    // IMPORTANT NOTE: Mobile uses techProjects because the date is in string timestamp, desktop uses dateProjectsArray because Big Cal needs Date objects
    const isMobile = window.innerWidth < 1200;
    const { categories, dateProjectsArray } = this.classifyProjects(Object.values(projects), userRole, auth.uid, isMobile);


    return (
      <div>
        {/* {!profile.homeMessageSeen && <LoginMessage id={auth.uid} />} */}

        <JumboHello
          auth={auth}
          writeupKeys={this.gatherNumberOfUnseenWriteups(profile.writeups)}
          profile={profile}
          requestedProjects={categories.requestedProjects}
          needToConfirmProjects={categories.needToConfirmProjects}
          confirmedProjects={categories.confirmedProjects}
          finishedProjects={categories.finishedProjects}
          isMobile={isMobile}
          userRole={userRole} />

        {isMobile ? (
          <UserHomeMobile
            auth={auth}
            categories={categories}
            profile={profile}
            adminEmail={administrative.adminEmail}
            userRole={userRole} />
        ) : (
          <UserCalendar
            auth={auth}
            projects={projects}
            dateProjectsArray={dateProjectsArray}
            profile={profile}
            adminEmail={administrative.adminEmail}
            userRole={userRole} />
        )}
      </div>
    );

  }
  
  classifyProjects = (projectsArray, userRole, userId, isMobile=false) => {
    const categories = {
      finishedProjects: [],
      requestedProjects: [],
      needToConfirmProjects: [],
      confirmedProjects: [],
      deniedProjects: [],
      droppedOffProjects: [],
      removedProjects: []
    };
  
    const confirmedStatusHandler = {
      "confirmed": (project) => categories.confirmedProjects.push(project),
      "no response": (project) => categories.needToConfirmProjects.push(project),
      "denied - unseen": (project) => categories.deniedProjects.push(project),
      "denied": (project) => categories.deniedProjects.push(project),
      "dropped off - unseen": (project) => categories.droppedOffProjects.push(project),
      "dropped off": (project) => categories.droppedOffProjects.push(project),
      "removed": (project) => categories.removedProjects.push(project)
    };
  
    projectsArray.forEach(project => {
      const { phase } = project;
      const proposedList = project[`proposed${userRole.capitalize}List`];
      const confirmedList = project[`confirmed${userRole.capitalize}List`];
      const userIn = list => list && Object.keys(list).includes(userId);
  
      switch (phase) {
        case 'finished':
          if (userIn(confirmedList)) {
            categories.finishedProjects.push(project);
          }
          break;
        case 'test':
        case 'scheduled':
        case 'confirmed':
          if (userIn(proposedList) && !userIn(confirmedList)) {
            categories.requestedProjects.push(project);
          } else if (userIn(confirmedList)) {
            const confirmedListUserStatus = confirmedList[userId];
            if (confirmedStatusHandler[confirmedListUserStatus]) {
              confirmedStatusHandler[confirmedListUserStatus](project);
            }
          }
          break;
        default:
          break;
      }
    });
  
    Object.keys(categories).forEach(key => {
      categories[key].sort((a, b) => new Date(a.startTime) - new Date(b.startTime));
    });

    if (isMobile) return { 
      categories,
      dateProjectsArray : []
    };

    const userProjects = [
      { projects: categories.finishedProjects, status: 'finished' },
      { projects: categories.requestedProjects, status: 'requested' },
      { projects: categories.needToConfirmProjects, status: 'needToConfirm' },
      { projects: categories.confirmedProjects, status: 'confirmed' },
      { projects: categories.deniedProjects, status: 'denied' },
      { projects: categories.droppedOffProjects, status: 'droppedOff' },
      { projects: categories.removedProjects, status: 'removed' }
    ].flatMap(({ projects, status }) =>
      projects.map(project => ({ ...project, userConfirmStatus: status }))
    );

    const dateProjectsArray = userProjects.map(project => ({
      ...project,
      startTime: new Date(project.startTime),
      endTime: new Date(project.endTime)
    })).sort((a, b) => a.startTime - b.startTime);
  
    return {
      categories,
      dateProjectsArray
    };
  };

  gatherNumberOfUnseenWriteups = (writeupsAsObject) => {
    var writeupKeys = [];
    Object.entries(writeupsAsObject).forEach(entry => {
      const writeupID = entry[0];
      const writeupData = entry[1];
  
      if (writeupData.status === "Not seen") {
        writeupKeys.push(writeupID);
      }
    })
    return writeupKeys;
  }

}

const mapStateToProps = (state) => ({
  auth: state.firebase.auth,
  profile: state.firebase.profile,
  administrative: state.firestore.data.administrative,
  projects: {
    ...state.firestore.data.LastMonthProjects,
    ...state.firestore.data.MonthProjects,
    ...state.firestore.data.NextMonthProjects,
  },
});


export default compose(
  connect(mapStateToProps),
  firestoreConnect(() => {
      const currentYear = moment().format('YYYY');
      const lastMonthYear = moment().subtract(1, 'month').format('MMMM') === "December" ? moment().subtract(1, 'year').format('YYYY') : currentYear;
      const nextMonthYear = moment().add(1, 'month').format('MMMM') === "January" ? moment().add(1, 'year').format('YYYY') : currentYear;
      const lastMonth = moment().subtract(1, 'month').format('MMMM');
      const thisMonth = moment().format('MMMM');
      const nextMonth = moment().add(1, 'month').format('MMMM');

      return [
        {   collection: "SchedulerProjects", doc: "administrative", storeAs: "administrative" },
        {
            collection: 'SchedulerProjects',
            doc: lastMonthYear,
            subcollections: [{ collection: lastMonth }],
            storeAs: 'LastMonthProjects'
        },
        {
            collection: 'SchedulerProjects',
            doc: currentYear,
            subcollections: [{ collection: thisMonth }],
            storeAs: 'MonthProjects'
        },
        {
            collection: 'SchedulerProjects',
            doc: nextMonthYear,
            subcollections: [{ collection: nextMonth }],
            storeAs: 'NextMonthProjects'
        }
      ];
  })
)(UserHome);
