import React from 'react';
import { connect } from 'react-redux';

import Modal from 'react-modal';
import Button from 'react-bootstrap/Button';

import moment from 'moment';
import emailjs from 'emailjs-com';

import { userDeny, addToActivityLog } from '../../../../../../store/actions/projectActions';
 
const customStyles = {
    content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)'
    },
    overlay                 : {zIndex: 1000}
};
 
// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
//Modal.setAppElement('#yourAppElement')
 
function ModalDenyProject(props){
    var subtitle;
    const [modalIsOpen,setIsOpen] = React.useState(false);

    function openModal() {
        setIsOpen(true);
    };
 
    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        subtitle.style.color = '#f00';
    };
 
    function closeModal(){
        setIsOpen(false);
    };
    
    function denyProject(refLocation, userID, userRole) {
        props.userDeny(refLocation, userID, userRole);
        props.addToActivityLog(refLocation, `${userRole.lower} deny`, userID, userID, userRole);
        sendSchedulerAdminDenyEmail();
        closeModal();
    };

    function sendSchedulerAdminDenyEmail () {

        // Calculate the difference in time between drop off and project start.
        // +1 hour is added to calculation as a way of rounding up the minutes difference to a full hour
        var today = moment();
        var projectDate = moment(props.startTime);

        var diffInHours = projectDate.diff(today, "hours") + 1;
        var diffInDays = projectDate.diff(today, "days");

        var templateParams = {
            tech_name: props.profile.firstName + " " + props.profile.lastName,
            client: props.client,
            date_of_project: projectDate.format("MM-DD"),    // CHANGE THIS
            days_until_project: diffInDays,
            hours_until_project: diffInHours,            // CALCULATE THIS
            scheduler_email: props.adminEmail,
        }

        if (diffInHours <= 48) {
            // Send URGENT template
            emailjs.send('scheduler@murphymckay', 'template_1gv5afe', templateParams, "user_UgmdOeeH6OCjwrAqPoGpi")
                .then((result) => {
                        console.log(result.text);
                }, (error) => {
                        console.log(error.text);
                });
        } else {
            // Send normal template
            emailjs.send('scheduler@murphymckay', 'template_0zw0zmg', templateParams, "user_UgmdOeeH6OCjwrAqPoGpi")
            .then((result) => {
                    console.log(result.text);
            }, (error) => {
                    console.log(error.text);
            });
        }
    }

    const { refLocation, userID, userRole } = props;
    return (
        <>
            {/* Button is visible at all times, clicking it will open the modal */}
            <Button variant="danger" onClick={openModal}> Deny </Button>

            {/* Modal JSX */}
            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Warning Modal"
            >
                <h2 ref={_subtitle => (subtitle = _subtitle)}>
                    Deny Project
                </h2>
                
                <p>{"Are you sure you want to deny this project? You will NOT be able to undo this."}</p>
            
                <Button style={{marginRight: 10}} variant="primary" onClick={closeModal}> Go Back </Button>
                <Button variant="danger"  onClick={() => denyProject(refLocation, userID, userRole)}> Deny </Button>
            </Modal>
        </>
    );
};

const mapDispatchToProps = dispatch => {
    return {
        userDeny: (refLocation, userID, userRole) => dispatch(userDeny(refLocation, userID, userRole)),
        addToActivityLog: (refLocation, action, authorID, subjectID, userRole) => dispatch(addToActivityLog(refLocation, action, authorID, subjectID, userRole)),
    };
};
 
export default connect(null, mapDispatchToProps)(ModalDenyProject);