import React from 'react';
import { connect } from 'react-redux';

import Modal from 'react-modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';

import moment from 'moment';
import emailjs from 'emailjs-com';

import { userDropOff, addToActivityLog, logUserDropOff } from '../../../../../../store/actions/projectActions';
 
const customStyles = {
    content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)'
    },
    overlay                 : {zIndex: 1000}
};
 
// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
//Modal.setAppElement('#yourAppElement')
 
function ModalDropOff(props){
    var subtitle;
    const [modalIsOpen,setIsOpen] = React.useState(false);
    const [reason, setReason] = React.useState("");
    const [showNoReasonError, setNoReasonError] = React.useState(false);

    function openModal() {
        setIsOpen(true);
    };
 
    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        subtitle.style.color = '#f00';
    };
 
    function closeModal() {
        setIsOpen(false);
    };
    
    function dropOffProject(refLocation, userID, client, startTime, userRole) {
        if (reason) {
            props.logUserDropOff(refLocation, userID, reason, client, startTime, userRole)
            props.userDropOff(refLocation, userID, userRole);
            props.addToActivityLog(refLocation, `${userRole.lower} drop off`, userID, userID, userRole);
            sendSchedulerAdminDropOffEmail();
            closeModal();
        } else {
            setNoReasonError(true);
        }

    };

    function handleChange(e) {
        setReason(e.target.value)
    }

    function sendSchedulerAdminDropOffEmail () {

        // Calculate the difference in time between drop off and project start.
        // +1 hour is added to calculation as a way of rounding up the minutes difference to a full hour
        var today = moment();
        var projectDate = moment(props.startTime);

        var diffInHours = projectDate.diff(today, "hours") + 1;
        var diffInDays = projectDate.diff(today, "days");

        var templateParams = {
            tech_name: props.profile.firstName + " " + props.profile.lastName,
            client: props.client,
            date_of_project: projectDate.format("MM-DD"),    // CHANGE THIS
            days_until_project: diffInDays,
            hours_until_project: diffInHours,            // CALCULATE THIS
            scheduler_email: props.adminEmail,
            reason: reason,
        }

        if (diffInHours <= 48) {
            // Send URGENT template
            emailjs.send('scheduler@murphymckay', 'template_8e5n1qf', templateParams, "user_UgmdOeeH6OCjwrAqPoGpi")
                .then((result) => {
                        console.log(result.text);
                }, (error) => {
                        console.log(error.text);
                });
        } else {
            // Send normal template
            emailjs.send('scheduler@murphymckay', 'template_ae33o0n', templateParams, "user_UgmdOeeH6OCjwrAqPoGpi")
            .then((result) => {
                    console.log(result.text);
            }, (error) => {
                    console.log(error.text);
            });
        }
    }

    const { refLocation, userID, startTime, client, userRole } = props;
    return (
        <>
            {/* Button is visible at all times, clicking it will open the modal */}
            <Button variant="danger" onClick={openModal}> Drop Off </Button>
            
            {/* Modal JSX */}
            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Warning Modal"
            >
                <h2 ref={_subtitle => (subtitle = _subtitle)}>
                    Drop Off Project
                </h2>
                <p>Please provide a reason for dropping off this project:</p>
                
                <Form>
                    <Form.Group as={Col} controlId="reportContent">

                        <Form.Control
                            as="textarea" rows={3}
                            //value=
                            onChange={handleChange}
                            required
                        />
                    </Form.Group>
                </Form>

                <p>{"Are you sure you want to drop off of this project? You will NOT be able to undo this. Reminder: Dropping off of a project within 24 hours of its start time will result in a write-up!"}</p>

            
                <Button variant="primary" size="sm" onClick={closeModal} style={{marginRight: 10}}> Cancel </Button>
                <Button variant="danger"  size="sm" onClick={() => dropOffProject(refLocation, userID, client, startTime, userRole)}> Drop Off </Button>
                {showNoReasonError && (
                    <p style={{color: "red", marginTop: 10, marginBottom: 0}}> You must give a reason for dropping off </p>
                )}
            </Modal>
        </>
    );
};

const mapDispatchToProps = dispatch => {
    return {
        userDropOff: (refLocation, userID, userRole) => dispatch(userDropOff(refLocation, userID, userRole)),
        addToActivityLog: (refLocation, action, authorID, subjectID, userRole) => dispatch(addToActivityLog(refLocation, action, authorID, subjectID, userRole)),
        logUserDropOff: (refLocation, userID, reason, client, startTime, userRole) => dispatch(logUserDropOff(refLocation, userID, reason, client, startTime, userRole))
    };
};
 
export default connect(null, mapDispatchToProps)(ModalDropOff);